function portfolioEntrance() {
    var $bigDownCircle = $(".intro .big-down-circle"),
        $antonioAlvesImage = $(".intro .antonio-alves-image"),
        $languageContainer = $(".languages-container");

    var timelineAnimateIn = new TimelineMax({
            paused: true
        }),
        timelineAnimateFromIntro = new TimelineMax({
            paused: true
        });

    this.init = function () {
        $(".category-text-wrapper.active").removeClass("active");
        $(".category-text-wrapper").eq(_titleActiveIndex).addClass("active");
        TweenMax.set(".dragger-wrapper figure", {
            opacity: 0
        });
        TweenMax.set(".category-text-wrapper.active p", {
            y: 10,
            opacity: 0
        });
        TweenMax.set(".separator", {
            scaleX: 0
        });
        TweenMax.set(".dragger-circle", {
            rotation: 25
        });
        TweenMax.set(".dragger-titles", {
            x: _globalViewportW
        });


        $(".lines path").each(function () {
            var $this = $(this)[0];
            TweenMax.set($this, {
                strokeDasharray: $this.getTotalLength(),
                strokeDashoffset: $this.getTotalLength()
            });
        });

        TweenMax.set(".buttons-container", {
            clip: "rect(0px 0px " + $(".buttons-container").outerHeight() + "px 0px)"
        });

        if (_browserObj.isPhone) {
            TweenMax.set(".buttons-container-mobile", {
                autoAlpha: 0
            });
        }
    }

    this.animateIn = function (f) {
        timelineAnimateIn
            .to(".dragger-circle", 1.4, {
                rotation: -25*_titleActiveIndex,
                ease: Expo.easeOut,
                onComplete: function () {
                    f();
                    TweenMax.to(".buttons-container", 0.6, {
                        clip: "rect(0px " + $(".buttons-container").outerWidth() + "px " + $(".buttons-container").outerHeight() + "px 0px)",
                        ease: Circ.easeOut
                    });
                    TweenMax.to(".custom-cursor", 1, {
                        opacity: 1,
                        ease: Expo.easeOut
                    });
                }
            }, "0")
            .to(".dragger-titles", 1, {
                x: parseInt($(".dragger-titles").attr("data-movement")),
                ease: Expo.easeOut
            }, "0.15")
            .staggerTo(".category-text-wrapper.active p", 0.8, {
                y: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05, "0.2")
            .to(".separator", 1, {
                scaleX: 1,
                ease: Expo.easeOut,
                onComplete: function(){
                    if(_browserObj.isMobile){
                        TweenMax.to(".pseudo-circle", 0.8, {
                            opacity: 1,
                            ease: Expo.easeOut
                        })
                    }
                }
            }, "0")
            .staggerTo(".dragger-wrapper figure", 1, {
                opacity: 1
            }, 0.1, "0.1")
            // .to($languageContainer, 1, {
            //     x: 0,
            //     ease: Circ.easeOut
            // }, "0.5")
            .staggerTo(".filters-chars", 1, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05, "0.6")

        if(!_browserObj.isMobile){
            $(".lines path").each(function () {
                var $this = $(this)[0];
                TweenMax.to($this, 2, {
                    strokeDasharray: $this.getTotalLength(),
                    strokeDashoffset: 0,
                    delay: 0.5,
                    ease: Expo.easeOut
                });
            });
        }

        if (_browserObj.isPhone) {
            TweenMax.to(".buttons-container-mobile", 1, {
                autoAlpha: 1,
                delay: 0.5, 
                ease: Expo.easeOut
            });
            TweenMax.to(".pseudo-circle", 1, {
                opacity: 1,
                delay: 0.5,
                ease: Expo.easeOut
            });
        }

        timelineAnimateIn.play();
    }

    this.animateFromIntro = function (f) {
        timelineAnimateFromIntro.to($(".big-square-container-intro"), .6, {
                height: _globalViewportH,
                ease: Circ.easeOut,
                onComplete: function () {
                    $(".main-logo").removeClass("white-version");
                    f();
                }
            }, "0.1")
            .to($(".big-square-container-intro .big-square"), .6, {
                scaleY: 0,
                ease: Circ.easeInOut
            })
            .to(".intro .chars", .6, {
                opacity: 0,
                ease: Circ.easeOut
            },  "0")
            .to($bigDownCircle, 0.7, {
                scale: 0,
                ease: Circ.easeOut
            }, "0.2")
            .to($antonioAlvesImage, 0.6, {
                opacity: 0,
                rotation: -15,
                scale: .8,
                ease: Circ.easeOut
            }, "0")
            .to($(".intro-button"), .6, {
                y: 200,
                ease: Circ.easeOut
            }, "0.2")
            .to($(".policy"), 1.2, {
                y: 200,
                ease: Circ.easeOut,
                onComplete: function () {
                    TweenMax.set($(".intro"), {
                        autoAlpha: 0
                    });
                    if(!_browserObj.isMobile){
                        $(".lines path").each(function () {
                            var $this = $(this)[0];
                            TweenMax.to($this, 2, {
                                strokeDasharray: $this.getTotalLength(),
                                strokeDashoffset: 0,
                                delay: 0.5,
                                ease: Expo.easeOut
                            });
                        });
                    }
                    
                    if(_browserObj.isMobile){
                        TweenMax.to(".pseudo-circle", 1, {
                            opacity: 1,
                            delay: 0.5,
                            ease: Expo.easeOut
                        });
                    }
                    if (_browserObj.isPhone) {
                        TweenMax.to(".buttons-container-mobile", 1, {
                            autoAlpha: 1,
                            delay: 0.5,
                            ease: Expo.easeOut
                        });
                    }
                    timelineAnimateIn.play();
                }
            }, "0")

        timelineAnimateFromIntro.play();
    }
}