function relatedArticles() {
    var $relatedArticlesContainer = $(".related-articles-container"),
        $relatedArticlesWrapper = $(".related-articles-wrapper");

    var articleWidth = $(".related-articles-wrapper").find("li").width(),
        articleHeight = $(".related-articles-wrapper").find("li").height(),
        articleLength = $(".related-articles-wrapper").find("li").length,
        timer = 20,
        timelineArticles = new TimelineMax({paused: true}),
        offsetRight = _globalViewportW*0.0625*3,
        relatedArticlesDrag = null,
        raf_transformLoop = null;

    this.init = function(){
        $relatedArticlesWrapper.find("ul").css({
            "width": articleWidth*articleLength,
            "height": articleHeight 
        });

        $(".related-articles-wrapper").find("li").each(function(key){
            var $this = $(this);

            if(key != 0)
                $this.css("left", (articleWidth+_globalViewportW*0.125)*key);
        });

        timelineArticles.to($relatedArticlesWrapper, timer, {x: -$relatedArticlesWrapper.width()+offsetRight, ease: Linear.easeNone});
        // timelineArticles.play();

        if(!_browserObj.isMobile){
            $relatedArticlesWrapper.on("mouseenter", function(){
                // timelineArticles.pause();
            });
            $relatedArticlesWrapper.on("mouseleave", function(){
                // timelineArticles.play();
            });
        }

        if($relatedArticlesWrapper.find("ul").width() > _globalViewportW - parseInt($relatedArticlesWrapper.css("marginLeft"))){
            $relatedArticlesWrapper.addClass("js-drag");
            relatedArticlesDrag = Draggable.create($relatedArticlesWrapper, {
                type: "x",
                throwProps: true,
                minDuration: 1, 
                maxDuration: 2, 
                edgeResistance: 1,
                onThrowUpdate: function(){
                },  
                onThrowComplete : function() { 
                },
                onDragStart: function(){
                },
                onDrag: function(e){
                },
                onDragEnd: function(){
                    this.applyBounds({minX: 0, minY: 0, maxX: -$relatedArticlesWrapper.find("ul").width()+offsetRight, maxY: 0});
                },  
            });
        } else {
            relatedArticlesDrag = null;
        }
        // getTransformLoop();
    }

    function getTransformLoop(){
        raf_transformLoop = _rAF_loop(getTransformLoop);
        console.log(Math.abs(document.querySelector(".related-articles-wrapper")._gsTransform.x));
    }

    this.kill = function(){
        relatedArticlesDrag = null;
        cancelAnimationFrame(raf_transformLoop);
    }
}
