function buroScrollElements() {
  var translateElems = document.querySelectorAll('[data-scrollTranslate]');
  var scroll_array = [];

  var init = function() {
    translateElems.forEach(function(item, i) {
      var elem = {};

      elem.element = item;
      elem.offset = item.dataset.offset;
      elem.offsetY = item.getBoundingClientRect().top + Number(item.dataset.offset);
      elem.offsetX = item.getBoundingClientRect().left;
      elem.height = item.offsetHeight;
      elem.width = item.offsetWidth;
      elem.isAnimated = false;
      
      TweenMax.set(elem.element, { force3D: true, y: elem.offset, opacity: 0, willChange: "transform, opacity" });

      scroll_array.push(elem);
    });
  }

  var update = function(scrollVal) {
    for(var i=0,len=scroll_array.length; i<len; i++) {
      if(scroll_array[i].isAnimated == false && scrollVal + _globalViewportH > scroll_array[i].offsetY && scrollVal + _globalViewportH < scroll_array[i].offsetY + scroll_array[i].height + _globalViewportH) {
        scroll_array[i].isAnimated = true
        
        TweenMax.to(scroll_array[i].element, 1.5, {
          y: 0,
          opacity: 1,
          force3D: true,
          ease: Power4.easeOut
        });
      }
    }
  }

  
  var kill = function() {

  }

  return {
    init: init,
    update: update,
    kill: kill
  }
}