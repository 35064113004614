function historyPageFunctions() {

  if($_scrollContentWrapper.attr("data-buroScrollbar") != undefined  && !_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
    _buroScroll = new buroVirtualScroll("body", ".page-current", .5, true);
    _buroScroll.init();

   _page_scroll_listener = function(status) {
      historyPage_scroll_rAF(status);
    };
    _buroScroll.addListener(_page_scroll_listener)
  } else {
    _scrollRef = function() { _raf_loop_id = _rAF_loop(historyPage_scroll_rAF ); }
    $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
  }

  /*DOM variables*/
  var $illustrationHistoryContainer = $("#illustration-history"),
      $portoCalem = $(".porto-calem"),
      $lineHistory = $(".line-history"),
      $header = $(".page-header"),
      $calemNewBrand = $(".calem-new-brand"),
      $headerHistoryMobile = $(".header-history-mobile"),
      $introHistoryRightImage = $(".intro-history-right-image");

  /*Variables*/

  var cvs = document.getElementById("illustration-history");

  var illustrationHistory = lottie.loadAnimation({
        wrapper: $illustrationHistoryContainer[0],
        loop: false,
        prerender: true,
        autoplay: false,
        path: '/public/bodymovin/history.json'
      }),
      illustrationHistoryObj = new illustrationHistoryControls(),
      illustrationHistoryHeight = $(".page-header").outerHeight(),
      lineHistoryObj = new lineHistory(),
      beforeAfterObj = new beforeAfter(),
      historyScrollAnimationsObj = new historyScrollAnimations(),
      historyParallaxElements = buroParallaxScroll(),
      scrollValue = 0,
      lastScroll = 0,
      calemNewBrandVisible = false,
      calemNewBrandOffset = $calemNewBrand.offset().top;
      

  var init = function() {

    /*inits*/
    if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
    _buroScroll.blockBuroScroll();
    else
      disableBodyScroll(true);

    if($_body.hasClass("js-no-ajax")){
      _introObj.init(function(){
        $(".preload-image").imagesLoaded(function($images, $proper, $broken) {
          var fPreload = $(this).imagesLoaded();
          fPreload.always(function() {
            lineHistoryObj.init();
            historyScrollAnimationsObj.init();
            if(!_browserObj.isMobile){
              historyParallaxElements.init();
            }
            if(cookie.get('cookie-age') != undefined){
              historyEntranceObj.animateIn(function(){
                if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
                  if(!_browserObj.isSafari){
                    illustrationHistoryObj.init();
                  } else {
                    TweenMax.to($headerHistoryMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
                  }
                  
                  _buroScroll.unblockBuroScroll();
                  _buroScroll.update();
                } else {
                  disableBodyScroll(false);
                  TweenMax.to($headerHistoryMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
                }
              });
            } else {
              if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
                _buroScroll.unblockBuroScroll();
                _buroScroll.update();
                if(!_browserObj.isSafari){
                  illustrationHistoryObj.init();
                } else {
                  TweenMax.to($headerHistoryMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
                }
                $.doTimeout(800, function(){
                  _buroScroll.update();
                });
              } else {
                disableBodyScroll(false);
                TweenMax.to($headerHistoryMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
              }
            }
          });
        });
      });

      beforeAfterObj.init();
      historyEntranceObj.init();
    } else {
      if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
        _buroScroll.unblockBuroScroll();
        _buroScroll.update();
        historyScrollAnimationsObj.init();
        $.doTimeout(1000, function(){
          _buroScroll.update();
        });  
      } else {
        disableBodyScroll(false);
        historyScrollAnimationsObj.init();
      }

      setTimeout(function(){
        if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
          if(!_browserObj.isSafari){
            illustrationHistoryObj.init();
          } else {
            TweenMax.to($headerHistoryMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
          }
          _buroScroll.update();
        }
      }, 1000)
      beforeAfterObj.init();
      lineHistoryObj.init();
      if(!_browserObj.isMobile){
        historyParallaxElements.init();
      }
    }

    $(".header-link").eq(0).addClass("active");
    $("nav .main-links li").removeClass("disable");
    $("nav .main-links li").eq(0).addClass("disable");
        
    //Events
    initEvents();
  }

  var kill = function() {
    //Kill Events

    cancelAnimationFrame(_raf_loop_id);
    lineHistoryObj.kill();
    beforeAfterObj.kill();
    historyParallaxElements.kill();
    if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
      setTimeout(function(){
        if(!_browserObj.isSafari){
          illustrationHistoryObj.kill();
        }
      }, 1000);
      _buroScroll.kill();
    } else {
      $_window[0].removeEventListener("scroll", _scrollRef);
      _scrollRef = null;
    }
  }

  function illustrationHistoryControls() {
    this.init = function(){
      illustrationHistory.playSegments([0, 60], true);
      illustrationHistory.addEventListener("complete", function(){
        illustrationHistory.loop = true;
        illustrationHistory.playSegments([61, 960], true);
      });
    }

    this.controls = function(scroll){
      (scroll > illustrationHistoryHeight) ? illustrationHistory.pause() : illustrationHistory.play();
    }

    this.kill = function(){
      illustrationHistory.destroy();
    }
  }

  function lineHistory(){
    var offset = 0,
        headerHeight = 0,
        pageHeaderHeight = $(".page-header").outerHeight(),
        firstPathLength = $(".first-path svg path")[0].getTotalLength(),
        secondPathLength = $(".first-path svg path")[1].getTotalLength(),
        thirdPathLength = $(".second-path svg path")[0].getTotalLength(),
        targetInitFirst = $(".brasil").offset().top + $introHistoryRightImage.outerHeight(),
        targetEndFirst = $(".antonio-junior-wrapper").offset().top,
        targetInitSecond = $(".brasil").offset().top + $introHistoryRightImage.outerHeight()*2,
        targetEndSecond = $(".antonio-junior").offset().top,
        targetInitThird = $(".antonio-junior").offset().top,
        targetEndThird = $(".pre-footer").offset().top;

    this.init = function(){
      $(".second-path").css({
        "height": $portoCalem.offset().top - $(".brasil .block-second-text").offset().top - $(".brasil-right-image img").height() + $(".first-path svg").width() * 0.053,
        "top": $(".brasil .block-second-text").offset().top - $header.outerHeight() + $(".brasil-right-image img").height() - $(".first-path svg").width() * 0.0265,
        "width": $(".first-path svg").offset().left + $(".first-path svg").width() - ($(".first-path svg").width() * 0.0265)
      });

      $(".second-path svg").css({
        "height": $portoCalem.offset().top - $(".brasil .block-second-text").offset().top - $(".brasil-right-image img").height() + $(".first-path svg").width() * 0.053
      })

      if(!_browserObj.isIE){
        TweenMax.set($(".first-path svg path")[0], {strokeDasharray: $(".first-path svg path")[0].getTotalLength(), strokeDashoffset: $(".first-path svg path")[0].getTotalLength()})
        TweenMax.set($(".first-path svg path")[1], {strokeDasharray: $(".first-path svg path")[1].getTotalLength(), strokeDashoffset: $(".first-path svg path")[1].getTotalLength()})
        TweenMax.set($(".second-path svg path")[0], {strokeDasharray: $(".second-path svg path")[0].getTotalLength(), strokeDashoffset: $(".second-path svg path")[0].getTotalLength()})
        TweenMax.set($(".first-path"), {top: $introHistoryRightImage.outerHeight() - ($introHistoryRightImage.outerHeight() * 0.13)});
      } else {
        TweenMax.set($(".first-path"), {top: $introHistoryRightImage.outerHeight() - ($introHistoryRightImage.outerHeight() * 0.13 + 100)});
      }

      if(!_browserObj.isMobile)
        $_window.on("resize.lineHistory", $.debounce(10, resizeLineHistory));

    }

    function resizeLineHistory(){
      $(".second-path").css({
        "height": $portoCalem.offset().top - $(".brasil .block-second-text").offset().top - $(".brasil-right-image img").height() + $(".first-path svg").width() * 0.053,
        "top": $(".brasil .block-second-text").offset().top - $header.outerHeight() + $(".brasil-right-image img").height() - $(".first-path svg").width() * 0.0265 + scrollValue,
        "width": $(".first-path svg").offset().left + $(".first-path svg").width() - ($(".first-path svg").width() * 0.0265)
      });

      $(".second-path svg").css({
        "height": $portoCalem.offset().top - $(".brasil .block-second-text").offset().top - $(".brasil-right-image img").height() + $(".first-path svg").width() * 0.053
      });

      TweenMax.set($(".first-path"), {top: $introHistoryRightImage.outerHeight() - ($introHistoryRightImage.outerHeight() * 0.13)});
    }

    this.animate = function(scroll){
      var lineAnimFirst = scaleBetween(scroll + pageHeaderHeight, firstPathLength, 0, targetInitFirst, targetEndFirst);
      var lineAnimSecond = scaleBetween(scroll + pageHeaderHeight, secondPathLength, 0, targetInitSecond, targetEndSecond);
      var lineAnimThird = scaleBetween(scroll + pageHeaderHeight, thirdPathLength, 0, targetInitThird, targetEndThird);
      TweenMax.set($(".second-path svg path")[0], { strokeDashoffset: lineAnimThird})

      if(lineAnimFirst > 0 && !$(".first-path").hasClass("done")){
        TweenMax.set($(".first-path svg path")[0], { strokeDashoffset: lineAnimFirst})
        TweenMax.set($(".first-path svg path")[1], { strokeDashoffset: lineAnimSecond})
      } else {
        $(".first-path").addClass("done");
        TweenMax.set($(".first-path svg path")[0], { strokeDashoffset: 0})
        TweenMax.set($(".first-path svg path")[1], { strokeDashoffset: 0})
      }

      if(lineAnimThird > 0 && !$(".second-path").hasClass("done")){
        TweenMax.set($(".second-path svg path")[0], { strokeDashoffset: lineAnimThird})
      } else {
        $(".second-path").addClass("done");
        TweenMax.set($(".second-path svg path")[0], { strokeDashoffset: 0})
      }
    }

    this.kill = function(){
      if(!_browserObj.isMobile)
        $_window.off("resize.lineHistory");
    }
  }

  function historyScrollAnimations() {
    var $jsBigTitle = $(".js-big-title"),
        $jsSubtitle = $(".js-subtitle"),
        $jsText = $(".js-text"),
        $calemButton = $(".calem-button"),
        $mainPerspective = $(".main-perspective"),
        offset = 100,
        positionsBigTitles = [],
        positionsSubtitles = [],
        positionsText = [],
        positionsButtons = [],
        positionsImgs = [];

    this.init = function(){

      if(_browserObj.isPhone){
        // $(".intro-history-left-image").parent().addClass("main-perspective");
        // $(".intro-history-left-image").addClass("perspective-right");
      }

      $portoCalem.find("p").each(function(){
        var $this = $(this);
        $portoCalem.find("p").addClass("js-text");
      });
      
      $(".js-text").each(function(){
        $(this).attr("data-html", $(this).html());
      });

      $jsBigTitle.each(function(){
        var $this = $(this),
            offset,
            splittedBigTitle = null,
            objBigTitle = {};

        splittedBigTitle = new SplitText($this, {type: "words, chars"});
        $(splittedBigTitle.chars).addClass("big-title-chars");
        if(!_browserObj.isSafari){
          TweenMax.set($(splittedBigTitle.chars), {rotationX: 100, opacity: 0});
        } else {
          if(_browserObj.isMobile){
            TweenMax.set($(splittedBigTitle.chars), {rotationX: 100, opacity: 0});
          }
        }

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objBigTitle.elem = $this;
        objBigTitle.offset = $this.offset().top + offset;
        objBigTitle.active = false;

        positionsBigTitles.push(objBigTitle);
      });

      $(".js-subtitle").each(function(key){
        var $this = $(this),
            offset,
            splittedSubTitle = null,
            objSubtitle = {};

        splittedSubTitle = new SplitText($this, {
          type: "lines"
        });
        $(splittedSubTitle.lines).addClass("subtitle-lines");
        TweenMax.set($this.find(".subtitle-lines"), {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objSubtitle.elem = $this;
        objSubtitle.offset = $this.offset().top + offset;
        objSubtitle.active = false;

        positionsSubtitles.push(objSubtitle);
      });

      $(".js-text").each(function(){
        var $this = $(this),
            offset,
            objText = {};

        TweenMax.set($this, {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objText.elem = $this;
        objText.offset = $this.offset().top + offset;
        objText.active = false;

        positionsText.push(objText);
      });

      $calemButton.each(function(){
        var $this = $(this),
            offset,
            objBtn = {};

        if(!$this.hasClass("intro-button")){
          TweenMax.set($this, {
            y: 50
          });
        }

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 50;
        }

        objBtn.elem = $this;
        objBtn.offset = $this.offset().top + offset;
        objBtn.active = false;

        positionsButtons.push(objBtn);
      });

      $mainPerspective.find("figure").each(function(){
        var $this = $(this),
            offset,
            objImg = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objImg.elem = $this;
        objImg.offset = $this.offset().top + offset;
        objImg.active = false;

        positionsImgs.push(objImg);
      });
    }

    this.inViewport = function(scroll){
      for(var i = 0; i <= positionsBigTitles.length-1; i++){
        if(scroll + _globalViewportH > positionsBigTitles[i].offset && positionsBigTitles[i].active === false){
          TweenMax.staggerTo($(positionsBigTitles[i].elem).find(".big-title-chars"), 1, {opacity: 1, rotationX: 0, ease: Circ.easeOut}, 0.05);
          positionsBigTitles[i].active = true;
        }
        if(i === 0){
          $(positionsBigTitles[0].elem).find("sup").addClass("show");
        }
        if(positionsBigTitles[positionsBigTitles.length-1].active === true)
          break;
      }

      for(var x = 0; x <= positionsSubtitles.length-1; x++){
        if(scroll + _globalViewportH > positionsSubtitles[x].offset && positionsSubtitles[x].active === false){
          TweenMax.staggerTo($(positionsSubtitles[x].elem.find(".subtitle-lines")), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          }, 0.1);
          positionsSubtitles[x].active = true;
        }
        if(positionsSubtitles[positionsSubtitles.length-1].active === true)
          break;
      }

      for(var j = 0; j <= positionsText.length-1; j++){
        if(scroll + _globalViewportH > positionsText[j].offset && positionsText[j].active === false){
          TweenMax.to($(positionsText[j].elem), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          });
          positionsText[j].active = true;
        }
        if(positionsText[positionsText.length-1].active === true)
          break;
      }

      for(var i = 0; i <= positionsButtons.length-1; i++){
        if(scroll + _globalViewportH > positionsButtons[i].offset && positionsButtons[i].active === false){
          TweenMax.to($(positionsButtons[i].elem), 1, {
            autoAlpha: 1,
            y: 0,
            ease: Circ.easeOut
          })
          positionsButtons[i].active = true;
        }
        if(positionsButtons[positionsButtons.length-1].active === true)
          break;
      }

      for(var i = 0; i <= positionsImgs.length-1; i++){
        if(scroll + _globalViewportH > positionsImgs[i].offset && positionsImgs[i].active === false){
          TweenMax.to($(positionsImgs[i].elem), 1.4, {x:0,y: 0, rotation: 0, opacity: 1, ease: Expo.easeOut});
          positionsImgs[i].active = true;
        }
        if(positionsImgs[positionsImgs.length-1].active === true)
          break;
      }

      if(scroll + _globalViewportH > calemNewBrandOffset + 300 && !calemNewBrandVisible){
        calemNewBrandVisible = true;
        $calemNewBrand.addClass("active");
      }
    }

    this.kill = function(){

    }
  }

  /*page functions*/
  function initEvents() {
  }

  function historyPage_scroll_rAF(status) {
    if(!_buroScroll) {
      var status = { scrollVal: window.pageYOffset }

      if(lastScroll - status.scrollVal < 0){
        status.direction = 'down';
      } else if(lastScroll - status.scrollVal > 0){
        status.direction = 'up';
      }

      lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
    }
    
    scrollValue = status.scrollVal;
    _globalHeader.animate(status.scrollVal, status.direction);
    historyScrollAnimationsObj.inViewport(status.scrollVal);
    globalCtaCavesObj.animate(status.scrollVal, $(".page-current").height(), status.direction);
    if($_window.width() >= 1024 && !_browserObj.isIE){
      lineHistoryObj.animate(status.scrollVal);
    }
    if(!_browserObj.isMobile){
      historyParallaxElements.update(status.scrollVal);
      if(!_browserObj.isSafari){
        illustrationHistoryObj.controls(status.scrollVal);
      }
      beforeAfterObj.inViewport(status.scrollVal);
    }
  }

  return {
    init: init,
    kill: kill
  }
}

///BARBA INITIALIZER///
var historyPage = Barba.BaseView.extend({
  namespace: 'history',
  onEnter: function() {
    
  },
  onEnterCompleted: function() {
      // The Transition has just finished.
      _currentView = historyPageFunctions();
      _currentView.init();
  },
  onLeave: function() {
      // A new Transition toward a new page has just started.
      _currentView.kill();
  },
  onLeaveCompleted: function() {
      // The Container has just been removed from the DOM.
  }
});


historyPage.init();