var homeTransition = Barba.BaseTransition.extend({
  start: function () {

    $_body.addClass("js-loading-page");

    // As soon the loading is finished and the old page is faded out, let's fade the new page
    Promise.all([this.newContainerReady, this.newContainerLoading])
      .then(this.fadeOut.bind(this))
      .then(this.fadeIn.bind(this));
  },

  fadeOut: function () {
    var _this = this;
    return new Promise(function (resolve, reject) {

      var mouseX, mouseY, raf_loading;
      $(".loading-svg").addClass("white-version");
      if(!_browserObj.isMobile){
        TweenMax.set(".loading-svg", {autoAlpha: 1, scale: 1});
      } else {
        TweenMax.set(".loading-svg", {
          autoAlpha: 1, 
          scale: 1, 
          delay: 0.5,
          y: (_globalViewportH - $(".loading-svg").outerHeight())/2 + $(_this.newContainer).find(".page-header .big-titles").outerHeight()
        });
      }
      TweenMax.to(".loading-svg", 1, {rotation: "+=60", repeat: -1, ease: Linear.easeNone});
      if(!_browserObj.isMobile){
        $_window.on("mousemove.loading", function (e) {
          mouseX = e.clientX - $(".loading-svg").outerWidth() / 2;
          mouseY = e.clientY - $(".loading-svg").outerHeight() / 2;
        });
  
        loadingLoop();
        TweenMax.set(".loading-svg", {x: (window.innerWidth - $(".loading-svg").outerWidth()) / 2, y: (window.innerHeight - $(".loading-svg").outerHeight()) / 2})
  
        function loadingLoop() {
          TweenMax.to(".loading-svg", .6, {
              x: mouseX,
              y: mouseY,
              ease: Circ.easeOut
          });
          raf_loading = _rAF_loop(loadingLoop);
        } 
      }

      if (!_browserObj.isMobile) {
        yValue = "0";
      } else {
        yValue = "-50%";
      }

      $("#header-main").css("pointer-events", "none");
      TweenMax.set($(".loading-layout .loading-titles-container"), {
        autoAlpha: 0
      });
      setTimeout(function () {
        $("#header-main").removeClass("header-collapse");
      }, 100);
      $(".header-link").eq(2).removeClass("active-blue");
      $(".header-link.active").removeClass("active");
      $(".loading-layout .second-big-titles").addClass("uppercase").addClass("home-styles");
      $(".loading-layout").addClass("blue-version");
      TweenMax.set($(".loading-layout h3"), {
        display: "none"
      });
      TweenMax.set(".loading-layout", {
        clearProps: "paddingTop"
      });
      TweenMax.set($(".loading-layout .second-big-titles"), {
        clearProps: "all"
      });
      TweenMax.set($(_this.newContainer).find(".big-square-home"), {
        scaleY: 1
      });
      TweenMax.set(".loading-layout", {
        paddingTop: $(_this.newContainer).find(".big-titles").position().top
      });
      $(".loading-layout .second-big-titles").html($(_this.newContainer).find(".page-header .big-titles").html());
      $(".loading-layout .second-big-titles").append('<span>' + $(_this.newContainer).find(".page-header .big-titles").html() + '</span>');
      TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.6, {
        fill: "#ffffff",
        ease: Circ.easeOut
      });
      TweenMax.to($(".logo-image"), 0.6, {
        alpha: 1,
        delay: 0.1,
        ease: Expo.easeInOut
      });
      TweenMax.to($("#header-main"), 1, {
        y: 0,
        ease: Expo.easeInOut
      });
      setTimeout(function(){
        if(_browserObj.isMobile){
            if($("nav.show-mobile").hasClass("menu-is-open")){
                $(".hamburguer").click();
            }
        }
      }, 400)
      TweenMax.to(".loading-layout", 1, {
        clip: "rect(0px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
        ease: Expo.easeInOut,
        onComplete: function () {
          $_window.scrollTop(0);
          TweenMax.set(_this.oldContainer, {
            autoAlpha: 0,
            onComplete: function () {
              TweenMax.to(".loading-svg", 0.2, {opacity: 0, scale: 0.9, ease: Expo.easeOut, onComplete: function(){
                $(".loading-svg").removeClass("white-version");
              }});
              TweenMax.set($(_this.oldContainer).find(".page-current"), {
                clearProps: "transform"
              });
              TweenMax.set(_this.oldContainer, {
                display: 'none'
              });
              resolve();
              if(!_browserObj.isMobile){
                $_window.off("mousemove.loading");
                cancelAnimationFrame(raf_loading);
              }
              setTimeout(function () {
                TweenMax.to($(_this.newContainer).find(".destaques-slider-wrapper li"), 1, {
                  y: yValue,
                  delay: 0.3,
                  ease: Circ.easeOut
                });
                TweenMax.to($(_this.newContainer).find(".arrow-destaques"), 0.6, {
                  x: 0,
                  opacity: 0.4,
                  delay: 0.5,
                  ease: Circ.easeOut,
                  onComplete: function () {
                    TweenMax.set($(_this.newContainer).find(".arrow-destaques"), {
                      clearProps: "opacity"
                    });
                    $(_this.newContainer).find(".arrow-destaques").addClass("active");
                  }
                });
                TweenMax.to($(_this.newContainer).find(".page-content .big-down-circle"), 0.7, {
                  scale: 1,
                  delay: 0.4,
                  ease: Circ.easeInOut
                });
                TweenMax.to($(_this.newContainer).find(".page-content .antonio-alves-image"), 1, {
                  opacity: 1,
                  rotation: 0,
                  scale: 1,
                  delay: 1.3,
                  ease: Circ.easeOut
                });
                TweenMax.to(".loading-layout", 1, {
                  clip: "rect(" + $('.loading-layout').outerHeight() + "px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                  ease: Expo.easeInOut,
                  onComplete: function () {
                    $("#header-main").removeClass("whiteCategory");
                    $("#header-main").removeClass("tawnyCategory");
                    $("#header-main").removeClass("rubyCategory");
                    $("#header-main").removeClass("roseCategory");
                    $(".loading-layout").removeClass("blue-version");
                    $(".loading-layout .second-big-titles").removeClass("history-styles").removeClass("home-styles");
                    TweenMax.set(".loading-layout", {
                      clip: "rect(0px " + $('.loading-layout').outerWidth() + "px 0px 0px)"
                    })
                    $("#header-main").css("pointer-events", "all");
                  }
                });
              }, 200);
            }
          })
        }
      })
    });
  },

  fadeIn: function () {
    var _this = this;
    TweenMax.fromTo(_this.newContainer, .2, {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      onComplete: function () {
        _this.done();
        $_body.removeClass("js-loading-page");
        manageBodyClasses(_this.newContainer, _this.oldContainer)
      }
    });
  }
});