function buroUnderline() {
  var $text = $("[data-underline]");

  $text.each(function() {

    var $this = $(this),
        text = $this.html(),
        s = '<span class="underline">';

      $this.prop('innerHTML', function (_, html) {
      s += html.replace(/&amp;/g, '&').replace(/(g|j|p|q|y|Q|@|{|_|\(|\)|\[|\||\]|}|;|,|§|µ|ç|\/)/g, '</span>$1<span class="underline">');
      s += '</span>'
      $(this).html(s);
      });

  });
}