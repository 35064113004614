function buroParallaxScroll() {
  var elements = document.querySelectorAll('[data-parallax]');
  var parallax_array = [];

  var init = function() {

    elements.forEach(function(item, i){
      var elem = {};

      elem.element = item;
      elem.friction = Number(item.dataset.friction);
      elem.height = item.offsetHeight;
      elem.offsetY = item.getBoundingClientRect().top;

      parallax_array.push(elem);
    });

    window.addEventListener('resize', resize);
  }

  var update = function(scrollVal) {

    for(var i=0,len=parallax_array.length; i<len; i++) {
      if(scrollVal + _globalViewportH > parallax_array[i].offsetY && scrollVal + _globalViewportH < parallax_array[i].offsetY + parallax_array[i].height + _globalViewportH) {
        
        if($_body.hasClass("wine-advice")){
          TweenMax.to(parallax_array[i].element, 1, {
            y: -scrollVal*parallax_array[i].friction*.5,
            ease: Power4.easeOut
          })
        } else {
          var offsetScreen = scrollVal + _globalViewportH - parallax_array[i].offsetY;

          if(parallax_array[i].offsetY < _globalViewportH)
            offsetScreen -= _globalViewportH;
            
          if(offsetScreen < 0)
            offsetScreen = 0;
  
          TweenMax.to(parallax_array[i].element, 1, {
            y: -offsetScreen*parallax_array[i].friction*.5,
            ease: Power4.easeOut
          })
        }
      }
    }
  }

  var resize = function() {
    parallax_array.forEach(function(item, i){
      item.height = item.element.offsetHeight;
      item.offsetY = item.element.getBoundingClientRect().top  + _buroScroll.getStatus().scrollVal;
    });
  }

  var kill = function() {
    window.removeEventListener('resize', resize);
  }

  return {
    init: init,
    update: update,
    kill: kill
  }
}