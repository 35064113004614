/*! -------------------------------------------------------------------------------------------
JAVASCRIPT main engine!

* @Version:    1.0 - 2016
* @author:     Burocratik
* @email:      hello@burocratik.com
* @website:    http://www.burocratik.com
* @preserve
NOTES:
:: js-no-ajax class on body (nao pode ser no html) > take it off with js as soon I work with ajax
:: js-no-ajax = did refresh
:: body.js-byrefresh= when i start by direct link (refresh) do no show content before loading
:: #loading-page.js-loading-page = i need separate byrefresh of this when I have js off
:: js-loading-page = can be used if I need a global style only when I am loading content
:: mobile = tag html is via js, tag body is via php (can't be on html tag or is deleted), also used for IE<=10
:: _global_allowNavigate = do not allow multiple cliks to load ajax (arrow, keys, click)
:: js-no-transPage = when I want a domain link with no transition ajax animation
--------------------------------------------------------------------------------------------*/
$(document).ready(function() {
  //** outdatedbrowser.com
  // Must be the first to be call or in older browsers IE6,7 will have weird js erros on my code, and the plugin will not work
  outdatedBrowser({
    bgColor: "#f25648",
    color: "#fff",
    lowerThan: "borderImage",
    languagePath: ""
  });
  //** MODERNIZR not supporter properties
  // Modernizr.addTest('backgroundcliptext', function() {
  //     var div = document.createElement('div');
  //     div.style.cssText = Modernizr._prefixes.join('background-clip:text;');
  //     return !!div.style.cssText.replace(/\s/g, '').length;
  // });
  // Modernizr.addTest('object-fit', !!Modernizr.prefixed('objectFit') );

  //BURO SIGNATURE CONSOLE
  if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1 || navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
    var e = [
      "%c Made by Büro %c https://burocratik.com %c 🤘 %c\n",
      "color: #fff; background: #0020f4; padding:5px 0;",
      "color: #fff; background: #242424; padding:5px 0 5px 5px;",
      "background: #242424; padding:5px 0",
      "background: #242424; padding:5px 5px 5px 0"
    ];
    window.console.log.apply(console, e);
  } else {
    window.console && window.console.log("Made by Büro - https://burocratik.com");
  }
  /////////////////////////
}); //END LOAD DOCUMENT

/********************************************************************************************
 **                                                                                       **
      =LOADING PAGES, SECTIONS - =transitions between pages, =ajax
 **                                                                                       **
*********************************************************************************************/

/********************************************************************************************
 **                                                                                       **
     =START EACH PAGE - refresh or ajax
 **                                                                                       **
*********************************************************************************************/
function onStartPageWhenRefresh(byRefresh) {
  if (byRefresh) {
    $("html,body").addClass("fixed-all");

    var mouseX, mouseY, raf_loading;
    TweenMax.to(".loading-svg", 20, { rotation: 720, repeat: -1, ease: Linear.easeNone });
    if (!_browserObj.isMobile) {
      $_window.on("mousemove.loading", function(e) {
        mouseX = e.clientX - $(".loading-svg").outerWidth() / 2;
        mouseY = e.clientY - $(".loading-svg").outerHeight() / 2;
      });

      loadingLoop();
      TweenMax.set(".loading-svg", { x: (window.innerWidth - $(".loading-svg").outerWidth()) / 2, y: (window.innerHeight - $(".loading-svg").outerHeight()) / 2 });

      function loadingLoop() {
        TweenMax.to(".loading-svg", 0.6, {
          x: mouseX,
          y: mouseY,
          ease: Circ.easeOut
        });
        raf_loading = _rAF_loop(loadingLoop);
      }
    }

    $_body.addClass("js-loading-page");
    $_body.removeClass("js-byrefresh");

    $_toPreload.imagesLoaded(function($images, $proper, $broken) {
      var fPreload = $(this).imagesLoaded();
      fPreload.always(function() {
        TweenMax.to(".loading-svg", 0.2, { opacity: 0, scale: 0.9, ease: Expo.easeOut });
        TweenMax.to($_loadingPage, 0.6, {
          opacity: 0,
          ease: Power4.easeOut,
          onComplete: function() {
            TweenMax.killTweensOf(".loading-svg");
            $("html,body").removeClass("fixed-all");
            $_loadingPage.removeClass("js-loading-page").hide();
            onStartPage();
            if (!_browserObj.isMobile) {
              $_window.off("mousemove.loading");
              cancelAnimationFrame(raf_loading);
            }
          }
        });
      }); //end always
    }); //end preload images
  }
} //////end function

/*-------------------------------------------------------------------------------------------
    =STARTPAGE - EACH PAGE - call of functions and events
--------------------------------------------------------------------------------------------*/
function onStartPage() {
  // ** =ALLOW user load other pages
  _global_allowNavigate = true;

  window.cancelAnimationFrame(_raf_loop_id);

  // ** =REMOVE classes of loading (if needed)
  $("html,body").removeClass("fixed-all");
  $_body.removeClass("js-loading-page");

  ($_currentPage = $(".page-main.page-current")), ($_toPreload = $(".preload-image")), ($_loadingPage = $("#loading-page"));

  $_pageHeader = $(".page-current .page-header");
  $_pageFooter = $(".page-current .page-footer");
  $_pageContent = $(".page-current .page-content");
  $_pageToLoad = $(".page-current .page-toload");

  if (_customScroll != null) {
    $.doTimeout(1000, function() {
      _customScroll.update();
    });
  }

  if ($("html").hasClass("ios")) {
    TweenMax.set("body", {
      position: "",
      delay: 0.5,
      onComplete: function() {
        TweenMax.set(window, {
          scrollTo: {
            y: 1,
            autoKill: false
          }
        });
      }
    });
  }

  if (!_browserObj.isMobile) {
    if ($_scrollContentWrapper.attr("data-scrollbar") != undefined && !$_scrollContentWrapper.hasClass("js-scroll")) {
      _customScroll = Scrollbar.init($_scrollContentWrapper[0], {
        speed: 0.8,
        syncCallbacks: true
      });
      $_scrollContentWrapper.addClass("js-scroll");
    }
  } else {
    $(".scroll-content-wrapper").removeAttr("data-scrollbar");
  }

  if (_customScroll != null) {
    $(".loading-container").css({
      top: 0
    });
    _customScroll.setPosition(0, 0);
    _customScroll.removeListener(_page_scroll_listener);
  }

  // **=POLYFILL ASYNC
  callAsyncFunctions();

  // ** =URL com ancoras onload
  var hasHash = window.location.hash;
  if (hasHash != "") {
    var $toGoHere = $("" + hasHash + "");
    $.doTimeout(500, function() {
      goTo($toGoHere, 1500, [0.7, 0, 0.3, 1], 0);
    });
  }
} //////end function StartPage

/*******************************************************************************************
 ****                                                                                   ****
    =DOCUMENT =READY =START Document ready
 ****                                                                                   ****
*********************************************************************************************/
$(document).ready(function() {
  //** =Global objects
  ($_window = $(window)),
    ($_body = $("body")),
    ($_html = $("html")),
    ($_mainPage = $(".page-main")),
    ($_headerMain = $("#header-main")),
    ($_currentPage = $(".page-main.page-current")),
    ($_toPreload = $(".preload-image")),
    ($_loadingPage = $("#loading-page")),
    ($_pageHeader = $(".page-current .page-header")),
    ($_pageFooter = $(".page-current .page-footer")),
    ($_pageContent = $(".page-current .page-content")),
    ($_scrollContentWrapper = $(".scroll-content-wrapper")),
    ($_pageToLoad = $(".page-current .page-toload"));

  //** =Global variables
  _pageTransition = null;
  _raf_main_id = -1;
  _page_scroll_listener = null;
  _currentView = null;
  _customScroll = null;
  _lastClickEl = null;
  _browserObj = getBrowserInfo();
  _introObj = new intro();
  _globalHeader = new globalHeader();
  _buroScroll = null;
  _linkedClicked = true;
  _titleActiveIndex = 0;
  _portfolioMouseX = 0;
  _portfolioMouseY = 0;
  calculateGlobalValues();
  _global_allowNavigate = false;
  _server_hostname = window.location.origin;

  homeEntranceObj = new homeEntrance();
  contactsEntranceObj = new contactsEntrance();
  historyEntranceObj = new historyEntrance();
  douroEntranceObj = new douroEntrance();
  portfolioEntranceObj = new portfolioEntrance();
  portfolioSingleEntranceObj = new portfolioSingleEntrance();
  wineAdviceEntranceObj = new wineAdviceEntrance();
  wineAdviceSingleEntranceObj = new wineAdviceSingleEntrance();
  termsAndServicesEntranceObj = new termsAndServicesEntrance();
  four0fourEntranceObj = new four0fourEntrance();
  globalCtaCavesObj = new globalCtaCaves();
  TweenMax.set(".loading-layout", {
    clip: "rect(0px " + $(".loading-layout").outerWidth() + "px 0px 0px)"
  });

  if ($_body.hasClass("js-no-ajax")) {
    globalCtaCavesObj.init($_currentPage.attr("data-namespace"));
  }

  if ($("html").hasClass("ios")) {
    TweenMax.set("body", {
      position: "fixed"
    });
  }

  setTimeout(function() {
    $(".iubenda-cs-close-btn").css({
      "font-size": "",
      "background-color": ""
    });
    TweenMax.to($("body").find("#iubenda-cs-banner"), 1, {
      opacity: 1,
      y: 0,
      ease: Power4.out
    });
  }, 2000);

  // Request Animation Frame
  _rAF_loop =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    // IE Fallback, you can even fallback to onscroll
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    };

  _raf_loop_id = null;

  // VW/VH Unis fix for IOS
  // Usar esta CSS em todos os VH logo a seguir à medida VH (height: calc(var(--vh, 1vh) * 100);)
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");

  //** =START PAGES
  onStartPageWhenRefresh(true);
  navMobile();

  if (_browserObj.isIE) {
    $("a").each(function() {
      $(this).addClass("no-barba");
    });
  }

  Barba.Dispatcher.on("linkClicked", function(el) {
    _lastClickEl = el;
    _linkedClicked = true;

    Barba.Pjax.getTransition = function() {
      var transition = $(_lastClickEl).attr("data-transition");

      if (window.location.href.indexOf("local") === -1) {
        ga("send", "pageview", window.location.pathname); //analytics
      }

      globalCtaCavesObj.init(transition);
      switch (transition) {
        case "home":
          return homeTransition;
          break;

        case "contacts":
          return contactsTransition;
          break;

        case "history":
          return historyTransition;
          break;

        case "douro":
          return douroTransition;
          break;

        case "wine-advice":
          return wineAdviceTransition;
          break;

        case "portfolio":
          return portfolioTransition;
          break;

        case "single-portfolio":
          return singlePortfolioTransition;
          break;

        case "wine-advice-single":
          return wineAdviceSingleTransition;
          break;

        case "category-change":
          return categoryChangeTransition;
          break;

        case "terms-and-services":
          return termsAndServicesTransition;
          break;

        case "next-footer-category":
          return nextFooterCategoryTransition;
          break;

        default:
          return defaultTransition;
          break;
      }
    };
  });

  Barba.Dispatcher.on("initStateChange", function(currentStatus) {
    if (!_linkedClicked) {
      location.reload();
    }
    _linkedClicked = false;
  });

  Barba.Pjax.Dom.wrapperId = "container-wrapper";
  Barba.Pjax.Dom.containerClass = "page-current";

  Barba.Pjax.start();
  Barba.Prefetch.init();

  manageBodyClassesRefresh();
  callAsyncFunctions();

  /* -------------------------------------------------------------------------------------------
  =EVENTS DEFAULT BURO
  -------------------------------------------------------------------------------------------- */
  //OPEN NEW WINDOW
  $(document).on("click", "a[rel=external]", function(event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    var linkExterno = window.open($(this).attr("href"));
    return linkExterno.closed;
  });
  //PRINT
  $("a[rel=print]").click(function() {
    var imprimir = window.print();
    return false;
  });
  //E-MAIL: has classclass="email", e [-at-]
  $("a.email").each(function() {
    var mailReal = $(this)
      .text()
      .replace("[-at-]", "@");
    $(this).text(mailReal);
    $(this).attr("href", "mailto:" + mailReal);
  });

  /*-------------------------------------------------------------------------------------------
  =RESIZE on
  --------------------------------------------------------------------------------------------*/
  $_window.on(
    "resize",
    $.debounce(500, function() {
      //** =recalculate global variables
      calculateGlobalValues();
      _browserObj = getBrowserInfo();

      if ($_html.hasClass("no-object-fit")) {
        var $element = $(".page-current .element-cover");
        resizeLikeCover($element);
      }

      // VW/VH Unis fix for IOS
      // Usar esta CSS em todos os VH logo a seguir à medida VH (height: calc(var(--vh, 1vh) * 100);)
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");
    })
  ); //end resize window

  window.addEventListener("orientationchange", function() {
    $.doTimeout(500, function() {
      _browserObj = getBrowserInfo();
    });

    if ($_body.hasClass("tablet")) {
      location.reload();
    }
  });
}); //END LOAD DOCUMENT
