var singlePortfolioTransition = Barba.BaseTransition.extend({
    start: function () {

        $_body.addClass("js-loading-page");

        // As soon the loading is finished and the old page is faded out, let's fade the new page
        Promise.all([this.newContainerReady, this.newContainerLoading])
            .then(this.fadeOut.bind(this))
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        var _this = this,
            titlesArray = [];

        return new Promise(function (resolve, reject) {

            $("nav .main-links li").removeClass("disable");
            TweenMax.set("#header-main .main-logo .logo-type svg path", {
                clearProps: "fill"
            });
            TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.2, {
                fill: "#06214D",
                ease: Circ.easeOut
            });

            for (var i = 0; i < $(_this.newContainer).find(".header-titles-container .header-titles").find("li").length; i++) {
                titlesArray.push(-(_globalViewportH * 0.62 + 100) * i);
            }

            TweenMax.set($(_this.newContainer).find(".line-scroll"), {
                opacity: 1
            });

            TweenMax.to($(_this.oldContainer).find(".custom-cursor"), 1, {
                opacity: 0,
                ease: Expo.easeOut
            });

            $("#header-main").removeClass("whiteCategory, tawnyCategory, rubyCategory, roseCategory");
            $("#header-main").css("pointer-events", "none");
            $(".header-link").eq(2).removeClass("active-blue");
            TweenMax.to(".bottle-canvas-container canvas", 0.05, {
                opacity: 0,
                ease: Expo.easeOut
            });

            $(_this.newContainer).find(".header-titles-container .header-titles").find("li").each(function (key) {
                var $this = $(this);
                $this.css({
                    "width": _globalViewportH * 0.62,
                    "margin-right": "100px"
                });
            });

            $(_this.newContainer).find(".header-titles-container .header-titles").css("left", (_globalViewportW - _globalViewportH * 0.62) / 2);
            TweenMax.set($(_this.newContainer).find(".header-titles-container .header-titles"), {
                x: titlesArray[_titleActiveIndex]
            })

            $(".lines path").each(function () {
                var $this = $(this)[0];
                TweenMax.to($this, 2, {
                    strokeDasharray: $this.getTotalLength(),
                    strokeDashoffset: $this.getTotalLength(),
                    ease: Expo.easeOut
                });
            });

            if ($(_this.newContainer).find(".suggestion").length != 0) {
                TweenMax.set($(_this.newContainer).find(".suggestion"), {
                    opacity: 0
                });
            }

            TweenMax.to($(_this.oldContainer).find(".buttons-container"), 0.6, {
                clip: "rect(0px " + $(_this.oldContainer).find(".buttons-container").outerWidth() / 2 + "px " + $(_this.oldContainer).find(".buttons-container").outerHeight() + "px " + $(_this.oldContainer).find(".buttons-container").outerWidth() / 2 + "px)",
                ease: Power4.easeOut
            });

            TweenMax.to(".separator", 0.8, {
                scaleX: 0,
                ease: Expo.easeOut
            });

            TweenMax.staggerTo($(_this.oldContainer).find(".category-text-wrapper.active").find(".line"), 0.8, {
                y: -10,
                opacity: 0,
                ease: Circ.easeOut
            }, 0.03);

            TweenMax.staggerTo(".dragger-wrapper figure", 1, {
                opacity: 0,
                ease: Expo.easeOut
            }, 0.05);

            TweenMax.set($(_this.newContainer).find(".header-titles-container .header-titles li"), {
                opacity: 1
            })

            TweenMax.set($(_this.newContainer).find(".page-header p"), {
                y: 10,
                opacity: 0,
                ease: Circ.easeOut
            }, 0.03);

            TweenMax.set($(_this.newContainer).find(".element"), {
                opacity: 0,
            });

            if ($(_this.newContainer).find(".preserve-icon")) {
                TweenMax.set($(_this.newContainer).find(".preserve-icon"), {
                    scale: 0.8,
                    opacity: 0,
                });
            }

            $(_this.newContainer).find(".header-titles-container").addClass("hide-before");

            setTimeout(function () {
                if (_browserObj.isMobile) {
                    if ($("nav.show-mobile").hasClass("menu-is-open")) {
                        $(".hamburguer").click();
                    }
                }
            }, 50)

            if (_browserObj.isMobile) {
                TweenMax.to([$(_this.oldContainer).find(".pseudo-circle"), $(_this.oldContainer).find(".buttons-container-mobile"), $(_this.oldContainer).find(".drag-helper-mobile")], 0.6, {
                    opacity: 0,
                    ease: Expo.easeOut
                });
            }

            TweenMax.to($(".dragger-circle .circle-bottle-wrapper .bottle").eq(_titleActiveIndex), 1, {
                y: _globalViewportH,
                ease: Circ.easeInOut,
                onComplete: function () {
                    $_window.scrollTop(0);
                    TweenMax.set(_this.oldContainer, {
                        autoAlpha: 0,
                        onComplete: function () {
                            TweenMax.set($(_this.oldContainer).find(".page-current"), {
                                clearProps: "transform"
                            });
                            TweenMax.set(_this.oldContainer, {
                                display: 'none'
                            });
                            resolve();

                            $(_this.newContainer).find(".header-titles-container").removeClass("hide-before");
                            $(".header-titles-container").addClass("remove-opacity");

                            if ($(_this.newContainer).find(".preserve-icon")) {
                                TweenMax.to($(_this.newContainer).find(".preserve-icon"), 0.8, {
                                    scale: 1,
                                    opacity: 1,
                                    delay: 0.5,
                                    ease: Expo.easeOut
                                });
                            }

                            if ($(_this.newContainer).find(".suggestion").length != 0) {
                                TweenMax.to($(_this.newContainer).find(".suggestion"), 0.8, {
                                    opacity: 1,
                                    delay: 0.5,
                                    ease: Expo.easeOut
                                });
                            }

                            TweenMax.to($(_this.newContainer).find(".element"), 1, {
                                delay: 0.5,
                                ease: Expo.easeOut,
                                opacity: 1
                            });

                            TweenMax.staggerTo($(_this.newContainer).find(".page-header p"), 1, {
                                y: 0,
                                opacity: 1,
                                delay: 0.3,
                                ease: Circ.easeOut
                            }, 0.1);

                            $("#header-main").css("pointer-events", "all");
                        }
                    });
                }
            })
        });
    },

    fadeIn: function () {
        var _this = this;
        TweenMax.fromTo(_this.newContainer, 0, {
            autoAlpha: 0
        }, {
            autoAlpha: 1,
            onComplete: function () {
                _this.done();
                $_body.removeClass("js-loading-page");
                manageBodyClasses(_this.newContainer, _this.oldContainer)
            }
        });
    }
});