function intro() {

    var $intro = $(".intro"),
        $introTitle = $(".intro-title"),
        $introButton = $(".intro-button"),
        $bigSquare = $(".intro .big-square"),
        $bigSquareContainer = $(".big-square-container-intro"),
        $antonioAlvesImage = $(".antonio-alves-image"),
        $languageContainer = $(".languages-container");

    var bigSquareContainerHeight = $bigSquareContainer.outerHeight(),
        headerHeight = $_headerMain.outerHeight(true),
        introTitleHeight = $introTitle.outerHeight(),
        antonioAlvesImageHeight = $antonioAlvesImage.outerHeight(),
        introTitleLine,
        pageName = $(".page-current").attr("data-namespace");


    this.init = function (f) {
        headerLinks = new SplitText($("#header-main .header-link:not(.logo)"), {
            type: "words, chars"
        });
        $(headerLinks.chars).addClass("header-links-chars");

        if (cookie.get('cookie-age') == 'true') {
            TweenMax.set($intro, {
                autoAlpha: 0
            });

            if (_browserObj.isMobile && $_window.width() >= 1024) {
                TweenMax.set($languageContainer, {
                    y: -$languageContainer.outerHeight()
                })
            }
            if (_browserObj.isMobile && $_window.width() < 1024 || !_browserObj.isMobile) {
                TweenMax.set($languageContainer, {
                    x: -$languageContainer.outerWidth()
                })
            }

            if(!_browserObj.isMobile){
                TweenMax.to($languageContainer, 1, {
                    x: 0,
                    ease: Expo.easeOut
                })
            }
            
            TweenMax.set($("#header-main .header-link:not(.logo)"), {
                autoAlpha: 1
            });
            TweenMax.staggerTo($(".header-links-chars"), 2, {
                y: 0,
                autoAlpha: 1,
                delay: 1.2,
                ease: Circ.easeOut
            }, 0.05);
            
            if (_browserObj.isMobile && $_window.width() >= 1024) {
                TweenMax.to($languageContainer, 1, {
                    y: -$languageContainer.outerHeight(),
                    delay: 1.2,
                    ease: Circ.easeOut
                })
            }

            if ($_window.width() <= 768) {
                TweenMax.to($(".hamburguer"), 1, {
                    autoAlpha: 1,
                    ease: Circ.easeOut
                });
            }

            if (typeof f === 'function') {
                f();
            }

        } else {
            $(".main-logo").addClass("white-version");
            if (!_browserObj.isMobile) {
                TweenMax.set($languageContainer, {
                    x: 0,
                });
            }
            $introTitle.css("marginTop", ((bigSquareContainerHeight + headerHeight - antonioAlvesImageHeight / 2) - introTitleHeight) / 2);
            $introButton.on("click", function () {
                cookie.set('cookie-age', true);

                if (_browserObj.isMobile && $_window.width() >= 1024) {
                    TweenMax.to($languageContainer, 1,{
                        y: -$languageContainer.outerHeight(),
                        ease: Expo.easeOut
                    })
                }
                if (_browserObj.isMobile && $_window.width() < 1024 || !_browserObj.isMobile) {
                    TweenMax.to($languageContainer, 1, {
                        x: -$languageContainer.outerWidth(),
                        ease: Expo.easeOut
                    })
                }

                if(!_browserObj.isMobile){
                    TweenMax.to($languageContainer, 1, {
                        x: 0,
                        ease: Expo.easeOut
                    })
                }

                TweenMax.staggerTo($(".header-links-chars"), 2, {
                    y: 0,
                    autoAlpha: 1,
                    ease: Circ.easeOut
                }, 0.05);

                TweenMax.staggerTo(introTitleLine.lines, 0.8, {
                    y: -20,
                    opacity: 0,
                    ease: Circ.easeOut
                }, 0.05)

                if ($_window.width() <= 768)
                    TweenMax.to($(".hamburguer"), 1, {
                        autoAlpha: 1,
                        ease: Circ.easeOut
                    });

                switch (pageName) {
                    case 'home':
                        homeEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'contactos':
                        contactsEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'history':
                        historyEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'douro':
                        douroEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'portfolio':
                        portfolioEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'portfolio-archive':
                        portfolioSingleEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'wine-advice':
                        wineAdviceEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'terms-and-services':
                        termsAndServicesEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'wine-advice-single':
                        wineAdviceSingleEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    case 'four0four':
                        four0fourEntranceObj.animateFromIntro(function () {
                            f();
                        });
                        break;

                    default:
                        TweenMax.to($intro, 1, {
                            autoAlpha: 0,
                            ease: Circ.easeOut,
                            onComplete: function () {
                                $(".main-logo").removeClass("white-version");
                                if ($_window.width() <= 768)
                                    TweenMax.to($(".hamburguer"), 1, {
                                        autoAlpha: 1,
                                        ease: Circ.easeOut
                                    });

                                TweenMax.to($("#header-main .header-link:not(.logo)"), 1, {
                                    autoAlpha: 1,
                                    ease: Circ.easeOut
                                });
                                f();
                            }
                        });
                        break;
                }
            });

            // TweenMax.set($("#header-main .main-logo"), {
            //     y: (_globalViewportH - $("#header-main").outerHeight() - 40) / 2
            // });
            introTitleLine = new SplitText($introTitle, {
                type: "lines"
            });
            $(introTitleLine.lines).addClass("lines");

            $bigSquare.addClass("from-top");
        }

        $_window.on("resize.Intro", $.debounce(500, this.resize));
    }

    this.resize = function () {
        bigSquareContainerHeight = $bigSquareContainer.outerHeight();
        headerHeight = $_headerMain.outerHeight(true);
        introTitleHeight = $introTitle.outerHeight();
        antonioAlvesImageHeight = $antonioAlvesImage.outerHeight();

        $introTitle.css("marginTop", ((bigSquareContainerHeight - headerHeight - antonioAlvesImageHeight / 2) - introTitleHeight) / 2);
    }

    this.kill = function () {
        $_window.off("resize.Intro");
    }


}