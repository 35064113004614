function douroEntrance() {

    var $bigTitle = $(".page-header .js-char"),
        $bigSquareDouro = $(".big-square-douro"),
        $lineSup = $bigTitle.find("sup"),
        $illustrationDouro = $("#illustration-douro"),
        $bigDownCircle = $(".intro .big-down-circle"),
        $antonioAlvesImage = $(".intro .antonio-alves-image"),
        $languageContainer = $(".languages-container");


    var bigTitleChars = null,
        bigTitleSplitted = null,
        timelineAnimateIn = new TimelineMax({
            paused: true
        }),
        timelineAnimateFromIntro = new TimelineMax({
            paused: true
        });

    this.init = function () {
        bigTitleSplitted = new SplitText($bigTitle, {
            type: "chars"
        });
        bigTitleChars = bigTitleSplitted.chars;

        TweenMax.set($(bigTitleChars), {
            rotationX: 45,
            opacity: 0
        });
    }

    this.animateIn = function (f) {
        TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.2, {
            fill: "#ffffff",
            ease: Circ.easeOut
        });
        timelineAnimateIn.to($bigSquareDouro, 0.6, {
                scaleY: 1,
                ease: Circ.easeInOut,
                onComplete: function () {
                    if(_browserObj.isMobile){
                        TweenMax.to($(".header-douro-mobile"), 1, {
                            opacity: 1
                        })
                    } else {
                        TweenMax.set($illustrationDouro, {
                            opacity: 1
                        });
                    }
                    f();
                }
            })
            // .to($languageContainer, 1, {
            //     x: 0,
            //     ease: Circ.easeOut
            // }, "0.5")
            .staggerTo($(bigTitleChars), 3, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut,
                onComplete: function () {
                    $lineSup.addClass("show");
                }
            }, 0.05, "1");

        timelineAnimateIn.play();
    }

    this.animateFromIntro = function (f) {
        TweenMax.set($bigSquareDouro, {
            scaleY: 1
        });
        TweenMax.set($illustrationDouro, {
            opacity: 1
        });

        timelineAnimateFromIntro.to($(".big-square-container-intro"), 0.8, {
                height: _globalViewportH,
                ease: Circ.easeOut,
                onComplete: function () {
                    f();
                }
            }, "0.1")
            .to(".intro .chars", 0.6, {
                opacity: 0,
                ease: Circ.easeOut
            }, 0.003, "0")
            .to($bigDownCircle, 0.7, {
                scale: 0,
                ease: Circ.easeOut
            }, "0.2")
            .to($antonioAlvesImage, 0.8, {
                opacity: 0,
                rotation: -15,
                scale: .8,
                ease: Circ.easeOut
            }, "0")
            .to($(".intro-button"), 0.6, {
                y: 200,
                ease: Circ.easeOut
            }, "0.2")
            .to($(".policy"), 0.8, {
                y: 200,
                ease: Circ.easeOut,
                onComplete: function () {
                    TweenMax.set($(".intro"), {
                        autoAlpha: 0
                    });
                    TweenMax.staggerTo($(bigTitleChars), 3, {
                        rotationX: 0,
                        opacity: 1,
                        ease: Circ.easeOut,
                        onComplete: function () {
                            $lineSup.addClass("show");
                        }
                    }, 0.05);
                }
            }, "0")


        timelineAnimateFromIntro.play();
    }
}