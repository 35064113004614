var defaultTransition = Barba.BaseTransition.extend({
  start: function() {

    $_body.addClass("js-loading-page");
    
    // As soon the loading is finished and the old page is faded out, let's fade the new page
    Promise.all([this.newContainerReady, this.newContainerLoading])
            .then(this.fadeOut.bind(this))
            .then(this.fadeIn.bind(this));
  },

  fadeOut: function() {
    var _this = this;
    return new Promise(function(resolve, reject) {
      TweenMax.fromTo(_this.oldContainer, .2, { 
        autoAlpha: 1
      },
      {
        autoAlpha: 0,
        onComplete: function() {
          TweenMax.set(_this.oldContainer, { display: 'none' });
          resolve();
        }
      });
    });
  },

  fadeIn: function() {
    var _this = this;
    TweenMax.fromTo(_this.newContainer, .2, { 
      autoAlpha: 0
    },
    {
      autoAlpha: 1,
      onComplete: function() {
        _this.done();
        $_body.removeClass("js-loading-page");
        manageBodyClasses(_this.newContainer, _this.oldContainer)
      }
    });
  }
});