function destaquesSlider() {

    var $destaquesSliderContainer = $(".destaques-slider-container"),
        $destaquesSliderWrapper = $(".destaques-slider-wrapper"),
        $arrow = $(".arrow-destaques");

    var current = 0,
        totalDestaques = $destaquesSliderWrapper.find("li").length,
        canClick = true,
        loopRafDestaques = null,
        number = 0,
        destaquesSliderContainerOffset = $destaquesSliderContainer.offset().top;

    function loop(){
        if(number > 60*3){
            number = 0;
            animate('right');
            canClick = false;
        }            
        number++;
        loopRafDestaques = _rAF_loop(loop);
    }
        
        
    this.init = function(){
        loop();
        if(!_browserObj.isMobile){
            $destaquesSliderWrapper.css("height", $destaquesSliderWrapper.find("li").outerHeight());
        }
        $destaquesSliderWrapper.find("li").each(function(){
            var $this = $(this),
                destaqueLine = new SplitText($this, {type:"words, chars"}),
                destaquesChars = destaqueLine.chars;

            $(destaquesChars).addClass("chars");
        });

        $arrow.on("click", function(){
            cancelAnimationFrame(loopRafDestaques);
            var $this = $(this),
                direction = $this.attr("data-direction");

            if(canClick){
                canClick = false;
                animate(direction);
            }
        });
    }

    this.inViewport = function(scroll){
        if(scroll > _globalViewportH && !$destaquesSliderContainer.hasClass("is-paused")){
            $destaquesSliderContainer.addClass("is-paused");
            cancelAnimationFrame(loopRafDestaques);
        } else if(scroll < _globalViewportH && $destaquesSliderContainer.hasClass("is-paused")){
            loop();
            $destaquesSliderContainer.removeClass("is-paused");
        }
    }

    function animate(direction) {
        if(direction == 'right'){
            TweenMax.staggerTo($destaquesSliderWrapper.find("li").eq(current).find(".chars"), 0.8, {
                x: 10, 
                opacity: 0, 
                ease: Circ.easeOut
            }, 0.01);
            TweenMax.staggerFromTo($destaquesSliderWrapper.find("li.next").find(".chars"), 0.8, {
                x: -10, 
                opacity: 0, 
            }, {
                x: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.01, function(){
                $destaquesSliderWrapper.find("li.active").removeClass("active");
                $destaquesSliderWrapper.find("li.next").removeClass("next").addClass("active").next().addClass("next");
                TweenMax.set($destaquesSliderWrapper.find("li .chars"), {clearProps: "all"});
                canClick = true;
                if($destaquesSliderWrapper.find("li.active").next().length == 0){
                    $destaquesSliderWrapper.find("li").eq(0).addClass("next");
                }
            });
            if(current < totalDestaques-1){
                current++;
            } else {
                current = 0;
            }
        } else {
            if($destaquesSliderWrapper.find("li.active").prev().length == 0){
                TweenMax.staggerTo($destaquesSliderWrapper.find("li").eq(0).find(".chars"), 0.8, {
                    x: -10, 
                    opacity: 0, 
                    ease: Circ.easeOut
                }, 0.01);
                TweenMax.staggerFromTo($destaquesSliderWrapper.find("li").last().find(".chars"), 0.8, {
                    x: 10, 
                    opacity: 0, 
                }, {
                    x: 0,
                    opacity: 1,
                    ease: Circ.easeOut
                }, 0.01, function(){
                    $destaquesSliderWrapper.find("li.active").removeClass("active");
                    $destaquesSliderWrapper.find("li.next").removeClass("next");
                    $destaquesSliderWrapper.find("li").last().addClass("active");
                    $destaquesSliderWrapper.find("li").eq(0).addClass("next");
                    TweenMax.set($destaquesSliderWrapper.find("li .chars"), {clearProps: "all"});
                    current = totalDestaques - 1;
                    canClick = true;
                });
            } else {
                TweenMax.staggerTo($destaquesSliderWrapper.find("li").eq(current).find(".chars"), 0.8, {
                    x: -10, 
                    opacity: 0, 
                    ease: Circ.easeOut
                }, 0.01);
                TweenMax.staggerFromTo($destaquesSliderWrapper.find("li.active").prev().find(".chars"), 0.8, {
                    x: 10, 
                    opacity: 0, 
                }, {
                    x: 0,
                    opacity: 1,
                    ease: Circ.easeOut
                }, 0.01, function(){
                    $destaquesSliderWrapper.find("li.next").removeClass("next");
                    $destaquesSliderWrapper.find("li.active").removeClass("active").addClass("next").prev().addClass("active");
                    TweenMax.set($destaquesSliderWrapper.find("li .chars"), {clearProps: "all"});
                    canClick = true;
                });
                if(current > 0) {
                    current--;
                } else {
                    current = 0;
                }
            }
        }
    }

    this.kill = function(){
        cancelAnimationFrame(loopRafDestaques);
    }
}