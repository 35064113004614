function douroPageFunctions() {

  if ($_scrollContentWrapper.attr("data-buroScrollbar") != undefined && !_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
    _buroScroll = new buroVirtualScroll("body", ".page-current", .5, true);
    _buroScroll.init();

    _page_scroll_listener = function (status) {
      douroPage_scroll_rAF(status);
    };
    _buroScroll.addListener(_page_scroll_listener)
  } else {
    _scrollRef = function () {
      _raf_loop_id = _rAF_loop(douroPage_scroll_rAF);
    }
    $_window[0].addEventListener("scroll", _scrollRef, {
      passive: true
    });
  }

  /*DOM variables*/
  var $illustrationDouroContainer = $("#illustration-douro"),
      $truck = $("#truck"),
      $headerDouroMobile = $(".header-douro-mobile");

  /*Variables*/
  var illustrationDouro = bodymovin.loadAnimation({
      container: $illustrationDouroContainer[0],
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/public/bodymovin/douro.json'
    }),
    illustrationTruck = bodymovin.loadAnimation({
      container: $truck[0],
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/public/bodymovin/truck.json'
    }),
    illustrationDouroObj = new illustrationDouroControls(),
    illustrationDouroHeight = $(".page-header").outerHeight(),
    douroParallaxElements = buroParallaxScroll(),
    douroScrollAnimationsObj = new douroScrollAnimations(),
    lastScroll = 0;

  var init = function () {

    /*inits*/
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
      _buroScroll.blockBuroScroll();
    else
      disableBodyScroll(true);

    if ($_body.hasClass("js-no-ajax")) {
      _introObj.init(function () {
        if (cookie.get('cookie-age') != undefined) {
          $.doTimeout(100, function(){
            douroEntranceObj.animateIn(function () {
              if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                if(!_browserObj.isSafari){
                  illustrationDouroObj.init();
                } else {
                  TweenMax.to($headerDouroMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
                }
                _buroScroll.unblockBuroScroll();
                _buroScroll.update();
              } else {
                disableBodyScroll(false);
                douroScrollAnimationsObj.inViewport(10);
              }
            });
          })
        } else {
          if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
            _buroScroll.unblockBuroScroll();
            _buroScroll.update();
            if(!_browserObj.isSafari){
              illustrationDouroObj.init();
            } else {
              TweenMax.to($headerDouroMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
            }
            $.doTimeout(1000, function () {
              _buroScroll.update();
            });
          } else {
            disableBodyScroll(false);
            douroScrollAnimationsObj.inViewport(10);
          }
        }
      });
      douroEntranceObj.init();
      douroScrollAnimationsObj.init();
      if (!_browserObj.isMobile) {
        douroParallaxElements.init();
      }
    } else {
      if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
        setTimeout(function () {
          if(!_browserObj.isSafari){
            illustrationDouroObj.init();
          } else {
            TweenMax.to($headerDouroMobile, 2, {opacity: 1, scale: 1, ease: Expo.easeOut});
          }
          _buroScroll.update();
        }, 1000)
        _buroScroll.unblockBuroScroll();
        _buroScroll.update();
      } else {
        disableBodyScroll(false);
      }
      douroScrollAnimationsObj.init();
      if (!_browserObj.isMobile) {
        douroParallaxElements.init();
        _buroScroll.update();
      }
    }

    $(".header-link").eq(1).addClass("active");
    $("nav .main-links li").removeClass("disable");
    $("nav .main-links li").eq(1).addClass("disable");

    //Events
    initEvents();
  }

  var kill = function () {
    //Kill Events

    cancelAnimationFrame(_raf_loop_id);
    douroParallaxElements.kill();
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
      _buroScroll.kill();
      setTimeout(function () {
        if(!_browserObj.isSafari){
          illustrationDouroObj.kill();
        }
      }, 1000)
    } else {
      $_window[0].removeEventListener("scroll", _scrollRef);
      _scrollRef = null;
    }
  }

  function illustrationDouroControls() {
    this.init = function () {
      illustrationDouro.playSegments([0, 60], true);
      illustrationTruck.playSegments([0, 60], true);
      TweenMax.to($truck, 20, {
        x: 600,
        ease: Linear.easeNone
      });
      illustrationDouro.addEventListener("complete", function () {
        illustrationDouro.loop = true;
        illustrationTruck.loop = true;
        illustrationDouro.playSegments([61, 914], true);
        illustrationTruck.playSegments([61, 914], true);
      })
    }

    this.controls = function (scroll) {
      (scroll > illustrationDouroHeight) ? illustrationDouro.pause(): illustrationDouro.play();
    }

    this.kill = function () {
      illustrationDouro.destroy();
    }
  }

  function douroScrollAnimations() {
    var $jsBigTitle = $(".js-big-title"),
      $jsSubtitle = $(".js-subtitle"),
      $mainPerspective = $(".main-perspective"),
      $calemButton = $(".calem-button"),
      offset = 100,
      positionsBigTitles = [],
      positionsSubtitles = [],
      positionsText = [],
      positionsButtons = [],
      positionsImgs = [];

    this.init = function () {

      $(".regions-terroir").find("p").each(function () {
        var $this = $(this);

        $(this).attr("data-html", $(this).html());
        $this.addClass("js-text");
      });

      $(".caves").find("p").each(function () {
        var $this = $(this);

        $this.addClass("js-text");
      });

      $jsBigTitle.each(function () {
        var $this = $(this),
        offset,
        splittedBigTitle = null,
        objBigTitle = {};

        splittedBigTitle = new SplitText($this, {type: "chars"});
        $(splittedBigTitle.chars).addClass("big-title-chars");
        if(!_browserObj.isSafari){
          TweenMax.set($(splittedBigTitle.chars), {rotationX: 100, opacity: 0});
        } else {
          if(_browserObj.isMobile){
            TweenMax.set($(splittedBigTitle.chars), {rotationX: 100, opacity: 0});
          }
        }

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objBigTitle.elem = $this;
        objBigTitle.offset = $this.offset().top + offset;
        objBigTitle.active = false;

        positionsBigTitles.push(objBigTitle);
      });

      $jsSubtitle.each(function (key) {
        var $this = $(this),
        offset,
        splittedSubTitle = null,
        objSubtitle = {};

        splittedSubTitle = new SplitText($this, {
          type: "lines"
        });
        $(splittedSubTitle.lines).addClass("subtitle-lines");
        TweenMax.set($this.find(".subtitle-lines"), {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objSubtitle.elem = $this;
        objSubtitle.offset = $this.offset().top + offset;
        objSubtitle.active = false;

        positionsSubtitles.push(objSubtitle);
      });

      $(".js-text").each(function () {
        var $this = $(this),
        offset,
        objText = {};

        TweenMax.set($this, {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objText.elem = $this;
        objText.offset = $this.offset().top + offset;
        objText.active = false;

        positionsText.push(objText);
      });

      $calemButton.each(function () {
        var $this = $(this),
        offset,
        objBtn = {};

        if(!$this.hasClass("intro-button")){
          TweenMax.set($this, {
            y: 50
          });
        }

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 50;
        }

        objBtn.elem = $this;
        objBtn.offset = $this.offset().top + offset;
        objBtn.active = false;

        positionsButtons.push(objBtn);
      });

      $mainPerspective.find("figure").each(function () {
        var $this = $(this),
          offsetImg,
          objImg = {};

        if($this.attr("data-offset") != undefined){
          offsetImg = Number($this.attr("data-offset"));
        } else {
          offsetImg = 300;
        }

        objImg.elem = $this;
        objImg.offset = $this.offset().top + offsetImg;
        objImg.active = false;

        positionsImgs.push(objImg);
      });
    }

    this.inViewport = function (scroll) {
      for (var i = 0; i <= positionsBigTitles.length - 1; i++) {
        if (scroll + _globalViewportH > positionsBigTitles[i].offset && positionsBigTitles[i].active === false) {
          TweenMax.staggerTo($(positionsBigTitles[i].elem).find(".big-title-chars"), 1, {
            opacity: 1,
            rotationX: 0,
            ease: Circ.easeOut
          }, 0.05);
          if(i === positionsBigTitles.length-1){
            $(positionsBigTitles[positionsBigTitles.length-1].elem).find("sup").addClass("show");
          }
          positionsBigTitles[i].active = true;
        }
        if (positionsBigTitles[positionsBigTitles.length - 1].active === true)
          break;
      }

      for (var x = 0; x <= positionsSubtitles.length - 1; x++) {
        if (scroll + _globalViewportH > positionsSubtitles[x].offset && positionsSubtitles[x].active === false) {
          TweenMax.staggerTo($(positionsSubtitles[x].elem.find(".subtitle-lines")), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          }, 0.1);
          positionsSubtitles[x].active = true;
        }
        if (positionsSubtitles[positionsSubtitles.length - 1].active === true)
          break;
      }

      for (var j = 0; j <= positionsText.length - 1; j++) {
        if (scroll + _globalViewportH > positionsText[j].offset && positionsText[j].active === false) {
          var letter = j;
          positionsText[j].active = true;
          TweenMax.to($(positionsText[j].elem), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          });
        }
        if (positionsText[positionsText.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsButtons.length - 1; i++) {
        if (scroll + _globalViewportH > positionsButtons[i].offset && positionsButtons[i].active === false) {
          TweenMax.to($(positionsButtons[i].elem), 1, {
            autoAlpha: 1,
            y: 0,
            ease: Circ.easeOut
          })
          positionsButtons[i].active = true;
        }
        if (positionsButtons[positionsButtons.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsImgs.length - 1; i++) {
        if (scroll + _globalViewportH > positionsImgs[i].offset && positionsImgs[i].active === false) {
          TweenMax.to($(positionsImgs[i].elem), 1.4, {
            x: 0,
            y: 0,
            rotation: 0,
            opacity: 1,
            ease: Expo.easeOut
          });
          positionsImgs[i].active = true;
        }
        if (positionsImgs[positionsImgs.length - 1].active === true)
          break;
      }
    }

    this.kill = function () {

    }
  }

  /*page functions*/
  function initEvents() {}

  function douroPage_scroll_rAF(status) {
    if (!_buroScroll) {
      var status = {
        scrollVal: window.pageYOffset
      }

      if (lastScroll - status.scrollVal < 0) {
        status.direction = 'down';
      } else if (lastScroll - status.scrollVal > 0) {
        status.direction = 'up';
      }

      lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
    }

    _globalHeader.animate(status.scrollVal, status.direction);
    douroScrollAnimationsObj.inViewport(status.scrollVal);
    globalCtaCavesObj.animate(status.scrollVal, $(".page-current").height(), status.direction);
    if (!_browserObj.isMobile) {
      if(!_browserObj.isSafari){
        illustrationDouroObj.controls(status.scrollVal);
      }
      douroParallaxElements.update(status.scrollVal);
    }

  }

  return {
    init: init,
    kill: kill
  }
}

///BARBA INITIALIZER///
var douroPage = Barba.BaseView.extend({
  namespace: 'douro',
  onEnter: function () {

  },
  onEnterCompleted: function () {
    // The Transition has just finished.
    _currentView = douroPageFunctions();
    _currentView.init();
  },
  onLeave: function () {
    // A new Transition toward a new page has just started.
    _currentView.kill();
  },
  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
  }
});


douroPage.init();