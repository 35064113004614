function portfolioArchivePageFunctions() {

  if ($_scrollContentWrapper.attr("data-buroScrollbar") != undefined && !_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
    _buroScroll = new buroVirtualScroll("body", ".page-current", .4, true);
    _buroScroll.init();

    _page_scroll_listener = function (status) {
      portfolioArchivePage_scroll_rAF(status);
    };
    _buroScroll.addListener(_page_scroll_listener)
  } else {
    _scrollRef = function () {
      _raf_loop_id = _rAF_loop(portfolioArchivePage_scroll_rAF);
    }
    $_window[0].addEventListener("scroll", _scrollRef, {
      passive: true
    });
  }

  /*DOM variables*/
  var $bottleWrapper = $(".bottle-wrapper"),
    $bottleInfo = $(".bottle-info"),
    $preserveIcon = $(".preserve-icon"),
    $curiosityContainer = $(".curiosity-container"),
    $bottleImg = $(".bottle-wrapper img"),
    $headerTitles = $(".header-titles"),
    $labelShare = $(".label-share"),
    $shareButton = $(".share-button"),
    $headerTitlesContainer = $(".header-titles-container"),
    $nextCategoryBottle = $(".next-category .bottle"),
    $nextCategoryBase = $(".next-category .base"),
    $nextCategory = $(".next-category"),
    $serveFresh = null,
    arrayTitlesColors = [
      {active:false},
      {active:false},
      {active:false},
      {active:false}
    ];

  if ($(".suggestion").length != 0) {
    var $target = $(".suggestion").attr("data-target"),
      targetOffsetTop = 0,
      targetHeight = 0,
      $suggestion = $(".suggestion"),
      suggestionHeight = $suggestion.outerHeight();
  }

  /*Variables*/
  var lastScroll = 0,
    pageHeaderHeight = $(".page-header").height(),
    pageContentOffset = $(".page-content").offset().top,
    suggestionObj = new suggestion(),
    yearsBottleObj = new yearsBottle(),
    calemAwardsObj = new calemAwards(),
    linesPageObj = new linesPage(),
    portfolioScrollAnimationsObj = new portfolioScrollAnimations(),
    headerTitlesObj = new headerTitles(),
    $nextCategoryOffsetTop = 0,
    urlBottlesObj = new urlBottles(),
    portfolioParallaxElements = buroParallaxScroll(),
    pageName = $(".portfolio-archive-page").attr("data-category"),
    path = window.location.pathname,
    mouseX = 0,
    mouseY = 0,
    lastDirectory = path.replace(/\/$/, "").split('/').splice(-1, 1);

  var init = function () {

    /*inits*/
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
      _buroScroll.blockBuroScroll();
    else
      disableBodyScroll(true);

    if ($_body.hasClass("js-no-ajax")) {
      portfolioSingleEntranceObj.init();

      $(".preload-image").imagesLoaded(function ($images, $proper, $broken) {
        var fPreload = $(this).imagesLoaded();
        fPreload.always(function () {
          _introObj.init(function () {
            yearsBottleObj.init();
            calemAwardsObj.init();
            headerTitlesObj.init();
            headerTitlesObj.getIndex();
            $nextCategoryOffsetTop = $nextCategory.offset().top;
            setTimeout(function(){
              linesPageObj.init(pageName, function () {
                portfolioScrollAnimationsObj.init();
                setTimeout(function () {
                  urlBottlesObj.init();
                }, 2000)
              });
            }, 1000)

            if ($(".suggestion").length != 0) {
              targetOffsetTop = $("." + $target + "").offset().top;
              targetHeight = $("." + $target + "").height();
              suggestionObj.init();
            }

            if (cookie.get('cookie-age') != undefined) {
              $.doTimeout(700, function () {
                portfolioSingleEntranceObj.animateIn(function () {
                  if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                    _buroScroll.unblockBuroScroll();
                    _buroScroll.update();
                    portfolioScrollAnimationsObj.inViewport(_globalViewportH);
                    linesPageObj.animateIn();
                    TweenMax.set(".entry-point .label", {
                      opacity: 1
                    });
                  } else {
                    if($_window.width() <= 768 && _browserObj.isMobile){
                      portfolioScrollAnimationsObj.inViewport(_globalViewportH);
                    }    
                    disableBodyScroll(false);
                  }
                });
              });
            } else {
              if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                _buroScroll.unblockBuroScroll();
                _buroScroll.update();
                $.doTimeout(1000, function () {
                  _buroScroll.update();
                });
              } else {
                disableBodyScroll(false);
                if($_window.width() <= 768 && _browserObj.isMobile){
                  portfolioScrollAnimationsObj.inViewport(_globalViewportH);
                }
              }
            }
          });
        });
      });
    } else {
      yearsBottleObj.init();
      calemAwardsObj.init();
      headerTitlesObj.getIndex();
      headerTitlesObj.init();
      urlBottlesObj.init();
      setTimeout(function(){
        linesPageObj.init(pageName, function () {
          portfolioScrollAnimationsObj.init();
          portfolioScrollAnimationsObj.inViewport(_globalViewportH);
          linesPageObj.animateIn();
          $nextCategoryOffsetTop = $nextCategory.offset().top;
          TweenMax.set(".entry-point .label", {
            opacity: 1
          });
        });
      }, 1000);

      if ($(".suggestion").length != 0) {
        targetOffsetTop = $("." + $target + "").offset().top;
        targetHeight = $("." + $target + "").height();
        suggestionObj.init();
      }

      if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
        setTimeout(function(){
          _buroScroll.unblockBuroScroll();
          _buroScroll.update();
        }, 1500)
      } else {
        disableBodyScroll(false);
      }
    }

    if ($(".serve-fresh").length != 0) {
      $serveFresh = $(".serve-fresh");
      serveFreshOffset = $serveFresh.offset().top;
    }

    if (!_browserObj.isMobile)
      portfolioParallaxElements.init();

    //Events
    initEvents();
  }

  var kill = function () {
    //Kill Events

    $_window.off("resize.linesRose");
    yearsBottleObj.kill();
    calemAwardsObj.kill();
    linesPageObj.kill();
    urlBottlesObj.kill();
    headerTitlesObj.kill();
    portfolioScrollAnimationsObj.kill();

    if (!_browserObj.isMobile)
      portfolioParallaxElements.kill();

    cancelAnimationFrame(_raf_loop_id);
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
      _buroScroll.kill();
    } else {
      $_window[0].removeEventListener("scroll", _scrollRef);
      _scrollRef = null;
    }
  }

  /*page functions*/
  $labelShare.on("mouseenter", function () {
    var $this = $(this);
    $labelShare.addClass("active");
    TweenMax.staggerTo($this.parent().find("li"), 0.8, {
      autoAlpha: 1,
      ease: Expo.easeOut
    }, 0.05)
    window.Sharer.init();
  })

  $shareButton.on("mouseleave", function () {
    var $this = $(this);
    $labelShare.removeClass("active");
    TweenMax.staggerTo($this.parent().find("li"), 0.8, {
      autoAlpha: 0,
      ease: Expo.easeOut
    }, 0.05)
  })


  function initEvents() {
    if ($suggestion)
      TweenMax.set($suggestion, {
        y: _globalViewportH - suggestionHeight - 50
      });

    $(".next-category .hit-zone").css({
      "width": $(".next-category .next-bottle-wrapper .bottle").outerWidth() / 2.5,
      "height": $(".next-category .next-bottle-wrapper .bottle").outerHeight()
    });
    $(".next-category .buttons-container").css("width", $(".next-category .next-bottle-wrapper .bottle").outerWidth());
    $("#header-main").removeClass("whiteCategory, tawnyCategory, rubyCategory, roseCategory");
    switch ($(".portfolio-archive-page").attr("data-category")) {
      case 'white':
        $("#header-main").addClass("whiteCategory");
        arrayTitlesColors[0].active = true;
        break;
      case 'tawny':
        $("#header-main").addClass("tawnyCategory");
        arrayTitlesColors[1].active = true;
        break;
      case 'ruby':
        $("#header-main").addClass("rubyCategory");
        arrayTitlesColors[2].active = true;
        break;
      case 'rose':
        $("#header-main").addClass("roseCategory");
        arrayTitlesColors[3].active = true;
        break;
    }

    $nextCategory.find("a").on("click", function () {
      switch ($(".portfolio-archive-page").attr("data-category")) {
        case "white":
          _titleActiveIndex = 1;
          break;
        case "tawny":
          _titleActiveIndex = 2;
          break;
        case "ruby":
          _titleActiveIndex = 3;
          break;
        case "rose":
          _titleActiveIndex = 0;
          break;
      }
      for (var index = _titleActiveIndex; index >= 0; index--) {
        arrayTitlesColors[index].active = true;
      }
    });
  }

  function urlBottles() {
    var $bottles = $(".bottle-wrapper"),
      arrayBottles = [],
      fullUrl = $(".header-titles").find("li.active a").attr("href");

    this.init = function () {
      var lastWord = window.location.pathname.split('/');
      if ($(".bottle-wrapper." + lastWord[lastWord.length - 2] + "").length != 0 && lastWord[lastWord.length - 2] != "rose") {
        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
          _buroScroll.scrollTo(-$(".bottle-wrapper." + lastWord[lastWord.length - 2] + "")[0].getBoundingClientRect().top + _globalViewportH / 4, 2, null);
        } else {
          $('html, body').animate({
            scrollTop: $(".bottle-wrapper." + lastWord[lastWord.length - 2] + "")[0].getBoundingClientRect().top
          }, 2000);
        }
      } else if ($(".bottle-wrapper." + lastWord[lastWord.length - 2] + "").length != 0 && lastWord[lastWord.length - 2] == "rose" && lastWord[lastWord.length - 3] == "rose" && $_body.hasClass("js-no-ajax")) {
        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
          _buroScroll.scrollTo(-$(".bottle-wrapper." + lastWord[lastWord.length - 2] + "")[0].getBoundingClientRect().top + _globalViewportH / 4, 2, null);
        } else {
          $('html, body').animate({
            scrollTop: $(".bottle-wrapper." + lastWord[lastWord.length - 2] + "")[0].getBoundingClientRect().top
          }, 2000);
        }
      }

      $bottles.each(function () {
        var $this = $(this),
          slug = $this.attr("data-slug"),
          offset = $this[0].getBoundingClientRect().top,
          obj = {};

        obj.elem = $this;
        obj.slug = slug;
        obj.offsetTop = offset;
        obj.height = $this.outerHeight();
        obj.active = false;

        arrayBottles.push(obj);
      });
    }

    this.inViewport = function (scroll, direction) {
      for (var index = 0; index < arrayBottles.length; index++) {
        if (scroll + _globalViewportH > arrayBottles[index].offsetTop && arrayBottles[index].active == false && direction == 'down') {
          arrayBottles[index].active = true;
          history.pushState({}, {}, fullUrl + arrayBottles[index].slug);
        } else if (scroll < arrayBottles[index].offsetTop && arrayBottles[index].active == true && direction == 'up') {
          arrayBottles[index].active = false;
          history.pushState({}, {}, fullUrl + arrayBottles[index].slug);
        } else if (scroll < _globalViewportH / 2 && direction == 'up') {
          history.pushState({}, {}, fullUrl);
        }
      }
    }

    this.kill = function () {
      arrayBottles = [];
      fullUrl = null;
    }
  }

  function headerTitles() {
    var value = Number($headerTitles.attr("data-value")),
      titleWidth = 0,
      offsetLateral = 0,
      maxWidth = 0,
      titlesArray = [],
      headerDrag = null,
      raf_cursor = null,
      $dragWord = $(".drag-word"),
      $customCursor = $(".custom-cursor");

    this.init = function () {

      var mainCategory = '';
      if ($(".bottle-wrapper." + lastDirectory[0] + "").length != 0 && lastDirectory[0] != "rose") {
        mainCategory = path.replace(/\/$/, "").split('/').splice(-2, 1).toString();
      } else {
        mainCategory = lastDirectory[0];
      }

       // if (currentSlide > 0) {
          //   TweenMax.to($customCursor.find(".arrow").filter(".right"), 0.2, {
          //       opacity: 1
          //   });
          // } else if (currentSlide <= 0) {
          //   TweenMax.to($customCursor.find(".arrow").filter(".right"), 0.2, {
          //       opacity: 0.3
          //   });
          // }

          // if (currentSlide >= snapPoints.length - 1) {
          //   TweenMax.to($customCursor.find(".arrow").filter(".left"), 0.2, {
          //       opacity: 0.3
          //   });
          // } else {
          //   TweenMax.to($customCursor.find(".arrow").filter(".left"), 0.2, {
          //       opacity: 1
          //   });
          // }


      switch (mainCategory) {
        case "white":
          _titleActiveIndex = 0;
          TweenMax.set($customCursor.find(".arrow").filter(".right"), {
            opacity: 0.3
          });
          break;

        case "tawny":
          _titleActiveIndex = 1;
          break;

        case "ruby":
          _titleActiveIndex = 2;
          break;

        case "rose":
          _titleActiveIndex = 3;
          TweenMax.set($customCursor.find(".arrow").filter(".left"), {
            opacity: 0.3
          });
          break;

        default:
          break;
      }

      for (var index = _titleActiveIndex; index >= 0; index--) {
        arrayTitlesColors[index].active = true;
      }

      if ($_body.hasClass("js-no-ajax")) {
        $headerTitles.find("li").each(function (key) {
          var $this = $(this);
          $this.css({
            "width": _globalViewportH * 0.62,
            "margin-right": "100px"
          });
        });

        $headerTitles.css("left", (_globalViewportW - _globalViewportH * 0.62) / 2);
      }

      var snapPoints = [];
      var currentSlide;
      for (var i = 0; i < $headerTitles.find("li").length; i++) {
        snapPoints.push(-(_globalViewportH * 0.62 + 100) * i);
      }

      var color = $headerTitles.find("li.active").attr("data-color");
      $(".custom-cursor .custom-cursor-wrapper .drag-word, .custom-cursor .arrow").removeClass("white");
      $(".custom-cursor .custom-cursor-wrapper .drag-word, .custom-cursor .arrow").removeClass("tawny");
      $(".custom-cursor .custom-cursor-wrapper .drag-word, .custom-cursor .arrow").removeClass("ruby");
      $(".custom-cursor .custom-cursor-wrapper .drag-word, .custom-cursor .arrow").removeClass("rose");
      $(".custom-cursor .custom-cursor-wrapper .drag-word, .custom-cursor .arrow").addClass(color);

      Draggable.create($headerTitles, {
        type: "x",
        throwProps: true,
        minDuration: 1.5,
        maxDuration: 2,
        bounds: {
          minX: 0,
          minY: 0,
          maxX: -$headerTitles.outerWidth(),
          maxY: 0
        },
        onThrowUpdate: function () {
          currentSlide = Math.floor(Math.abs(document.querySelector(".header-titles")._gsTransform.x / $headerTitles.find("li").outerWidth()));
          dragAmount = scaleBetween(Math.abs(document.querySelector(".header-titles")._gsTransform.x), 0, snapPoints[2], 0, _globalViewportW);
          for (var index = 0; index < 4; index++) {
            if(dragAmount - $(".next-bottle-wrapper .bottle").outerWidth() < snapPoints[index] && !arrayTitlesColors[index].active){
              arrayTitlesColors[index].active = true;
              $headerTitles.find("li").removeClass("active");
              $headerTitles.find("li").eq(index).addClass("active");
            } else if(dragAmount - $(".next-bottle-wrapper .bottle").outerWidth() < snapPoints[index] && arrayTitlesColors[index].active){
              arrayTitlesColors[index].active = false;
              $headerTitles.find("li").removeClass("active");
              $headerTitles.find("li").eq(index).addClass("active");
            }                    
          }
        },
        onThrowComplete: function () {
          // $headerTitles.find("li").removeClass("active");
          // $headerTitles.find("li").eq(currentSlide).addClass("active");
          var color = $headerTitles.find("li.active").attr("data-color");
          _titleActiveIndex = currentSlide;
          _linkedClicked = true;

          TweenMax.to($customCursor, 0.2, {
            opacity: 0
          });

          Barba.Pjax.getTransition = function () {
            return categoryChangeTransition;
          };
          Barba.Pjax.goTo($headerTitles.find("li.active a").attr("href"));
        },
        onDrag: function(){
          dragAmount = scaleBetween(Math.abs(document.querySelector(".header-titles")._gsTransform.x), 0, snapPoints[2], 0, _globalViewportW);
          for (var index = 0; index < 4; index++) {
            if(dragAmount - $(".next-bottle-wrapper .bottle").outerWidth() < snapPoints[index] && !arrayTitlesColors[index].active){
              arrayTitlesColors[index].active = true;
              $headerTitles.find("li").removeClass("active");
              $headerTitles.find("li").eq(index).addClass("active");
            } else if(dragAmount - $(".next-bottle-wrapper .bottle").outerWidth() < snapPoints[index] && arrayTitlesColors[index].active){
              arrayTitlesColors[index].active = false;
              $headerTitles.find("li").removeClass("active");
              $headerTitles.find("li").eq(index).addClass("active");
            }                    
          }
        },
        onDragEnd: function () {
          this.applyBounds({
            minX: 0,
            minY: 0,
            maxX: -$headerTitles.outerWidth(),
            maxY: 0
          });
        },
        snap: snapPoints
      });

      headerDrag = Draggable.get($headerTitles);


      if (!_browserObj.isMobile) {

        $headerTitles.hover(function () {
          TweenMax.to($customCursor, 0.2, {
            opacity: 1
          });
        }, function () {
          TweenMax.to($customCursor, 0.2, {
            opacity: 0
          });
        });

        cursorLoop();
        $_window.on("mousemove.headerTitles", function (e) {
          mouseX = e.clientX - $customCursor.outerWidth() / 2;
          mouseY = e.clientY - $customCursor.outerHeight() / 2;
          _portfolioMouseX = mouseX;
          _portfolioMouseY = mouseY;
        });


        function cursorLoop() {
          TweenMax.to($dragWord, 1, {
            rotation: "+=20",
            repeat: -1,
            ease: Linear.easeNone
          });
          TweenMax.to($customCursor, .6, {
            x: _portfolioMouseX,
            y: _portfolioMouseY,
            ease: Circ.easeOut
          });

          raf_cursor = _rAF_loop(cursorLoop);
        }
        $_window.on("resize.headerTitles", $.debounce(500, resizeHeaderTitles));
      }
    }

    this.getIndex = function () {
      headerDrag = Draggable.get($headerTitles);
      $headerTitles.find("li a").on("click", function (e) {
        // var $this = $(this);

        // $headerTitles.find("li").removeClass("active");
        // $this.addClass("active");
        // _titleActiveIndex = $headerTitles.find("li").index($(this));
        // headerDrag.update();
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      });
    }

    this.kill = function () {
      if (!_browserObj.isMobile) {
        $_window.off("resize.headerTitles");
        $_window.off("mousemove.headerTitles");
      }
      headerDrag.kill();
    }

    function resizeHeaderTitles() {
      offsetLateral = 0;
      maxWidth = 0;
      titlesArray = [];
      TweenMax.set([$headerTitles, $headerTitles.find("li")], {
        clearProps: "all"
      });
      TweenMax.set($headerTitles.find("li"), {
        opacity: 1
      });

      if (_browserObj.isMobile) {
        offsetLateral = 70;
      } else {
        offsetLateral = 200;
      }

      $headerTitles.find("li").each(function (key) {
        var $this = $(this),
          elWidth = $this.find("a").width();

        if (elWidth > maxWidth) {
          maxWidth = elWidth;
        }
      });

      TweenMax.set($headerTitles.find("li"), {
        width: maxWidth + offsetLateral
      });
      for (var index = 0; index < $headerTitles.find("li").length; index++) {
        titlesArray.push($headerTitles.find("li").eq(index).offset().left);
      }
      TweenMax.set($headerTitles, {
        left: (_globalViewportW - (maxWidth + offsetLateral)) / 2
      })
      TweenMax.set($headerTitles, {
        x: -titlesArray[value]
      })
    }
  }

  function calemAwards() {
    var canClick = true,
      awardWidth = $(".calem-awards").find("li").eq(0).outerWidth(),
      totalClick = 0,
      totalAwards = 0,
      lastClick = " ";

    var $awardsButtons = $(".awards-buttons");

    this.init = function () {
      $bottleInfo.each(function () {
        var $this = $(this);

        if ($this.find(".years-wrapper").length != 0) {
          if ($_window.width() < 375) {
            TweenMax.set($this.find(".years-wrapper"), {
              x: $this.find(".years-container h4").width() + 5
            })
          } else {
            TweenMax.set($this.find(".years-wrapper"), {
              x: $this.find(".years-container h4").width() + 30
            })
          }
        }

        $this.find(".calem-awards").each(function () {
          var qty = $(this).find("li").length;

          if ($(this).find("li").length <= 5) {
            $this.find(".calem-awards").parent().parent().find("awards-buttons").css({
              "opacity": "0",
              "visibility": "hidden"
            });
          }

          if ($_window.width() > 768) {
            if (qty > 5 && $(this).hasClass("align-right") && !$(this).parent().hasClass("white-and-dry")) {
              TweenMax.set($(this).closest(".calem-awards-container"), {
                x: -$(this).find("li").eq(0).outerWidth() - $(this).find("li").eq(0).outerWidth() / 2
              });
            } else if (qty === 5 && $(this).hasClass("align-right")) {
              TweenMax.set($(this), {
                x: -$(this).find("li").eq(0).outerWidth() - $(this).find("li").eq(0).outerWidth() / 2
              });
            }

            if (qty > 5 && $(this).hasClass("align-right") && $(this).parent().hasClass("vintage") && _browserObj.isMobile && !_browserObj.isPhone) {
              TweenMax.set($(this).closest(".calem-awards-container"), {
                x: 0
              });
            }
          }

          if (qty > 5 && $_window.width() > 768) {
            $(this).css("position", "relative");
            $(this).parent().css("max-width", $(this).find("li").eq(0).outerWidth() * (parseInt($(this).attr("data-maxwidth"))));
          } else if (qty > 5 && $_window.width() <= 768 && !_browserObj.isPhone) {
            $(this).css("position", "relative");
            $(this).parent().css("max-width", $(this).find("li").eq(0).outerWidth() * (parseInt($(this).attr("data-maxwidth-mobile"))));
          } else if (qty > 5 && $_window.width() > 768 && _browserObj.isIE) {
            $(this).css("position", "relative");
            $(this).parent().css("max-width", 550);
          }
        });
      });

      if (_browserObj.isPhone) {
        $(".calem-awards-section").each(function () {
          if ($(this).find("ul li").length > 2) {
            var draggerHelperMobile = bodymovin.loadAnimation({
              container: $(this).find(".dragger-helper-mobile")[0],
              renderer: 'svg',
              loop: true,
              autoplay: true,
              path: '/public/bodymovin/dragger-mobile.json'
            });
          }
        });
        // $(".calem-awards-section").each(function () {
        //   var $this = $(this),
        //     maxHeight = 0;

        //   $this.find(".calem-awards").each(function () {
        //     if ($(this).outerHeight() > maxHeight) {
        //       maxHeight = $(this).outerHeight();
        //     }
        //   });

        //   $this.css("height", maxHeight);
        // });
      }

      $awardsButtons.on("click", function () {
        var $this = $(this);
        totalAwards = $this.closest(".bottle-info").find(".calem-awards-container.active .calem-awards li").length;

        if ("" + $this.attr("data-slug") + "" != "" + lastClick + "") {
          lastClick = $this.attr("data-slug");
          totalClick = parseInt($this.attr("data-click"));
        }

        if (count === 0) {
          totalClick = count;
          count = 1;
        }

        if ($this.hasClass("next-awards")) {
          if (canClick) {
            moveAwards("right", $this);
            canClick = false;
          }
        } else {
          if (canClick) {
            moveAwards("left", $this);
            canClick = false;
          }
        }

        $this.closest(".bottle-info").find(".calem-awards-container.active .awards-buttons").attr("data-click", totalClick);
      });

      if (!_browserObj.isMobile)
        $_window.on("resize.calemAwards", $.debounce(500, resizeCalemAwards));
    }

    function moveAwards(direction, elem) {
      if (direction === "right") {
        totalClick++;
        TweenMax.to($(elem).closest(".bottle-info").find(".calem-awards-container.active .calem-awards"), 1, {
          x: "-=" + awardWidth,
          ease: Circ.easeOut,
          onComplete: function () {
            canClick = true;
          }
        });
      }
      if (direction === "left") {
        totalClick--;
        TweenMax.to($(elem).closest(".bottle-info").find(".calem-awards-container.active .calem-awards"), 1, {
          x: "+=" + awardWidth,
          ease: Circ.easeOut,
          onComplete: function () {
            canClick = true;
          }
        });
      }
      if (totalClick > 0) {
        TweenMax.to($(elem).closest(".bottle-info").find(".calem-awards-container.active .prev-awards"), 0.6, {
          autoAlpha: 1,
          ease: Circ.easeOut
        });
      } else if (totalClick <= 0) {
        TweenMax.to($(elem).closest(".bottle-info").find(".calem-awards-container.active .prev-awards"), 0.6, {
          autoAlpha: 0,
          ease: Circ.easeOut
        });
      }

      if (totalClick >= totalAwards - parseInt($(elem).closest(".bottle-info").find(".calem-awards-container.active .calem-awards").attr("data-maxwidth"))) {
        TweenMax.to($(elem).closest(".bottle-info").find(".calem-awards-container.active .next-awards"), 0.6, {
          autoAlpha: 0,
          className: "+=disable",
          ease: Circ.easeOut
        });
      } else {
        if ($(elem).closest(".bottle-info").find(".calem-awards-container.active .next-awards").hasClass("disable")) {
          TweenMax.to($(elem).closest(".bottle-info").find(".calem-awards-container.active .next-awards"), 0.6, {
            autoAlpha: 1,
            className: "-=disable",
            ease: Circ.easeOut
          });
        }
      }
    }

    function resizeCalemAwards() {
      TweenMax.set($(".calem-awards.align-right"), {
        clearProps: "all"
      });
      $bottleInfo.each(function () {
        var $this = $(this);

        $this.find(".calem-awards").each(function () {
          var qty = $(this).find("li").length;
          if ($_window.width() > 768) {
            if (qty >= 5 && $(this).hasClass("align-right")) {
              TweenMax.set($(this).closest(".calem-awards-container"), {
                x: -$(this).find("li").eq(0).outerWidth() - $(this).find("li").eq(0).outerWidth() / 2
              });
            }
          }
        });
      });
    }

    this.kill = function () {
      $_window.off("resize.calemAwards");
    }
  }

  function suggestion() {
    this.init = function () {
      $suggestion.on("click", function () {
        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
          _buroScroll.scrollTo(-(targetOffsetTop - (_globalViewportH - targetHeight) / 2), 1, null);
        } else {
          $('html, body').animate({
            scrollTop: targetOffsetTop - (_globalViewportH - targetHeight) / 2
          }, 600);
        }
      });

      if (!_browserObj.isMobile) {
        $_window.on("resize.suggestion", $.debounce(500, resizeSuggestion));
      }

    }

    this.animate = function (scroll) {
      if (!_browserObj.isMobile)
        if (!_browserObj.isEdge && !_browserObj.isIE)
          TweenMax.to($suggestion, .4, {
            y: _globalViewportH + scroll - suggestionHeight - 50,
            roundProps: "y",
            force3D: true,
            ease: Power4.easeOut
          });

      // if (scroll + _globalViewportH / 2 > targetOffsetTop && !$suggestion.hasClass("hidden")) {
      //   $suggestion.addClass("hidden");
      //   TweenMax.to($suggestion, 0.6, {
      //     opacity: 0
      //   });
      // }
      if(scroll > _globalViewportH /2 && !$suggestion.hasClass("hidden")){
        TweenMax.to($suggestion, 0.6, {
          opacity: 0
        });
      }
    }

    function resizeSuggestion() {
      if ($(".suggestion").length != 0) {
        var $target = $(".suggestion").attr("data-target"),
          targetOffsetTop = $("." + $target + "").offset().top,
          $suggestion = $(".suggestion"),
          suggestionHeight = $suggestion.outerHeight();
      }
    }

    this.kill = function () {
      $_window.off("resize.suggestion");
    }
  }

  function portfolioScrollAnimations() {
    var $jsBigTitle = $(".js-big-title"),
      $jsSubtitle = $(".js-subtitle"),
      $jsText = $(".js-text"),
      $mainPerspective = $(".main-perspective"),
      offset = 0,
      positionsYears = [],
      positionsBigTitles = [],
      positionsSubtitles = [],
      positionsText = [],
      positionsImgs = [],
      positionsShare = [],
      positionsTechnical = [],
      positionsAwards = [];

    this.init = function () {
      if (_browserObj.isMobile) {
        offset = 100;
      } else {
        offset = 300;
      }

      $curiosityContainer.find("h3").addClass("js-subtitle").attr("data-html", $curiosityContainer.find("h3").html());
      $curiosityContainer.find("p").each(function () {
        $(this).addClass("js-text");
      });

      $(".calem-awards-container").each(function () {
        var $this = $(this);
        TweenMax.set($this, {
          autoAlpha: 0
        });
      });

      $bottleWrapper.each(function () {
        if ($(this).find(".bottles-images-container").length === 0) {
          $(this).find("figure").addClass("perspective-center");
          $(this).find(".bottle-info p").addClass("js-text");
        }
      });

      $(".js-text").each(function () {
        $(this).attr("data-html", $(this).html());
      });

      $jsBigTitle.each(function () {
        var $this = $(this),
          offset,
          splittedBigTitle = null,
          objBigTitle = {};

        splittedBigTitle = new SplitText($this, {
          type: "words, chars"
        });
        $(splittedBigTitle.chars).addClass("big-title-chars");
        TweenMax.set($(splittedBigTitle.chars), {
          rotationX: 100,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objBigTitle.elem = $this;
        objBigTitle.offset = $this.offset().top + offset;
        objBigTitle.active = false;

        positionsBigTitles.push(objBigTitle);
      });

      $(".js-subtitle").each(function () {
        var $this = $(this),
          offset,
          splittedSubTitle = null,
          objSubtitle = {};


        splittedSubTitle = new SplitText($this, {
          type: "lines"
        });
        $(splittedSubTitle.lines).addClass("subtitle-lines");

        TweenMax.set($this.find(".subtitle-lines"), {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objSubtitle.elem = $this;
        objSubtitle.offset = $this.offset().top + offset;
        objSubtitle.active = false;

        positionsSubtitles.push(objSubtitle);
      });

      $(".js-text").each(function () {
        var $this = $(this),
          offset,
          objText = {};

        TweenMax.set($this, {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objText.elem = $this;
        objText.offset = $this.offset().top + offset;
        objText.active = false;

        positionsText.push(objText);
      });

      $mainPerspective.find("[data-perspective]").each(function () {
        var $this = $(this),
          offset,
          objImg = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objImg.elem = $this;
        objImg.offset = $this.offset().top + offset;
        objImg.active = false;

        positionsImgs.push(objImg);
      });

      $(".years-container").each(function(){
        var $this = $(this),
            offset,
            objYears = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objYears.elem = $this;
        objYears.offset = $this.offset().top + offset;
        objYears.active = false;

        positionsYears.push(objYears);
      });

      $(".share-button").each(function(){
        var $this = $(this),
            offset,
            objShare = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 150;
        }

        objShare.elem = $this;
        objShare.offset = $this.offset().top + offset;
        objShare.active = false;

        positionsShare.push(objShare);
      });

      $(".technical-container").each(function(){
        var $this = $(this),
            offset,
            objTechnical = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 150;
        }

        objTechnical.elem = $this;
        objTechnical.offset = $this.offset().top + offset;
        objTechnical.active = false;

        positionsTechnical.push(objTechnical);
      });

      $(".calem-awards-container").each(function () {
        var $this = $(this),
          offset,
          objAwards = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objAwards.elem = $this;
        objAwards.offset = $this.offset().top + offset;
        objAwards.active = false;

        positionsAwards.push(objAwards);
      });
    }

    this.inViewport = function (scroll) {
      for (var k = 0; k <= positionsBigTitles.length - 1; k++) {
        if (scroll + _globalViewportH > positionsBigTitles[k].offset && positionsBigTitles[k].active === false) {
          var lett = k;
          positionsBigTitles[k].active = true;
          TweenMax.staggerTo($(positionsBigTitles[k].elem).find(".big-title-chars"), 1, {
            opacity: 1,
            rotationX: 0,
            ease: Circ.easeOut
          }, 0.05);
        }
        if (positionsBigTitles[positionsBigTitles.length - 1].active === true)
          break;
      }

      for (var x = 0; x <= positionsSubtitles.length - 1; x++) {
        if (scroll + _globalViewportH > positionsSubtitles[x].offset && positionsSubtitles[x].active === false) {
          positionsSubtitles[x].active = true;
          TweenMax.staggerTo($(positionsSubtitles[x].elem).find(".subtitle-lines"), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          }, 0.1);
        }
        if (positionsSubtitles[positionsSubtitles.length - 1].active === true)
          break;
      }

      for (var j = 0; j <= positionsText.length - 1; j++) {
        if (scroll + _globalViewportH > positionsText[j].offset && positionsText[j].active === false) {
          positionsText[j].active = true;
          TweenMax.to($(positionsText[j].elem), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          });
        }
        if (positionsText[positionsText.length - 1].active === true) {
          break;
        }
      }

      for (var i = 0; i <= positionsImgs.length - 1; i++) {
        if (scroll + _globalViewportH > positionsImgs[i].offset && positionsImgs[i].active === false) {
          TweenMax.to($(positionsImgs[i].elem), 1, {
            x: 0,
            y: 0,
            z: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,
            opacity: 1,
            ease: Expo.easeOut
          });
          positionsImgs[i].active = true;
        }
        // if(positionsImgs[positionsImgs.length-2].active === true)
        //   break;
      }

      for (var i = 0; i <= positionsYears.length - 1; i++) {
        if (scroll + _globalViewportH > positionsYears[i].offset && positionsYears[i].active === false) {
          TweenMax.to($(positionsYears[i].elem), 1, {
            autoAlpha: 1,
            y: 0,
            ease: Expo.easeOut
          });
          positionsYears[i].active = true;
        }
      }

      for (var i = 0; i <= positionsShare.length - 1; i++) {
        if (scroll + _globalViewportH > positionsShare[i].offset && positionsShare[i].active === false) {
          TweenMax.to($(positionsShare[i].elem), 1, {
            autoAlpha: 1,
            y: 0,
            ease: Expo.easeOut
          });
          positionsShare[i].active = true;
        }
      }

      for (var i = 0; i <= positionsTechnical.length - 1; i++) {
        if (scroll + _globalViewportH > positionsTechnical[i].offset && positionsTechnical[i].active === false) {
          TweenMax.to($(positionsTechnical[i].elem), 1, {
            autoAlpha: 1,
            y: 0,
            ease: Expo.easeOut
          });
          positionsTechnical[i].active = true;
        }
        // if(positionsAwards[positionsAwards.length-1].active === true)
        //   break;
      }

      for (var i = 0; i <= positionsAwards.length - 1; i++) {
        if (scroll + _globalViewportH > positionsAwards[i].offset && positionsAwards[i].active === false) {
          TweenMax.to($(positionsAwards[i].elem), 0.6, {
            autoAlpha: 1,
            ease: Expo.easeInOut
          });
          positionsAwards[i].active = true;
        }
        // if(positionsAwards[positionsAwards.length-1].active === true)
        //   break;
      }
    }

    this.kill = function () {
      positionsBigTitles = [];
      positionsSubtitles = [];
      positionsText = [];
      positionsImgs = [];
    }
  }

  function linesPage() {
    var $lineScroll = $(".line-scroll"),
      linesScrollArray = [];

    this.init = function (page, f) {

      $lineScroll.each(function () {
        var $this = $(this),
          obj = {};

        obj.elem = $this.find("path");
        obj.len = $this.find("path")[0].getTotalLength();
        obj.targetOffset = $("." + $this.attr("data-target") + "").offset().top;
        obj.targetHeight = $("." + $this.attr("data-target") + "").height();
        obj.offsetLine = Number($this.attr("data-line-offset"));
        obj.done = false;

        linesScrollArray.push(obj);
        TweenMax.set($this.find("path"), {
          strokeDashoffset: obj.len,
          strokeDasharray: obj.len
        })
      });

      if (page === 'rose') {
        var $lineRose = $(".line-rose");

        $lineRose.css({
          width: $preserveIcon.offset().left - $bottleImg.offset().left - $bottleImg.width() / 2 + $preserveIcon.width() / 2,
          left: -($preserveIcon.offset().left - $bottleImg.offset().left) + $bottleImg.width() / 2
        });

        f();

        if (!_browserObj.isMobile) {
          $_window.on("resize.linesRose", $.debounce(100, resizeLinesRose));
        }
      }

      if (page === 'ruby') {

        var $lineFineRuby = $(".line-fine-ruby"),
          $lineLBV = $(".line-lbv"),
          $lineVintage = $(".line-vintage"),
          $entryPoint = $(".entry-point");

        if (!_browserObj.isMobile) {
          $_window.on("resize.linesRuby", $.debounce(100, resizeLinesRuby));
        }

        $lineFineRuby.css({
          width: $entryPoint.offset().left - $(".fine-ruby .bottle-image").offset().left - $(".fine-ruby .bottle-image").width() / 2,
          left: -($entryPoint.offset().left - $(".fine-ruby .bottle-image").offset().left) + $(".fine-ruby .bottle-image").width() / 2 + 1
        });

        $lineVintage.css({
          width: $(".vintage .bottle-image").offset().left,
          left: -(0.63275 * $(".vintage .bottle-image").offset().left)
        });

        $lineLBV.css({
          width: $(".lbv .bottle-image").offset().left - $entryPoint.offset().left + $(".lbv .bottle-image").width() + _globalViewportW * 0.02,
          left: -1
        });

        $(".line-vintage-container").css({
          width: $(".vintage .bottle-image").offset().left,
          height: $lineVintage.height()
        });

        $(".line-lbv-container").css({
          width: $(".lbv .bottle-image").offset().left - $entryPoint.offset().left + $(".lbv .bottle-image").width() + _globalViewportW * 0.02,
          left: -1,
          height: $lineLBV.height()
        });

        f();
      }

      if (page === 'white') {
        var $lineWhiteDry = $(".line-white-dry"),
          $lineFineWhite = $(".line-fine-white"),
          $lineLagrima = $(".line-lagrima"),
          $entryPoint = $(".entry-point");

        $lineWhiteDry.css({
          width: $(".white-and-dry .bottle-image").offset().left - $entryPoint.offset().left + $(".white-and-dry .bottle-image").width() / 2,
          left: -1
        });

        $lineFineWhite.css({
          width: $entryPoint.offset().left - $(".fine-white .bottle-image").offset().left + $(".fine-white .bottle-image").width() / 2 - _globalViewportW * 0.065
        });

        $lineLagrima.css({
          width: $(".lagrima .bottle-image").offset().left + $(".lagrima .bottle-image").width() / 2,
          left: -($(".lagrima .bottle-image").offset().left + $(".lagrima .bottle-image").width() / 2) / 3 + 5
        });

        $(".line-lagrima-container").css({
          width: $(".lagrima .bottle-image").offset().left + $(".lagrima .bottle-image").width() / 2,
          height: $lineLagrima.height()
        });

        $(".line-fine-white-container").css({
          width: $entryPoint.offset().left - $(".fine-white .bottle-image").offset().left + $(".fine-white .bottle-image").width() / 2 - _globalViewportW * 0.065,
          left: -($entryPoint.offset().left - $(".fine-white .bottle-image").offset().left) - $(".fine-white .bottle-image").width() / 2 + _globalViewportW * 0.065 + 1,
          height: $lineFineWhite.height()
        });

        f();

        if (!_browserObj.isMobile) {
          $_window.on("resize.linesWhite", $.debounce(100, resizeLinesWhite));
        }
      }

      if (page === 'tawny') {
        var $entryPoint = $(".entry-point"),
          $lineSpecialReserve = $(".line-special-reserve"),
          $lineTawny = $(".line-tawny"),
          $lineColheita = $(".line-colheita");

        $lineSpecialReserve.css({
          width: $entryPoint.offset().left - $(".special-reserve").offset().left - $(".special-reserve .bottle-image").width() / 2 - _globalViewportW * 0.045
        });

        $(".line-special-reserve-container").css({
          width: $entryPoint.offset().left - $(".special-reserve").offset().left - $(".special-reserve .bottle-image").width() / 2 - _globalViewportW * 0.045,
          left: -($entryPoint.offset().left - $(".special-reserve").offset().left - $(".special-reserve .bottle-image").width() / 2) + _globalViewportW * 0.045 + 2,
          height: $lineSpecialReserve.height()
        });

        if ($("body").attr("data-lang") === 'en') {
          $lineTawny.css({
            width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
            left: 0
          });

          $(".line-tawny-container").css({
            width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
            left: 0,
            height: $lineTawny.height()
          });

          $lineColheita.css({
            width: $entryPoint.offset().left - $(".aged-tawny .bottles-images-container").offset().left - _globalViewportW * 0.035
          });

          $(".line-colheita-container").css({
            width: $entryPoint.offset().left - $(".aged-tawny .bottles-images-container").offset().left - _globalViewportW * 0.035,
            left: -($entryPoint.offset().left - $(".aged-tawny .bottles-images-container").offset().left) - _globalViewportW * 0.15,
            height: $lineColheita.height()
          });

        } else {
          $lineTawny.css({
            width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
            left: 0
          });

          $(".line-tawny-container").css({
            width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
            left: 0,
            height: $lineTawny.height()
          });

          $lineColheita.css({
            width: $entryPoint.offset().left - $(".idades .bottles-images-container").offset().left - _globalViewportW * 0.035
          });

          $(".line-colheita-container").css({
            width: $entryPoint.offset().left - $(".idades .bottles-images-container").offset().left - _globalViewportW * 0.035,
            left: -($entryPoint.offset().left - $(".idades .bottles-images-container").offset().left) - _globalViewportW * 0.15,
            height: $lineColheita.height()
          });
        }

        f();

        if (!_browserObj.isMobile) {
          $_window.on("resize.linesTawny", $.debounce(100, resizeLinesTawny));
        }
      }
    }

    this.animateIn = function () {
      TweenMax.set(".line-scroll", {opacity: 1});
      for (var index = 0; index < linesScrollArray.length; index++) {
        TweenMax.to(linesScrollArray[index].elem, 2, {
          strokeDashoffset: linesScrollArray[index].len - (linesScrollArray[index].len * linesScrollArray[index].offsetLine),
          strokeDasharray: linesScrollArray[index].len,
          ease: Expo.easeInOut
        });
      }
    }

    function resizeLinesRose() {
      var offset = $bottleImg.offset().left;
      var iconOffsetLeft = $preserveIcon.offset().left;
      $(".line-rose").css({
        width: $preserveIcon.offset().left - $bottleImg.offset().left - $bottleImg.width() / 2 + $preserveIcon.width() / 2,
        left: -($preserveIcon.offset().left - $bottleImg.offset().left) + $bottleImg.width() / 2
      });
    };

    function resizeLinesRuby() {
      $(".line-fine-ruby").css({
        width: $(".entry-point").offset().left - $(".fine-ruby .bottle-image").offset().left - $(".fine-ruby .bottle-image").width() / 2,
        left: -($(".entry-point").offset().left - $(".fine-ruby .bottle-image").offset().left) + $(".fine-ruby .bottle-image").width() / 2 + 1
      });

      $(".line-lbv").css({
        width: $(".lbv .bottle-image").offset().left - $(".entry-point").offset().left + $(".lbv .bottle-image").width() + _globalViewportW * 0.02,
        left: -1
      });

      $(".line-vintage").css({
        width: $(".vintage .bottle-image").offset().left,
        left: -(0.63275 * $(".vintage .bottle-image").offset().left)
      });

      $(".line-vintage-container").css({
        width: $(".vintage .bottle-image").offset().left,
        height: $(".line-vintage").height()
      });

      $(".line-lbv-container").css({
        width: $(".lbv .bottle-image").offset().left - $(".entry-point").offset().left + $(".lbv .bottle-image").width() + _globalViewportW * 0.02,
        left: -1,
        height: $(".line-lbv").height()
      });
    }

    function resizeLinesWhite() {
      $(".line-white-dry").css({
        width: $(".white-and-dry .bottle-image").offset().left - $(".entry-point").offset().left + $(".white-and-dry .bottle-image").width() / 2,
        left: -1
      });

      $(".line-fine-white").css({
        width: $(".entry-point").offset().left - $(".fine-white .bottle-image").offset().left + $(".fine-white .bottle-image").width() / 2 - _globalViewportW * 0.065
      });

      $(".line-lagrima").css({
        width: $(".lagrima .bottle-image").offset().left + $(".lagrima .bottle-image").width() / 2,
        left: -($(".lagrima .bottle-image").offset().left + $(".lagrima .bottle-image").width() / 2) / 3 + 5
      });

      $(".line-lagrima-container").css({
        width: $(".lagrima .bottle-image").offset().left + $(".lagrima .bottle-image").width() / 2,
        height: $(".line-lagrima").height()
      });

      $(".line-fine-white-container").css({
        width: $(".entry-point").offset().left - $(".fine-white .bottle-image").offset().left + $(".fine-white .bottle-image").width() / 2 - _globalViewportW * 0.065,
        left: -($(".entry-point").offset().left - $(".fine-white .bottle-image").offset().left) - $(".fine-white .bottle-image").width() / 2 + _globalViewportW * 0.065 + 1,
        height: $(".line-fine-white").height()
      });
    }

    function resizeLinesTawny() {

      $(".line-special-reserve").css({
        width: $(".entry-point").offset().left - $(".special-reserve").offset().left - $(".special-reserve .bottle-image").width() / 2 - _globalViewportW * 0.045
      });

      $(".line-special-reserve-container").css({
        width: $(".entry-point").offset().left - $(".special-reserve").offset().left - $(".special-reserve .bottle-image").width() / 2 - _globalViewportW * 0.045,
        left: -($(".entry-point").offset().left - $(".special-reserve").offset().left - $(".special-reserve .bottle-image").width() / 2) + _globalViewportW * 0.045 + 2,
        height: $(".line-special-reserve").height()
      });

      if ($("body").attr("data-lang") === 'en') {
        $(".line-tawny").css({
          width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
          left: 0
        });

        $(".line-tawny-container").css({
          width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
          left: 0,
          height: $(".line-tawny").height()
        });

        $(".line-colheita-container").css({
          width: $(".entry-point").offset().left - $(".aged-tawny .bottles-images-container").offset().left - _globalViewportW * 0.035,
          left: -($(".entry-point").offset().left - $(".aged-tawny .bottles-images-container").offset().left) - _globalViewportW * 0.15,
          height: $(".line-colheita").height()
        });

        $(".line-colheita").css({
          width: $(".entry-point").offset().left - $(".aged-tawny .bottles-images-container").offset().left - _globalViewportW * 0.035
        });
      } else {
        $(".line-tawny").css({
          width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
          left: 0
        });

        $(".line-tawny-container").css({
          width: $(".colheita .bottles-images-container").offset().left - $(".colheita .bottles-images-container").width() / 2 - _globalViewportW * 0.055,
          left: 0,
          height: $(".line-tawny").height()
        });

        $(".line-colheita-container").css({
          width: $(".entry-point").offset().left - $(".idades .bottles-images-container").offset().left - _globalViewportW * 0.035,
          left: -($(".entry-point").offset().left - $(".idades .bottles-images-container").offset().left) - _globalViewportW * 0.15,
          height: $(".line-colheita").height()
        });

        $(".line-colheita").css({
          width: $(".entry-point").offset().left - $(".idades .bottles-images-container").offset().left - _globalViewportW * 0.035
        });
      }
    }

    this.scrollAnimate = function (scroll) {
      for (var i = 0; i <= linesScrollArray.length - 1; i++) {
        if (linesScrollArray[i].done === false) {
          var lineAnim = scaleBetween(scroll + pageHeaderHeight, linesScrollArray[i].len - (linesScrollArray[i].len * linesScrollArray[i].offsetLine), 0, pageHeaderHeight, linesScrollArray[i].targetOffset + linesScrollArray[i].targetHeight / 2);
          TweenMax.set(linesScrollArray[i].elem, {
            strokeDashoffset: lineAnim
          });
          if (lineAnim <= 0) {
            linesScrollArray[i].done = true;
          }
        }
      }
    }

    this.kill = function () {
      linesScrollArray = [];
      if (pageName === 'rose') {
        if (!_browserObj.isMobile) {
          $_window.off("resize.linesRose");
        }
      } else if (pageName === 'ruby') {
        if (!_browserObj.isMobile) {
          $_window.off("resize.linesRuby");
        }
      } else if (pageName === 'white') {
        if (!_browserObj.isMobile) {
          $_window.off("resize.linesWhite");
        }
      } else if (pageName === 'tawny') {
        if (!_browserObj.isMobile) {
          $_window.off("resize.linesTawny");
        }
      }
    }
  }

  function portfolioArchivePage_scroll_rAF(status) {
    if (!_buroScroll) {
      var status = {
        scrollVal: window.pageYOffset
      }

      if (lastScroll - status.scrollVal < 0) {
        status.direction = 'down';
      } else if (lastScroll - status.scrollVal > 0) {
        status.direction = 'up';
      }

      lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
    }


    if(status.scrollVal + _globalViewportH > $nextCategoryOffsetTop){
      var yMove = scaleBetween(status.scrollVal + _globalViewportH, 150, 0, $nextCategoryOffsetTop, status.scrollLimit);
      TweenMax.set([$nextCategoryBottle, $nextCategoryBase], {
        y: yMove
      })
    }

    _globalHeader.animate(status.scrollVal, status.direction);
    portfolioScrollAnimationsObj.inViewport(status.scrollVal);
    yearsBottleObj.inViewport(status.scrollVal);
    linesPageObj.scrollAnimate(status.scrollVal);
    urlBottlesObj.inViewport(status.scrollVal, status.direction);
    globalCtaCavesObj.animate(status.scrollVal, $(".page-current").height(), status.direction);

    if ($serveFresh) {
      var teste = scaleBetween(status.scrollVal, 0, 360, serveFreshOffset - _globalViewportH / 2, serveFreshOffset + _globalViewportH / 2)
      TweenMax.set($serveFresh, {
        rotation: teste
      });
    }

    if ($suggestion) {
      suggestionObj.animate(status.scrollVal);
    }

    if (!_browserObj.isMobile) {
      portfolioParallaxElements.update(status.scrollVal);
    }

  }

  return {
    init: init,
    kill: kill
  }
}

///BARBA INITIALIZER///
var portfolioArchivePage = Barba.BaseView.extend({
  namespace: 'portfolio-archive',
  onEnter: function () {

  },
  onEnterCompleted: function () {
    // The Transition has just finished.
    _currentView = portfolioArchivePageFunctions();
    _currentView.init();
  },
  onLeave: function () {
    // A new Transition toward a new page has just started.
    _currentView.kill();
  },
  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
  }
});


portfolioArchivePage.init();