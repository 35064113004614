function homeSlider() {

    var $homeSlider = $(".home-slider"),
        $homeSliderWrapper = $(".home-slider-wrapper"),
        $homeSliderArrow = $(".home-slider-arrow"),
        $loader = $(".loader"),
        $captionsContainer = $(".captions-container");

    var current = 0,
        totalCaptions = $captionsContainer.find("li").length,
        number = 0,
        spriteImagesSrc = [],
        fullCircleLength = 0,
        galleryLoopRAF = null,
        isClicked = false,
        offset = _globalViewportH/2,
        loaderTL = new TimelineMax({paused: true}),
        canClick = true,
        initCanvasSlideshow,
        displacementImageObj = null,
        homeSliderOffset = $homeSlider.offset().top,
        homeSliderHeight = $homeSlider.height();

    this.init = function(){
        if(!_browserObj.isEdge && !_browserObj.isIE)
            fullCircleLength = $loader[0].getTotalLength();
        else
            fullCircleLength = 181.6670684814453;

        getImages();

        initCanvasSlideshow = new canvasSlideshow({
          containerTarget: '.home-slider-wrapper',
          sprites: spriteImagesSrc,
          displacementImage: '/public/images/home/disp5.jpg',
          autoPlay: false,
          displaceScale: [200, 70],
          fullScreen: true,
          wacky: false,
          centerSprites: true,
          wacky: true
        });

        loaderTL.to($loader, 3.6, {strokeDashoffset: 0, ease: Linear.easeNone});
        $captionsContainer.find("li.active").next().addClass("next");
        $captionsContainer.find("li").each(function(){
            var $this = $(this),
                captionLine = new SplitText([$this.find("h3"), $this.find("p")] , {type:"words, chars"});
                captionChars = captionLine.chars;

            $(captionChars).addClass("chars");
        });

        $homeSliderArrow.on("click", function(){
            cancelAnimationFrame(galleryLoopRAF);
            var $this = $(this),
                direction = $this.attr("data-direction");

            isClicked = true;

            if(canClick){
                canClick = false;
                animate(direction);
                loaderTL.pause();
                TweenMax.to($loader, 0.4, {strokeDashoffset: -fullCircleLength, ease: Linear.easeNone, onComplete: function(){
                  TweenMax.set($loader, {opacity: 0});
                }});
            }
        });

        if(!_browserObj.isMobile){
            $(document).on("keydown.homeSlider", function(event) {
                switch (event.which) {
                  case 39: //arrow right
                    $homeSliderArrow.filter(".home-arrow-right").click();
                    break;
                  case 37: //arrow left
                    $homeSliderArrow.filter(".home-arrow-left").click();
                    break;
                  default:
                    break;
                }
            });

            $_window.on("resize.homeSlider", $.debounce(100, resizeHomeSlider));
        }
    }

    this.inViewport = function(scroll){
        if(scroll + _globalViewportH > homeSliderOffset && scroll + _globalViewportH < homeSliderOffset + homeSliderHeight + offset && !$homeSlider.hasClass("animate-in")){
            if(!isClicked){
                $homeSlider.addClass("animate-in");
                loaderTL.play();
                galleryLoop();
            }
        } else if(scroll + _globalViewportH < homeSliderOffset && $homeSlider.hasClass("animate-in") || scroll > homeSliderOffset + homeSliderHeight && $homeSlider.hasClass("animate-in")){
            if(!isClicked){
                $homeSlider.removeClass("animate-in");
                loaderTL.pause();
                cancelAnimationFrame(galleryLoopRAF);
            }
        }
    }

    function getImages(){
        spriteImagesSrc = $homeSliderWrapper.attr("data-images").split(',');
        spriteImagesSrc.pop();
    }

    function galleryLoop() {
        if(number > 60*4){
            number = 0;
            animate('right');
            canClick = false;
            loaderTL.pause();
            initCanvasSlideshow.moveSlider(current);
            TweenMax.to($loader, .4, {strokeDashoffset: -fullCircleLength, ease: Linear.easeNone, onComplete: function(){
                loaderTL.restart();
            }});
        }            
        number++;
        galleryLoopRAF = _rAF_loop(galleryLoop);
    }

    function resizeHomeSlider(){
        homeSliderOffset = $homeSlider.offset().top;
        homeSliderHeight = $homeSlider.height();
    }

    function animate(direction){
        if(direction == 'right'){
            TweenMax.staggerTo($captionsContainer.find("li").eq(current).find("h3 .chars"), 1, {
                x: 10, 
                opacity: 0, 
                ease: Circ.easeOut
            }, 0.03);
            TweenMax.staggerTo($captionsContainer.find("li").eq(current).find("p .chars"), 1, {
                x: 10, 
                opacity: 0, 
                delay: 0.2,
                ease: Circ.easeOut
            }, 0.03);
            TweenMax.staggerFromTo($captionsContainer.find("li.next").find("h3 .chars"), 1, {
                x: -10, 
                opacity: 0, 
            }, {
                x: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.03);
            TweenMax.staggerFromTo($captionsContainer.find("li.next").find("p .chars"), 1, {
                x: -10, 
                opacity: 0, 
            }, {
                x: 0,
                opacity: 1,
                delay: 0.2, 
                ease: Circ.easeOut
            }, 0.03, function(){
                $captionsContainer.find("li.active").removeClass("active");
                $captionsContainer.find("li.next").removeClass("next").addClass("active").next().addClass("next");
                TweenMax.set($captionsContainer.find("li .chars"), {clearProps: "all"});
                canClick = true;
                if($captionsContainer.find("li.active").next().length == 0){
                    $captionsContainer.find("li").eq(0).addClass("next");
                }
            });
            if(current < totalCaptions-1){
                current++;
            } else {
                current = 0;
            }
        } else {
            if($captionsContainer.find("li.active").prev().length == 0){
                TweenMax.staggerTo($captionsContainer.find("li").eq(current).find("h3 .chars"), 1, {
                    x: -10, 
                    opacity: 0, 
                    ease: Circ.easeOut
                }, 0.03);
                TweenMax.staggerTo($captionsContainer.find("li").eq(current).find("p .chars"), 1, {
                    x: -10, 
                    opacity: 0, 
                    delay: 0.2, 
                    ease: Circ.easeOut
                }, 0.03);
                TweenMax.staggerFromTo($captionsContainer.find("li").last().find("h3 .chars"), 1, {
                    x: 10, 
                    opacity: 0, 
                }, {
                    x: 0,
                    opacity: 1,
                    ease: Circ.easeOut
                }, 0.03);
                TweenMax.staggerFromTo($captionsContainer.find("li").last().find("p .chars"), 1, {
                    x: 10, 
                    opacity: 0, 
                }, {
                    x: 0,
                    opacity: 1,
                    delay: 0.2,
                    ease: Circ.easeOut
                }, 0.03, function(){
                    $captionsContainer.find("li.active").removeClass("active");
                    $captionsContainer.find("li.next").removeClass("next");
                    $captionsContainer.find("li").last().addClass("active");
                    $captionsContainer.find("li").eq(0).addClass("next");
                    TweenMax.set($captionsContainer.find("li .chars"), {clearProps: "all"});
                    current = totalCaptions - 1;
                    canClick = true;
                });
            } else {
                TweenMax.staggerTo($captionsContainer.find("li").eq(current).find("h3 .chars"), 1, {
                    x: -10, 
                    opacity: 0, 
                    ease: Circ.easeOut
                }, 0.03);
                TweenMax.staggerTo($captionsContainer.find("li").eq(current).find("p .chars"), 1, {
                    x: -10, 
                    opacity: 0, 
                    delay: 0.2,
                    ease: Circ.easeOut
                }, 0.03);
                TweenMax.staggerFromTo($captionsContainer.find("li.active").prev().find("h3 .chars"), 1, {
                    x: 10, 
                    opacity: 0, 
                }, {
                    x: 0,
                    opacity: 1,
                    ease: Circ.easeOut
                }, 0.03);
                TweenMax.staggerFromTo($captionsContainer.find("li.active").prev().find("p .chars"), 1, {
                    x: 10, 
                    opacity: 0, 
                }, {
                    x: 0,
                    opacity: 1,
                    delay: 0.2,
                    ease: Circ.easeOut
                }, 0.03, function(){
                    $captionsContainer.find("li.next").removeClass("next");
                    $captionsContainer.find("li.active").removeClass("active").addClass("next").prev().addClass("active");
                    TweenMax.set($captionsContainer.find("li .chars"), {clearProps: "all"});
                    canClick = true;
                });
                if(current > 0) {
                    current--;
                } else {
                    current = 0;
                }
            }
        }
        // displacementImageObj.goTo(current);
    }

    this.kill = function(){
        $(document).off("keydown.homeSlider"); 
        $_window.off("resize.homeSlider");
        cancelAnimationFrame(galleryLoopRAF);
    }
}