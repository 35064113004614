var termsAndServicesTransition = Barba.BaseTransition.extend({
    start: function () {

        $_body.addClass("js-loading-page");

        // As soon the loading is finished and the old page is faded out, let's fade the new page
        Promise.all([this.newContainerReady, this.newContainerLoading])
            .then(this.fadeOut.bind(this))
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        var _this = this;
        return new Promise(function (resolve, reject) {

            var mouseX, mouseY, raf_loading;
            if(!_browserObj.isMobile){
                TweenMax.set(".loading-svg", {autoAlpha: 1, scale: 1});
            } else {
                TweenMax.set(".loading-svg", {
                    autoAlpha: 1, 
                    scale: 1, 
                    delay: 0.5,
                    y: (_globalViewportH - $(".loading-svg").outerHeight())/2 + $(_this.newContainer).find(".page-header .terms-title").outerHeight()
                });
            }
            TweenMax.to(".loading-svg", 1, {rotation: "+=60", repeat: -1, ease: Linear.easeNone});
            if(!_browserObj.isMobile){
              $_window.on("mousemove.loading", function (e) {
                mouseX = e.clientX - $(".loading-svg").outerWidth() / 2;
                mouseY = e.clientY - $(".loading-svg").outerHeight() / 2;
              });
        
              loadingLoop();
              TweenMax.set(".loading-svg", {x: (window.innerWidth - $(".loading-svg").outerWidth()) / 2, y: (window.innerHeight - $(".loading-svg").outerHeight()) / 2})
        
              function loadingLoop() {
                TweenMax.to(".loading-svg", .6, {
                    x: mouseX,
                    y: mouseY,
                    ease: Circ.easeOut
                });
                raf_loading = _rAF_loop(loadingLoop);
              } 
            }

            $(".main-logo").removeClass("white-version");
            $("#header-main").css("pointer-events", "none");
            TweenMax.set($(".loading-layout .loading-titles-container"), {
                autoAlpha: 0
            });
            setTimeout(function () {
                $("#header-main").removeClass("header-collapse");
            }, 100);
            $(".header-link").eq(2).removeClass("active-blue");
            $(".header-link.active").removeClass("active");
            TweenMax.set($(".loading-layout h3"), {
                display: "block",
                opacity: 0
            });
            $(".loading-layout").addClass("white-version");
            TweenMax.set(".loading-layout", {
                clearProps: "paddingTop"
            });
            $(".loading-layout .second-big-titles").html($(_this.newContainer).find(".page-header .terms-title").html());
            $(".loading-layout .second-big-titles").append('<span>' + $(_this.newContainer).find(".page-header .terms-title").html() + '</span>');
            $(".loading-layout h3").html($(_this.newContainer).find(".page-header span").html());
            $(".loading-layout h2").removeClass("uppercase")
            $(".loading-layout h2").addClass("terms-styles");
            $(".loading-layout h3").addClass("terms-styles");
            TweenMax.set($(".loading-layout .second-big-titles"), {
                clearProps: "all"
            });
            TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.6, {
                fill: "#06214D",
                ease: Circ.easeOut
            });
            TweenMax.to($(".logo-image"), 0.6, {
                alpha: 1,
                delay: 0.1,
                ease: Expo.easeInOut
            });
            TweenMax.to($("#header-main"), 1, {
                y: 0,
                ease: Expo.easeInOut
            });
            setTimeout(function(){
                if(_browserObj.isMobile){
                    if($("nav.show-mobile").hasClass("menu-is-open")){
                        $(".hamburguer").click();
                    }
                }
            }, 400)
            TweenMax.to(".loading-layout", 1, {
                clip: "rect(0px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                ease: Expo.easeInOut,
                onComplete: function () {
                    $_window.scrollTop(0);
                    TweenMax.set(_this.oldContainer, {
                        autoAlpha: 0,
                        onComplete: function () {
                            TweenMax.to(".loading-svg", 0.2, {opacity: 0, scale: 0.9, ease: Expo.easeOut});
                            TweenMax.set($(_this.oldContainer).find(".page-current"), {
                                clearProps: "transform"
                            });
                            TweenMax.set(_this.oldContainer, {
                                display: 'none'
                            });
                            resolve();
                            if(!_browserObj.isMobile){
                                $_window.off("mousemove.loading");
                                cancelAnimationFrame(raf_loading);
                            }
                            setTimeout(function () {
                                TweenMax.to(".loading-layout", 1, {
                                    clip: "rect(" + $('.loading-layout').outerHeight() + "px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                                    ease: Expo.easeInOut,
                                    onComplete: function () {
                                        $("#header-main").removeClass("whiteCategory");
                                        $("#header-main").removeClass("tawnyCategory");
                                        $("#header-main").removeClass("rubyCategory");
                                        $("#header-main").removeClass("roseCategory");
                                        $(".loading-layout").removeClass("white-version");
                                        $(".loading-layout h2").removeClass("terms-styles");
                                        $(".loading-layout h3").addClass("terms-styles")
                                        TweenMax.set($("#header-main .main-logo .logo-type svg path"), {
                                            clearProps: "fill"
                                        });
                                        TweenMax.set(".loading-layout", {
                                            clip: "rect(0px " + $('.loading-layout').outerWidth() + "px 0px 0px)"
                                        })
                                        $("#header-main").css("pointer-events", "all");
                                    }
                                });
                            }, 200);
                        }
                    })
                }
            })
        });
    },

    fadeIn: function () {
        var _this = this;
        TweenMax.fromTo(_this.newContainer, .2, {
            autoAlpha: 0
        }, {
            autoAlpha: 1,
            onComplete: function () {
                _this.done();
                $_body.removeClass("js-loading-page");
                manageBodyClasses(_this.newContainer, _this.oldContainer)
            }
        });
    }
});