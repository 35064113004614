
function buroParallaxMouseMove() {
  var elements = document.querySelectorAll('[data-parallaxMove]');
  var parallax_array = [];
  var mouseX = 0;
  var mouseY = 0;
  var mouseXAbs = 0;
  var mouseYAbs = 0;
  var loop_id = -1;

  var init = function() {
    elements.forEach(function(item, i){
      var elem = {};

      if(item.dataset.movefriction)
        elem.moveFriction = item.dataset.movefriction;
      else
        elem.moveFriction = 1;

      elem.element = item;
      elem.close = item.querySelectorAll('[data-parallaxMoveClose]');
      elem.away = item.querySelectorAll('[data-parallaxMoveAway]');
      elem.offsetY = item.getBoundingClientRect().top;
      elem.offsetX = item.getBoundingClientRect().left;
      elem.height = item.offsetHeight;
      elem.width = item.offsetWidth;
      elem.inView = false;
      elem.isOver = false;
 
      TweenMax.set([elem.close, elem.away], { transformStyle: "preserve-3d" });

      parallax_array.push(elem);
    });

    document.addEventListener("mousemove", mouseMoveFunc);
    updateInView(0);
    loop_id = _rAF_loop(update);
  }

  var update = function() {
    loop_id = _rAF_loop(update);
 
    for(var i=0,len=parallax_array.length; i<len; i++) {
      if(parallax_array[i].inView) {
        parallax_array[i].isOver = true;
        
        TweenMax.to(parallax_array[i].close, 3, {
          rotationY: -0.03 * mouseX*.1*parallax_array[i].moveFriction,
          rotationX: 0.03 * mouseY*.1*parallax_array[i].moveFriction,
          transformPerspective: 500,
          transformOrigin: "center center -400",
          ease: Power2.easeOut
        });
        TweenMax.to(parallax_array[i].away, 3, {
          rotationY: -0.03 * mouseX*.1*parallax_array[i].moveFriction,
          rotationX: 0.03 * mouseY*.1*parallax_array[i].moveFriction,
          transformPerspective: 500,
          transformOrigin: "center center -200",
          ease: Power2.easeOut
        });
        
      }
      else {
        parallax_array[i].isOver = false;

        TweenMax.to(parallax_array[i].close, 2, {
          rotationY:0,
          rotationX: 0,
          transformPerspective: 500,
          transformOrigin: "center center -400",
          ease: Expo.easeOut
        });
        TweenMax.to(parallax_array[i].away, 2, {
          rotationY: 0,
          rotationX: 0,
          transformPerspective: 500,
          transformOrigin: "center center -200",
          ease: Expo.easeOut
        });
      }
    }
  }

  var updateInView = function(scrollVal) {
    for(var i=0,len=parallax_array.length; i<len; i++) {
      if(scrollVal + _globalViewportH > parallax_array[i].offsetY && scrollVal + _globalViewportH < parallax_array[i].offsetY + parallax_array[i].height + _globalViewportH) {
        parallax_array[i].inView = true;
      }
    }
  }

  var mouseMoveFunc = function(e) {
    mouseX = e.clientX - _globalViewportW/2;
    mouseY = e.clientY - _globalViewportH/2;
    mouseXAbs = e.pageX;
    mouseYAbs = e.pageY;
  }

  var kill = function() {
    document.removeEventListener("mousemove", mouseMoveFunc);
    cancelAnimationFrame(loop_id);
  }
  return {
    init: init,
    update: update,
    updateInView: updateInView,
    kill: kill
  }
}