function portfolioDragger() {

    var $portfolioDragger = $(".portfolio-dragger"),
        $buttonsContainer = $(".buttons-container"),
        $categoryTextContainer = $(".category-text-container"),
        $categoryTextWrapper = $(".category-text-wrapper"),
        $bottleWrapper = $(".bottle-wrapper"),
        $discoverButton = $(".discover-button"),
        $draggerWrapper = $(".dragger-wrapper"),
        $dragWord = $(".drag-word"),
        $draggerTitles = $(".dragger-titles"),
        $jsDragElement = $(".js-drag-element"),
        $hitZone = $(".hit-zone"),
        $draggerCircle = $(".dragger-circle"),
        $bottleCanvasContainer = $(".bottle-canvas-container"),
        $circleBottleWrapper = $(".circle-bottle-wrapper"),
        $customCursor = $(".custom-cursor"),
        $circleContainer = $(".circle-container"),
        $buttonsContainerMobile = $(".buttons-container-mobile"),
        $draggerHelperMobile = $("#dragger-helper-mobile"),
        $arrowCursor = $(".arrow"),
        $mobileCategoryTextContainer = $(".mobile-category-text-container"),
        $categoryTextWrapperMobile = $(".category-text-wrapper-mobile");

    var currentSlide = _titleActiveIndex,
        draggerHelperMobile = null,
        snapPoints = [],
        snapPointsTitles = [],
        draggerWidth = 0,
        isAnimatingKeyPressed = false,
        dragAmount = 0,
        maxHeight = 0,
        portDrag = null,
        maxHeightMobile = 0,
        isPressed = false,
        isScrolling = false,
        mouseX = 0,
        mouseY = 0,
        scrollDrag = 0,
        maxTimer = 0,
        elemsArr = [],
        raf_cursor = null,
        cursorTotalLength = 0,
        arrayTitlesColors = [
            {active:false},
            {active:false},
            {active:false},
            {active:false}
        ],
        slideWidth = $draggerWrapper.outerWidth(),
        displacementImageObj = displacementImage({
            displacementImage: '/public/images/home/displacement2.png',
            spriteImages: ['/public/images/white-base-light@2x.png', '/public/images/tawny-base-light@2x.png', '/public/images/ruby-base-light@2x.png', '/public/images/rose-base-light@2x.png'],
            container: $(".bottle-canvas-container")
        });

    this.preload = function () {
        $(".preload-displacement").imagesLoaded(function ($images, $proper, $broken) {
            var fPreload = $(this).imagesLoaded();
            fPreload.always(function () {
                displacementImageObj.init(_titleActiveIndex, function(){
                    portDrag.enable();
                });
            });
        });
    }

    this.init = function () {
        disableBodyScroll(true);

        if (!_browserObj.isMobile) {
            $hitZone.css({
                "width": $bottleWrapper.find("img").outerWidth()+ 60,
                "height": $buttonsContainer.outerHeight() + 60
            });
            $_window.on('keydown.portfolioDragger', function (event) {
                switch (event.keyCode) {
                    case 16:
                        return false;
                        break;

                    case 17:
                        return false;
                        break;

                    case 18:
                        return false;
                        break;

                                
                    case 91:
                        return false;
                        break;

                
                    case 93:
                        return false;
                        break;


                    case 39:
                        if (!isAnimatingKeyPressed) {
                            next();
                        }
                        break;

                    case 37:
                        if (!isAnimatingKeyPressed) {
                            prev();
                        }
                        break;

                    default:
                        break;
                }
            });

            $hitZone.hover(function(){
                TweenMax.to($customCursor, .6, {
                    scale: 0,
                    ease: Circ.easeOut
                });
                $buttonsContainer.addClass("hovered");
            }, function(){
                TweenMax.to($customCursor, .6, {
                    scale: 1,
                    ease: Circ.easeOut
                });
                $buttonsContainer.removeClass("hovered");
            })
        }

        if (!_browserObj.isEdge && !_browserObj.isIE) {
            cursorTotalLength = 242;
        } else {
            cursorTotalLength = 242;
        }

        if (!_browserObj.isMobile) {
            maxTimer = 2;
            cursorLoop();
            $_window.on("mousemove.portfolio", function (e) {
                mouseX = e.clientX - $customCursor.outerWidth() / 2;
                mouseY = e.clientY - $customCursor.outerHeight() / 2;
            });

            function cursorLoop() {
                TweenMax.to($dragWord, 1, {
                    rotation: "+=20",
                    repeat: -1,
                    ease: Linear.easeNone
                });
                TweenMax.to($customCursor, .6, {
                    x: mouseX,
                    y: mouseY,
                    ease: Circ.easeOut
                });
                if (isPressed) {
                    TweenMax.to($buttonsContainer, 0.25, {
                        clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px " + $buttonsContainer.outerWidth() / 2 + "px)",
                        ease: Power4.easeOut
                    });
                }
                raf_cursor = _rAF_loop(cursorLoop);
            }

            $buttonsContainer.hover(function () {
                TweenMax.to($customCursor, .6, {
                    scale: 0,
                    ease: Circ.easeOut
                });
            }, function () {
                TweenMax.to($customCursor, .6, {
                    scale: 1,
                    ease: Circ.easeOut
                });
            });

            $("#header-main").hover(function () {
                TweenMax.to($customCursor, .6, {
                    scale: 0,
                    ease: Circ.easeOut
                });
            }, function () {
                TweenMax.to($customCursor, .6, {
                    scale: 1,
                    ease: Circ.easeOut
                });
            });

            // VirtualScroll.on(function (e) {
            //     if (!isScrolling && !isPressed) {
            //         scrollDrag += e.deltaY;
            //         if (scrollDrag < -1) {
            //             next();
            //         } else {
            //             prev();
            //         }
            //     }
            // });
        } else {
            maxTimer = 2;
        }

        $categoryTextWrapper.each(function () {
            var $this = $(this),
                elemHeight = $this.outerHeight();

            TweenMax.set($this, {
                width: $this.outerWidth(),
                height: elemHeight
            });
            if (elemHeight > maxHeight)
                maxHeight = elemHeight;
        });

        $categoryTextWrapper.css({
            position: "absolute",
            left: "calc(" + $categoryTextContainer.find("span").outerWidth() + "px + 0.625rem"
        });
        $categoryTextContainer.css({
            height: maxHeight
        });

        $bottleCanvasContainer.css({
            width: $circleBottleWrapper.find(".bottle").outerWidth(),
            height: $circleBottleWrapper.find(".bottle").outerHeight()
        });

        if (!_browserObj.isMobile) {
            // $(".preload-displacement").imagesLoaded(function($images, $proper, $broken) {
            //     var fPreload = $(this).imagesLoaded();
            //     fPreload.always(function() {
            //         displacementImageObj.init(currentSlide);
            //     });
            // });
        } else {
            categoryTextMobile();
        }

        if (_browserObj.isPhone && $_body.hasClass("js-no-ajax")){
            TweenMax.set($buttonsContainerMobile.find("button"), {
                clip: "rect(0px " + $buttonsContainerMobile.find("button").outerWidth() + "px " + $buttonsContainerMobile.find("button").outerHeight() + "px 0px)"
            });
        }

        if (_browserObj.isMobile) {
            draggerHelperMobile = bodymovin.loadAnimation({
                container: $draggerHelperMobile[0],
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/public/bodymovin/dragger-mobile.json'
            });
        }

        TweenMax.set($draggerCircle, {
            x: (_globalViewportW - $draggerCircle.outerWidth()) / 2
        });

        $(".bottle-canvas-container").css({
            width: $bottleWrapper.find(".base").outerWidth(),
            height: $bottleWrapper.find(".base").outerHeight()
        });

        $categoryTextWrapper.find("p").each(function () {
            var $this = $(this),
                categoryLines = new SplitText($this, {
                    type: "lines"
                });

            $(categoryLines.lines).addClass("line");
        });

        $draggerWrapper.css("width", _globalViewportW * 4);
        draggerWidth = $draggerWrapper.outerWidth();
        $buttonsContainer.css("width", $bottleWrapper.find("img").outerWidth());
        if (!$_body.hasClass("js-no-ajax")) {
            TweenMax.set($buttonsContainer, {
                clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px " + $buttonsContainer.outerWidth() / 2 + "px)"
            });
        }
        if (!_browserObj.isMobile) {
            $_window.on("resize.portfolio", $.debounce(500, this.resize));
        }

        maxLimit = _globalViewportW - $draggerTitles.outerWidth() - (_globalViewportW - $buttonsContainer.offset().left);

        $draggerTitles.find("li").each(function (key) {
            var $this = $(this);
            $this.css({
                "width": $bottleWrapper.find("img").outerWidth(),
                "margin-right": "100px"
            });
        });

        $draggerTitles.css("left", (_globalViewportW - $bottleWrapper.find("img").outerWidth()) / 2);

        for (var i = 0; i < $draggerTitles.find("li").length; i++) {
            snapPointsTitles.push(-($bottleWrapper.find("img").outerWidth() + 100) * i);
            snapPoints.push(-slideWidth * i);
        }

        Draggable.create($draggerWrapper, {
            type: "x",
            throwProps: true,
            minDuration: 1.5,
            maxDuration: maxTimer,
            onPress: growCursor,
            onRelease: decreaseCursor,
            bounds: {
                minX: 0,
                minY: 0,
                maxX: -_globalViewportW * 3,
                maxY: 0
            },
            edgeResistance: 1,
            onThrowUpdate: function () {

                currentSlide = Math.round(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x / slideWidth));
                _titleActiveIndex = currentSlide;
                dragAmount = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, snapPointsTitles[1], 0, _globalViewportW);
                TweenMax.set($draggerTitles, {
                    x: dragAmount
                });

                var circleCursor = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), cursorTotalLength, 0, 0, _globalViewportW);
                TweenMax.set($customCursor.find('.inside-line')[0], {
                    strokeDashoffset: circleCursor
                });

                var rot = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, -25, 0, _globalViewportW);
                TweenMax.set($draggerCircle, {
                    rotation: rot
                });

                var mob = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x) - _globalViewportW * currentSlide, $buttonsContainerMobile.find("button").outerWidth(), 0, 0, _globalViewportW / 2.5);
                TweenMax.set($buttonsContainerMobile.find("button").eq(currentSlide), {
                    clip: "rect(0px " + mob + "px " + $buttonsContainerMobile.find("button").outerHeight() + "px 0px)"
                });

                for (var i = 0; i < elemsArr.length; i++) {
                    var rotate = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initRotation, elemsArr[i].endRotation, 0, _globalViewportW);
                    var scal = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initScale, elemsArr[i].endScale, 0, _globalViewportW);
                    var move = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, elemsArr[i].endOffset - elemsArr[i].initOffset, 0, _globalViewportW);
                    TweenMax.set(elemsArr[i].elem, {
                        rotation: rotate,
                        scale: scal,
                        x: move * elemsArr[i].friction
                    });
                }

                for (var index = 0; index < 4; index++) {
                    if(dragAmount - $bottleWrapper.find("img").outerWidth()/2 < snapPointsTitles[index] && !arrayTitlesColors[index].active){
                        arrayTitlesColors[index].active = true;
                        $draggerTitles.find("li").removeClass("active");
                        $draggerTitles.find("li").eq(index).addClass("active");
                    } else if(dragAmount - $bottleWrapper.find("img").outerWidth()/2 < snapPointsTitles[index] && arrayTitlesColors[index].active){
                        arrayTitlesColors[index].active = false;
                        $draggerTitles.find("li").removeClass("active");
                        $draggerTitles.find("li").eq(index).addClass("active");
                    }                    
                }
            },
            onThrowComplete: function () {
                $draggerTitles.find("li").eq(currentSlide).addClass("active");
                var colorClass = slugify($draggerWrapper.find("li").eq(currentSlide).attr("data-class").toLowerCase());
                var color = $draggerWrapper.find("li").eq(currentSlide).attr("data-color");
                var href = $draggerWrapper.find("li").eq(currentSlide).attr("data-href");
                $buttonsContainer.find("a").attr("href", href);
                $hitZone.attr("href", href);
                $buttonsContainer.removeClass("white tawny ruby rose").addClass(color);
                $buttonsContainer.find(".category-button").html(
                    colorClass +
                    '<span class="arrow"></span>'
                );
                $dragWord.removeClass("white tawny ruby rose").addClass(color);
                $arrowCursor.removeClass("white tawny ruby rose").addClass(color);

                if (!isPressed) {
                    TweenMax.to($buttonsContainer, 0.6, {
                        clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px 0px)",
                        opacity: 1,
                        ease: Circ.easeOut
                    });
                    TweenMax.to($buttonsContainer, 0.6, {
                        clip: "rect(0px " + $buttonsContainer.outerWidth() + "px " + $buttonsContainer.outerHeight() + "px 0px)",
                        delay: 0.2,
                        ease: Circ.easeOut
                    });
                    if (!_browserObj.isMobile)
                        displacementImageObj.goIn(currentSlide);
                }
                TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                    y: -10,
                    opacity: 0
                })
                TweenMax.staggerTo($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), 0.8, {
                    y: 0,
                    opacity: 1,
                    ease: Circ.easeOut
                }, 0.03, function () {
                    $categoryTextContainer.find(".category-text-wrapper").eq(_titleActiveIndex).addClass("active");
                    TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(_titleActiveIndex).find(".line"), {
                        clearProps: "all"
                    });
                });
                $customCursor.removeClass("white tawny ruby rose").addClass(colorClass);
                TweenMax.to($customCursor.find('.inside-line')[0], .8, {
                    strokeDashoffset: cursorTotalLength,
                    onComplete: function () {
                        isAnimatingKeyPressed = false;
                        isScrolling = false;
                        scrollDrag = 0;
                        isPressed = false;
                    }
                });
                $categoryTextWrapperMobile.removeClass("active");
                $categoryTextWrapperMobile.eq(currentSlide).addClass("active");
                if (currentSlide > 0) {
                    TweenMax.to($arrowCursor.filter(".right"), 0.2, {
                        opacity: 1
                    });
                } else if (currentSlide <= 0) {
                    TweenMax.to($arrowCursor.filter(".right"), 0.2, {
                        opacity: 0.3
                    });
                }

                if (currentSlide >= snapPoints.length - 1) {
                    TweenMax.to($arrowCursor.filter(".left"), 0.2, {
                        opacity: 0.3
                    });
                } else {
                    TweenMax.to($arrowCursor.filter(".left"), 0.2, {
                        opacity: 1
                    });
                }

                var url = '?category='+colorClass+'';
                history.pushState({}, {}, url);

            },
            onDragStart: function () {
                growCursor();
                isPressed = true;
                isAnimatingKeyPressed = true;
                isScrolling = true;
                // $draggerTitles.find("li").removeClass("active");
                TweenMax.to($buttonsContainer, 0.25, {
                    clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px " + $buttonsContainer.outerWidth() / 2 + "px)",
                    opacity: 0,
                    ease: Power4.easeOut
                });
                TweenMax.staggerTo($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), 0.8, {
                    y: -10,
                    opacity: 0,
                    ease: Circ.easeOut
                }, 0.03, function () {
                    $categoryTextContainer.find(".category-text-wrapper").removeClass("active");
                    TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                        clearProps: "all"
                    });
                });
                if (!_browserObj.isMobile)
                    displacementImageObj.goOut(currentSlide);
            },
            onDrag: function (e) {
                dragAmount = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, snapPointsTitles[1], 0, _globalViewportW);
                TweenMax.set($draggerTitles, {
                    x: dragAmount
                });

                var circleCursor = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x) - _globalViewportW * currentSlide, cursorTotalLength, 0, 0, _globalViewportW);
                TweenMax.set($customCursor.find('.inside-line')[0], {
                    strokeDashoffset: circleCursor
                });

                var rot = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, -25, 0, _globalViewportW);
                TweenMax.set($draggerCircle, {
                    rotation: rot
                });

                var mob = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x) - _globalViewportW * currentSlide, $buttonsContainerMobile.find("button").outerWidth(), 0, 0, _globalViewportW / 2.5);
                TweenMax.set($buttonsContainerMobile.find("button").eq(currentSlide), {
                    clip: "rect(0px " + mob + "px " + $buttonsContainerMobile.find("button").outerHeight() + "px 0px)"
                });

                for (var i = 0; i < elemsArr.length; i++) {
                    var rotate = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initRotation, elemsArr[i].endRotation, 0, _globalViewportW);
                    var scal = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initScale, elemsArr[i].endScale, 0, _globalViewportW);
                    var move = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, elemsArr[i].endOffset - elemsArr[i].initOffset, 0, _globalViewportW);
                    TweenMax.set(elemsArr[i].elem, {
                        rotation: rotate,
                        scale: scal,
                        x: move * elemsArr[i].friction
                    });
                }

                for (var index = 0; index < 4; index++) {
                    if(dragAmount - $bottleWrapper.find("img").outerWidth()/2 < snapPointsTitles[index] && !arrayTitlesColors[index].active){
                        arrayTitlesColors[index].active = true;
                        $draggerTitles.find("li").removeClass("active");
                        $draggerTitles.find("li").eq(index).addClass("active");
                    } else if(dragAmount - $bottleWrapper.find("img").outerWidth()/2 < snapPointsTitles[index] && arrayTitlesColors[index].active){
                        arrayTitlesColors[index].active = false;
                        $draggerTitles.find("li").removeClass("active");
                        $draggerTitles.find("li").eq(index).addClass("active");
                    }                    
                }

            },
            onDragEnd: function () {
                isPressed = false;
                this.applyBounds({
                    minX: 0,
                    minY: 0,
                    maxX: -_globalViewportW * 3,
                    maxY: 0
                });
                dragAmount = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, snapPointsTitles[1], 0, _globalViewportW);
                TweenMax.set($draggerTitles, {
                    x: dragAmount
                });
            },
            snap: snapPoints
        });

        function growCursor() {
            if (!_browserObj.isMobile) {
                TweenMax.to($dragWord, 1, {
                    scale: 1.6,
                    ease: Expo.easeOut
                });
                TweenMax.to($circleContainer, 1, {
                    scale: 1,
                    ease: Expo.easeOut
                });
                TweenMax.to($customCursor, 1, {
                    scale: 1.2,
                    ease: Expo.easeOut
                });
            }
        }

        function decreaseCursor() {
            if (!_browserObj.isMobile) {
                TweenMax.to($dragWord, 1, {
                    scale: 1,
                    ease: Expo.easeOut
                });
                TweenMax.to($circleContainer, 1, {
                    scale: 1.6,
                    ease: Expo.easeOut
                });
                TweenMax.to($customCursor, 1, {
                    scale: 1,
                    ease: Expo.easeOut
                });
            }
        }

        function categoryTextMobile() {
            var canShowModal = true;
            $categoryTextWrapperMobile.each(function () {
                var $this = $(this),
                    height = $this.outerHeight(true);

                if (height > maxHeightMobile)
                    maxHeightMobile = height;
            });

            TweenMax.set([$categoryTextWrapperMobile, $mobileCategoryTextContainer], {
                width: $categoryTextWrapperMobile.filter(".active").outerWidth(),
                height: maxHeightMobile + 100
            });

            if (!_browserObj.isPhone) {
                $categoryTextWrapperMobile.css({
                    "position": "absolute",
                    "max-width": "calc(100% - 120px)"
                });
            } else {
                $categoryTextWrapperMobile.css({
                    "position": "absolute",
                    "max-width": "calc(100% - 100px)"
                });
            }

            $portfolioDragger.append(
                '<div class="pseudo-circle">' +
                '<span>i</span>' +
                '</div>'
            );

            $(".pseudo-circle").css({
                "top": $mobileCategoryTextContainer.find(".circle").offset().top,
                "left": $mobileCategoryTextContainer.find(".circle").offset().left
            });

            $(".pseudo-circle").on("click", function () {
                if (canShowModal) {
                    canShowModal = false;
                    $mobileCategoryTextContainer.addClass("active");
                    $(".pseudo-circle").addClass("filled");
                } else {
                    canShowModal = true;
                    $mobileCategoryTextContainer.removeClass("active");
                    $(".pseudo-circle").removeClass("filled");
                }
            });

            $draggerWrapper.on("click", function () {
                if ($mobileCategoryTextContainer.hasClass("active")) {
                    $mobileCategoryTextContainer.removeClass("active");
                    $(".pseudo-circle").removeClass("filled");
                    canShowModal = true;
                }
            });
        }

        $jsDragElement.each(function () {
            var $this = $(this),
                obj = {};

            obj.elem = $this;
            obj.initOffset = $this.offset().left;
            obj.endOffset = $this.offset().left + 100;
            obj.friction = Number($this.attr("data-friction"));
            if ($this.attr("data-initScale"))
                obj.initScale = Number($this.attr("data-initScale"));
            else
                obj.initScale = 1;

            if ($this.attr("data-initRotation"))
                obj.initRotation = Number($this.attr("data-initRotation"));
            else
                obj.initRotation = 0;

            if ($this.attr("data-rotation"))
                obj.endRotation = Number($this.attr("data-endRotation"));
            else
                obj.endRotation = 0;

            if ($this.attr("data-endScale"))
                obj.endScale = Number($this.attr("data-endScale"));
            else
                obj.endScale = 1;
            elemsArr.push(obj);
        });

        function prev() {
            console.log("entrou prev");
            if (currentSlide > 0) {
                isAnimatingKeyPressed = true;
                isScrolling = true;
                TweenMax.to($arrowCursor.filter(".left"), 0.2, {
                    opacity: 1
                });
                TweenMax.to($draggerWrapper, 2, {
                    x: snapPoints[currentSlide - 1],
                    ease: Expo.easeOut,
                    onUpdate: getValues,
                    onComplete: function () {
                        currentSlide--;

                        TweenMax.to($buttonsContainer, 0.6, {
                            clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px 0px)",
                            ease: Circ.easeOut
                        });
                        TweenMax.to($buttonsContainer, 0.6, {
                            clip: "rect(0px " + $buttonsContainer.outerWidth() + "px " + $buttonsContainer.outerHeight() + "px 0px)",
                            delay: 0.2,
                            ease: Circ.easeOut
                        });

                        $draggerTitles.find("li").eq(currentSlide).addClass("active");
                        var colorClass = slugify($draggerWrapper.find("li").eq(currentSlide).attr("data-class").toLowerCase());
                        var color = $draggerWrapper.find("li").eq(currentSlide).attr("data-color");
                        var href = $draggerWrapper.find("li").eq(currentSlide).attr("data-href");
                        $buttonsContainer.find("a").attr("href", href);
                        $hitZone.attr("href", href);
                        $buttonsContainer.removeClass("white tawny ruby rose").addClass(color);
                        $buttonsContainer.find(".category-button").html(
                            colorClass +
                            '<span class="arrow"></span>'
                        );

                        $dragWord.removeClass("white tawny ruby rose").addClass(color);
                        $arrowCursor.removeClass("white tawny ruby rose").addClass(color);

                        TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                            y: -10,
                            opacity: 0
                        })
                        TweenMax.staggerTo($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), 0.8, {
                            y: 0,
                            opacity: 1,
                            ease: Circ.easeOut
                        }, 0.03, function () {
                            $categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).addClass("active");
                            TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                                clearProps: "all"
                            });
                        });
                        $customCursor.removeClass("white tawny ruby rose").addClass(colorClass);
                        TweenMax.to($customCursor.find('.inside-line')[0], .8, {
                            strokeDashoffset: cursorTotalLength,
                            onComplete: function () {
                                isAnimatingKeyPressed = false;
                                isScrolling = false;
                                scrollDrag = 0;
                            }
                        });

                        displacementImageObj.goIn(currentSlide);
                        _titleActiveIndex = currentSlide;
                        var url = '?category='+colorClass+'';
                        history.pushState({}, {}, url);        
                    }
                })

                TweenMax.staggerTo($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), 0.8, {
                    y: -10,
                    opacity: 0,
                    ease: Circ.easeOut
                }, 0.03, function () {
                    $categoryTextContainer.find(".category-text-wrapper").removeClass("active");
                    TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                        clearProps: "all"
                    });
                });

                $draggerTitles.find("li").removeClass("active");
                TweenMax.to($buttonsContainer, 0.25, {
                    clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px " + $buttonsContainer.outerWidth() / 2 + "px)",
                    ease: Power4.easeOut
                });
                displacementImageObj.goOut(currentSlide);
                if (currentSlide <= snapPoints.length - 1) {
                    TweenMax.to($arrowCursor.filter(".left"), 0.2, {
                        opacity: 1
                    });
                }
                if (currentSlide <= 1) {
                    TweenMax.to($arrowCursor.filter(".right"), 0.2, {
                        opacity: 0.3
                    });
                }
            }
        }

        function next() {
            console.log("entrou next");
            if (currentSlide < snapPoints.length - 1) {
                isAnimatingKeyPressed = true;
                isScrolling = true;
                TweenMax.to($arrowCursor.filter(".right"), 0.2, {
                    opacity: 1
                });
                TweenMax.to($draggerWrapper, 2, {
                    x: snapPoints[currentSlide + 1],
                    ease: Expo.easeOut,
                    onUpdate: getValues,
                    onComplete: function () {
                        currentSlide++;

                        TweenMax.to($buttonsContainer, 0.6, {
                            clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px 0px)",
                            ease: Circ.easeOut
                        });
                        TweenMax.to($buttonsContainer, 0.6, {
                            clip: "rect(0px " + $buttonsContainer.outerWidth() + "px " + $buttonsContainer.outerHeight() + "px 0px)",
                            delay: 0.2,
                            ease: Circ.easeOut
                        });

                        $draggerTitles.find("li").eq(currentSlide).addClass("active");
                        var colorClass = slugify($draggerWrapper.find("li").eq(currentSlide).attr("data-class").toLowerCase());
                        var color = $draggerWrapper.find("li").eq(currentSlide).attr("data-color");
                        var href = $draggerWrapper.find("li").eq(currentSlide).attr("data-href");
                        $buttonsContainer.find("a").attr("href", href);
                        $hitZone.attr("href", href);
                        $buttonsContainer.removeClass("white tawny ruby rose").addClass(color);
                        $buttonsContainer.find(".category-button").html(
                            colorClass +
                            '<span class="arrow"></span>'
                        );

                        $dragWord.removeClass("white tawny ruby rose").addClass(color);
                        $arrowCursor.removeClass("white tawny ruby rose").addClass(color);

                        TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                            y: -10,
                            opacity: 0
                        })
                        TweenMax.staggerTo($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), 0.8, {
                            y: 0,
                            opacity: 1,
                            ease: Circ.easeOut
                        }, 0.03, function () {
                            $categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).addClass("active");
                            TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                                clearProps: "all"
                            });
                        });
                        $customCursor.removeClass("white tawny ruby rose").addClass(colorClass);
                        TweenMax.to($customCursor.find('.inside-line')[0], .8, {
                            strokeDashoffset: cursorTotalLength,
                            onComplete: function () {
                                isAnimatingKeyPressed = false;
                                isScrolling = false;
                                scrollDrag = 0;
                            }
                        });

                        displacementImageObj.goIn(currentSlide);
                        _titleActiveIndex = currentSlide;
                        var url = '?category='+colorClass+'';
                        history.pushState({}, {}, url);
                    }
                })

                TweenMax.staggerTo($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), 0.8, {
                    y: -10,
                    opacity: 0,
                    ease: Circ.easeOut
                }, 0.03, function () {
                    $categoryTextContainer.find(".category-text-wrapper").removeClass("active");
                    TweenMax.set($categoryTextContainer.find(".category-text-wrapper").eq(currentSlide).find(".line"), {
                        clearProps: "all"
                    });
                });

                $draggerTitles.find("li").removeClass("active");
                TweenMax.to($buttonsContainer, 0.25, {
                    clip: "rect(0px " + $buttonsContainer.outerWidth() / 2 + "px " + $buttonsContainer.outerHeight() + "px " + $buttonsContainer.outerWidth() / 2 + "px)",
                    ease: Power4.easeOut
                });
                displacementImageObj.goOut(currentSlide);
                if (currentSlide === snapPoints.length - 2) {
                    TweenMax.to($arrowCursor.filter(".left"), 0.2, {
                        opacity: 0.3
                    });
                }
            }
        }

        function getValues() {
            dragAmount = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, snapPointsTitles[1], 0, _globalViewportW);
            TweenMax.set($draggerTitles, {
                x: dragAmount
            });

            var circleCursor = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x) - _globalViewportW * currentSlide, cursorTotalLength, 0, 0, _globalViewportW);
            TweenMax.set($customCursor.find('.inside-line')[0], {
                strokeDashoffset: circleCursor
            });

            var rot = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, -25, 0, _globalViewportW);
            TweenMax.set($draggerCircle, {
                rotation: rot
            });

            var mob = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x) - _globalViewportW * currentSlide, $buttonsContainerMobile.find("button").outerWidth(), 0, 0, _globalViewportW / 2.5);
            TweenMax.set($buttonsContainerMobile.find("button").eq(currentSlide), {
                clip: "rect(0px " + mob + "px " + $buttonsContainerMobile.find("button").outerHeight() + "px 0px)"
            });

            for (var i = 0; i < elemsArr.length; i++) {
                var rotate = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initRotation, elemsArr[i].endRotation, 0, _globalViewportW);
                var scal = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initScale, elemsArr[i].endScale, 0, _globalViewportW);
                var move = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, elemsArr[i].endOffset - elemsArr[i].initOffset, 0, _globalViewportW);
                TweenMax.set(elemsArr[i].elem, {
                    rotation: rotate,
                    scale: scal,
                    x: move * elemsArr[i].friction
                });
            }
        }

        portDrag = Draggable.get($draggerWrapper);
        if(!_browserObj.isMobile){
            portDrag.disable();
        }
        if ($_body.hasClass("js-no-ajax")) {
            if(window.location.search.length != 0){
                var pathUrl = window.location.search.split('=');
                switch(pathUrl[pathUrl.length-1]){
                    case 'white':
                        _titleActiveIndex = 0;
                        break;
                    case 'tawny':
                        _titleActiveIndex = 1;
                        break;
                    case 'ruby':
                        _titleActiveIndex = 2;
                        break;
                    case 'rose':
                        _titleActiveIndex = 3;
                        break;
                }
                for (var index = _titleActiveIndex; index >= 0; index--) {
                    arrayTitlesColors[index].active = true;
                }
            } else {
                var url = '?category=white';
                history.pushState({}, {}, url);
                arrayTitlesColors[0].active = true;
            }

            $draggerTitles.attr("data-movement", snapPointsTitles[_titleActiveIndex]);
            currentSlide = _titleActiveIndex;
            for (var index = _titleActiveIndex-1; index >= 0; index--) {
                TweenMax.set($buttonsContainerMobile.find("button").eq(index), {
                    clip: "rect(0px 0px " + $buttonsContainerMobile.find("button").outerHeight() + "px 0px)"
                });       
            }
        } else {
            var categoryColor;
            switch(_titleActiveIndex){
                case 0:
                    categoryColor = 'white';
                    break;
                case 1:
                    categoryColor = 'tawny';
                    break;
                case 2:
                    categoryColor = 'ruby';
                    break;
                case 3:
                    categoryColor = 'rose';
                    break;
            }

            var url = '?category='+categoryColor+'';
            history.pushState({}, {}, url);

            TweenMax.set($draggerTitles, {
                x: snapPointsTitles[_titleActiveIndex]
            });
        }
        TweenMax.set($draggerWrapper, {
            x: snapPoints[_titleActiveIndex]
        
        });
        for (var i = 0; i < elemsArr.length; i++) {
            var rotate = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initRotation, elemsArr[i].endRotation, 0, _globalViewportW);
            var scal = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), elemsArr[i].initScale, elemsArr[i].endScale, 0, _globalViewportW);
            var move = scaleBetween(Math.abs(document.querySelector(".dragger-wrapper")._gsTransform.x), 0, elemsArr[i].endOffset - elemsArr[i].initOffset, 0, _globalViewportW);
            TweenMax.set(elemsArr[i].elem, {
                rotation: rotate,
                scale: scal,
                x: move * elemsArr[i].friction
            });
        }

        $draggerTitles.find("li").removeClass("active");
        $draggerTitles.find("li").eq(_titleActiveIndex).addClass("active");
        var colorClassInit = slugify($draggerWrapper.find("li").eq(_titleActiveIndex).attr("data-class").toLowerCase());
        var colorInit = $draggerWrapper.find("li").eq(_titleActiveIndex).attr("data-color");
        var hrefInit = $draggerWrapper.find("li").eq(_titleActiveIndex).attr("data-href");
        $buttonsContainer.find("a").attr("href", hrefInit);
        $hitZone.attr("href", hrefInit);
        $buttonsContainer.removeClass("white tawny ruby rose").addClass(colorInit);
        $buttonsContainer.find(".category-button").html(
            colorClassInit +
            '<span class="arrow"></span>'
        );
        $dragWord.removeClass("white tawny ruby rose").addClass(colorInit);
        $arrowCursor.removeClass("white tawny ruby rose").addClass(colorInit);
        portDrag.update();
    }

    this.resize = function () {}

    this.kill = function () {
        $_window.off("resize.portfolio");
        $_window.off("mousemove.portfolio");
        cancelAnimationFrame(raf_cursor);
        $_window.off('keydown.portfolioDragger');
        VirtualScroll.off();
        portDrag.kill();
        setTimeout(function () {
            displacementImageObj.kill();
        }, 1000)
        if (draggerHelperMobile)
            draggerHelperMobile.destroy();
    }
}