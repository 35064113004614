function beforeAfter(){
    var $beforeAfter = $(".before-after"),
        $beforeImage = $(".before-calem-image"),
        $beforeAfterHandlerWrapper = $(".before-after-handler-wrapper"),
        $beforeAfterHandler = $(".before-after-handler");

    var imageWidth = $beforeImage.outerWidth(),
        imageHeight = $beforeImage.outerHeight(),
        imageOffsetLeft = $beforeImage.offset().left,
        imageOffsetTop = $beforeImage.offset().top,
        handlerHeight = $beforeAfterHandler.outerHeight(),
        handlerHalfWidth = $beforeAfterHandlerWrapper.outerWidth()/2,
        raf_loop_beforeAfter = null,
        mouseX = 0,
        mouseY = 0,
        posX = 0,
        posY = 0,
        isInViewport = false,
        dragMobile = null;

    this.init = function(){        
        if(!_browserObj.isMobile){
            TweenMax.set($beforeImage, {clip: "rect(0px 0px "+imageHeight+"px 0px)"});
            TweenMax.set(".line-top", {height: imageHeight/2 - 20});
            TweenMax.set(".line-bottom", {height: imageHeight/2 - 20});
    
            $beforeAfter.on("mousemove.beforeAfter", function(e){
                mouseX = Math.floor(e.clientX - imageOffsetLeft);
                mouseY = Math.floor(e.clientY - $beforeImage.offset().top);
            });
            $_window.on("resize.beforeAfter", $.debounce(500, resizeBeforeAfter));
        } else {
            TweenMax.set($beforeImage, {clip: "rect(0px 0px "+imageHeight+"px 0px)"});
            Draggable.create($beforeAfterHandlerWrapper, {
                type: "x",
                throwProps: false,
                onDrag: function(){
                    TweenMax.set($beforeImage, {clip: "rect(0px "+Math.abs(document.querySelector(".before-after-handler-wrapper")._gsTransform.x)+"px "+imageHeight+"px 0px)"});
                },
                onDragStart: function(){
                    disableBodyScroll(true);
                },  
                onDragEnd: function(){
                    this.applyBounds({minX: 0, minY: 0, maxX: imageWidth, maxY: 0});
                    disableBodyScroll(false);
                },
            });
            TweenMax.to($beforeAfterHandlerWrapper, 1, {x: imageWidth/2, ease: Power2.easeOut})
            TweenMax.to($beforeImage,1,  {clip: "rect(0px "+imageWidth/2+"px "+imageHeight+"px 0px)", ease: Power2.easeOut});
            dragMobile = Draggable.get($beforeAfterHandlerWrapper);
            dragMobile.update();
        }
    }

    this.inViewport = function(scroll){
        if(scroll + _globalViewportH > imageOffsetTop && !isInViewport){
            TweenMax.to($beforeImage, 0.8, {clip: "rect(0px "+imageWidth/2+"px "+imageHeight+"px 0px)", ease: Circ.easeOut});
            TweenMax.to($beforeAfterHandler, 0.8, {y: imageHeight/2 - handlerHeight/2, ease: Circ.easeOut});
            TweenMax.to($beforeAfterHandlerWrapper, 0.8, {x: imageWidth/2 - handlerHalfWidth, ease: Circ.easeOut});
            isInViewport = true;  
            beforeAfterLoop();
        }         
    }

    function beforeAfterLoop(){
        if(mouseX == 0)
            posX = imageWidth/2;
        else 
            posX = mouseX;

        if(mouseY == 0)
            posY = imageHeight/2 - handlerHeight;
        else 
            posY = mouseY;

        if(mouseX > 0 && mouseX <= imageWidth){
            TweenMax.set($beforeImage, {clip: "rect(0px "+posX+"px "+imageHeight+"px 0px)"});
            TweenMax.set($beforeAfterHandlerWrapper, {x: posX-handlerHalfWidth});
        }
        if(mouseY > 0 && mouseY <= imageHeight - handlerHeight - handlerHeight/2){
            TweenMax.set($beforeAfterHandler, {y: mouseY});
            TweenMax.set(".line-top", {height: posY});
            TweenMax.set(".line-bottom", {height: imageHeight - handlerHeight - posY - 20});
        }

        raf_loop_beforeAfter = _rAF_loop(beforeAfterLoop);
    }

    function resizeBeforeAfter(){
        imageWidth = $beforeImage.outerWidth();
        imageHeight = $beforeImage.outerHeight();
        imageOffsetLeft = $beforeImage.offset().left;
        imageOffsetTop = $beforeImage.offset().top;
    }

    this.kill = function(){
        $beforeImage.off("mousemove.beforeAfter");
        cancelAnimationFrame(raf_loop_beforeAfter);
        $_window.off("resize.beforeAfter");
        if(_browserObj.isMobile)
            dragMobile.kill();
    }
}