function homeEntrance() {
    var $bigTitle = $(".page-header .big-titles"),
        $subTitle = $(".page-header .subtitle"),
        $destaquesSliderWrapper = $(".destaques-slider-wrapper"),
        $bigSquareHome = $(".big-square-home"),
        $bigDownCircle = $(".page-content .big-down-circle"),
        $arrowDestaques = $(".arrow-destaques"),
        $antonioAlvesImage = $(".page-content .antonio-alves-image"),
        $languageContainer = $(".languages-container");

    var bigTitleChars = null,
        bigTitleSplitted = null,
        subtitleTitleChars = null,
        subtitleTitleSplitted = null,
        yValue = 0,
        timelineAnimateIn = new TimelineMax({
            paused: true
        }),
        timelineAnimateFromIntro = new TimelineMax({
            paused: true
        });

    this.init = function () {
        bigTitleSplitted = new SplitText($bigTitle, {
            type: "chars"
        });
        bigTitleChars = bigTitleSplitted.chars;

        subtitleTitleSplitted = new SplitText($subTitle, {
            type: "chars"
        });
        subtitleTitleChars = subtitleTitleSplitted.chars;

        TweenMax.set($(bigTitleChars), {
            rotationX: 45,
            opacity: 0
        });
        TweenMax.set($(subtitleTitleChars), {
            rotationX: -100,
            opacity: 0
        });

        if (!_browserObj.isMobile) {
            yValue = "0";
        } else {
            yValue = "-50%";
        }
    }

    this.animateIn = function (f) {
        TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.2, {
            fill: "#ffffff",
            ease: Circ.easeOut
        });
        timelineAnimateIn.to($bigSquareHome, 0.6, {
                scaleY: 1,
                ease: Circ.easeInOut
            })
            .to($bigDownCircle, 0.7, {
                scale: 1,
                ease: Circ.easeOut
            })
            .to($antonioAlvesImage, 1, {
                opacity: 1,
                rotation: 0,
                scale: 1,
                ease: Circ.easeOut
            })
            .to($destaquesSliderWrapper.find("li"), 1, {
                y: yValue,
                ease: Circ.easeOut
            }, "1.5")
            .to($arrowDestaques, 0.6, {
                x: 0,
                opacity: .4,
                ease: Circ.easeOut,
                onComplete: function () {
                    TweenMax.set($arrowDestaques, {
                        clearProps: "opacity"
                    });
                    $arrowDestaques.addClass("active");
                }
            }, "1.8")
            .staggerTo($(bigTitleChars), 3, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05, "0.6")
            .staggerTo($(subtitleTitleChars), 1, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut,
                onComplete: function () {
                    f();
                }
            }, 0.05, "0.6");

        timelineAnimateIn.play();
    }

    this.animateFromIntro = function (f) {
        TweenMax.set($bigSquareHome, {
            scaleY: 1
        });
        TweenMax.set($bigDownCircle, {
            scale: 1
        });
        TweenMax.set($antonioAlvesImage, {
            opacity: 1,
            rotation: 0,
            scale: 1
        });

        timelineAnimateFromIntro.to($(".big-square-container-intro"), 0.8, {
                height: $bigSquareHome.height(),
                ease: Circ.easeOut
            })
            .to(".intro .chars", 0.6, {
                opacity: 0,
                ease: Circ.easeOut
            }, "0")
            .to($(".intro-button"), 0.6, {
                y: 200,
                ease: Circ.easeOut
            }, "0.2")
            .to($(".policy"), 0.6, {
                y: 200,
                ease: Circ.easeOut,
                onComplete: function () {
                    f();
                    TweenMax.set($(".intro"), {
                        autoAlpha: 0
                    });
                    TweenMax.staggerTo($(bigTitleChars), 3, {
                        rotationX: 0,
                        opacity: 1,
                        ease: Circ.easeOut
                    }, 0.05);
                    TweenMax.staggerTo($(subtitleTitleChars), 1, {
                        rotationX: 0,
                        opacity: 1,
                        ease: Circ.easeOut
                    }, 0.05);
                    TweenMax.to($destaquesSliderWrapper.find("li"), 1, {
                        y: yValue,
                        ease: Circ.easeOut,
                        delay: 1.5
                    });
                    TweenMax.to($arrowDestaques, 0.6, {
                        x: 0,
                        opacity: .4,
                        ease: Circ.easeOut,
                        delay: 1.8,
                        onComplete: function () {
                            TweenMax.set($arrowDestaques, {
                                clearProps: "opacity"
                            });
                            $arrowDestaques.addClass("active");
                        }
                    });
                }
            }, "0")


        timelineAnimateFromIntro.play();
    }
}