var portfolioTransition = Barba.BaseTransition.extend({
    start: function () {

        $_body.addClass("js-loading-page");

        // As soon the loading is finished and the old page is faded out, let's fade the new page
        Promise.all([this.newContainerReady, this.newContainerLoading])
            .then(this.fadeOut.bind(this))
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        var _this = this,
            titlesArrayLoading = [];

        return new Promise(function (resolve, reject) {

            var mouseX, mouseY, raf_loading;
            if(!_browserObj.isMobile){
                TweenMax.set(".loading-svg", {autoAlpha: 1, scale: 1});
            } else {
                TweenMax.set(".loading-svg", {
                    autoAlpha: 1, 
                    scale: 1, 
                    delay: 0.5,
                    y: (_globalViewportH - $(".loading-svg").outerHeight())/2 + $(_this.newContainer).find(".dragger-titles").outerHeight()/2
                });
            }
            TweenMax.to(".loading-svg", 1, {rotation: "+=60", repeat: -1, ease: Linear.easeNone});
            if(!_browserObj.isMobile){
              $_window.on("mousemove.loading", function (e) {
                mouseX = e.clientX - $(".loading-svg").outerWidth() / 2;
                mouseY = e.clientY - $(".loading-svg").outerHeight() / 2;
              });
        
              loadingLoop();
              TweenMax.set(".loading-svg", {x: (window.innerWidth - $(".loading-svg").outerWidth()) / 2, y: (window.innerHeight - $(".loading-svg").outerHeight()) / 2})
        
              function loadingLoop() {
                TweenMax.to(".loading-svg", .6, {
                    x: mouseX,
                    y: mouseY,
                    ease: Circ.easeOut
                });
                raf_loading = _rAF_loop(loadingLoop);
              } 
            }

            $(".main-logo").removeClass("white-version");
            for (var i = 0; i < $(_this.newContainer).find(".dragger-titles").find("li").length; i++) {
                titlesArrayLoading.push(-($(_this.newContainer).find(".bottle-wrapper img").outerWidth() + 100) * i);
            }

            $(_this.newContainer).find(".category-text-wrapper.active").removeClass("active");
            $(_this.newContainer).find(".category-text-wrapper").eq(_titleActiveIndex).addClass("active");
            TweenMax.set($(_this.newContainer).find(".category-text-container .category-text-wrapper.active p"), {
                y: 10,
                opacity: 0
            });

            TweenMax.set($(_this.newContainer).find(".dragger-circle"), {
                rotation: 25
            });

            TweenMax.set($(_this.newContainer).find(".dragger-wrapper figure"), {
                opacity: 0
            });
            TweenMax.set($(_this.newContainer).find(".separator"), {
                scaleX: 0
            });
            $(_this.newContainer).find(".lines path").each(function () {
                var $this = $(this)[0];
                TweenMax.set($this, {
                    strokeDasharray: $this.getTotalLength(),
                    strokeDashoffset: $this.getTotalLength()
                });
            });

            $("#header-main").css("pointer-events", "none");
            TweenMax.set([$(".loading-layout h3"), $(".loading-layout h2")], {
                autoAlpha: 0
            });
            TweenMax.set($(".loading-layout .loading-titles-container"), {
                autoAlpha: 1
            });

            $(".loading-layout .loading-titles-container").find("li").each(function (key) {
                var $this = $(this);
                $this.css({
                    "width": $(_this.newContainer).find(".bottle-wrapper img").outerWidth(),
                    "margin-right": "100px"
                });
            });

            $(".loading-layout .loading-titles-container .loading-titles").css("left", (_globalViewportW - $(_this.newContainer).find(".bottle-wrapper img").outerWidth()) / 2);
            TweenMax.set($(".loading-layout .loading-titles-container .loading-titles"), {
                x: titlesArrayLoading[_titleActiveIndex]
            })

            setTimeout(function () {
                $("#header-main").removeClass("header-collapse");
            }, 100);
            $(".header-link.active").removeClass("active");
            $(".loading-layout").addClass("white-version");

            TweenMax.set($(".loading-layout .loading-titles-container"), {
                clearProps: "transform"
            });
            TweenMax.set($(".loading-layout .loading-titles-container"), {
                y: $(_this.newContainer).find(".dragger-titles").position().top
            });

            TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.6, {
                fill: "#06214D",
                ease: Circ.easeOut
            });
            TweenMax.to($(".logo-image"), 0.6, {
                alpha: 1,
                delay: 0.1,
                ease: Expo.easeInOut
            });
            TweenMax.to($("#header-main"), 1, {
                y: 0,
                ease: Expo.easeInOut
            });

            if (_browserObj.isMobile) {
                if ($("nav.show-mobile").hasClass("menu-is-open")) {
                    $(".hamburguer").click();
                }
            }

            if (_browserObj.isPhone) {
                TweenMax.set($(_this.newContainer).find(".buttons-container-mobile"), {
                    autoAlpha: 0
                });
            }

            TweenMax.to(".loading-layout", 1, {
                clip: "rect(0px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                ease: Expo.easeInOut,
                onComplete: function () {
                    $_window.scrollTop(0);
                    TweenMax.set(_this.oldContainer, {
                        autoAlpha: 0,
                        onComplete: function () {
                            TweenMax.to(".loading-svg", 0.2, {opacity: 0, scale: 0.9, ease: Expo.easeOut});
                            TweenMax.set($(_this.oldContainer).find(".page-current"), {
                                clearProps: "transform"
                            });
                            TweenMax.set(_this.oldContainer, {
                                display: 'none'
                            });
                            resolve();
                            if(!_browserObj.isMobile){
                                $_window.off("mousemove.loading");
                                cancelAnimationFrame(raf_loading);
                            }
                            setTimeout(function () {
                                if(_browserObj.isPhone){
                                    for (var index = 0; index < $(_this.newContainer).find(".buttons-container-mobile button").length; index++) {
                                        if(index < _titleActiveIndex){
                                            TweenMax.set($(_this.newContainer).find(".buttons-container-mobile button").eq(index), {
                                                clip: "rect(0px 0px " + $(_this.newContainer).find(".buttons-container-mobile button").outerHeight() + "px 0px)"
                                            });
                                        } else {
                                            TweenMax.set($(_this.newContainer).find(".buttons-container-mobile button").eq(index), {
                                                clip: "rect(0px " + $(_this.newContainer).find(".buttons-container-mobile button").outerWidth() + "px " + $(_this.newContainer).find(".buttons-container-mobile button").outerHeight() + "px 0px)"
                                            });
                                        }
                                    }
                                }
                                TweenMax.to(".loading-layout", 1, {
                                    clip: "rect(" + $('.loading-layout').outerHeight() + "px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                                    ease: Expo.easeInOut,
                                    onComplete: function () {
                                        $(_this.newContainer).find(".lines path").each(function () {
                                            var $this = $(this)[0];
                                            TweenMax.to($this, 6, {
                                                strokeDashoffset: 0,
                                                ease: Expo.easeOut
                                            });
                                        })
                                        TweenMax.to($(_this.newContainer).find(".custom-cursor"), 1, {
                                            opacity: 1,
                                            ease: Expo.easeOut
                                        });
                                        TweenMax.staggerTo($(_this.newContainer).find(".dragger-wrapper figure"), 1, {
                                            opacity: 1
                                        }, 0.1);

                                        if (_browserObj.isPhone) {
                                            TweenMax.to($(_this.newContainer).find(".buttons-container-mobile"), 1, {
                                                autoAlpha: 1,
                                                ease: Expo.easeOut
                                            });
                                        }
                                        if(_browserObj.isMobile){
                                            TweenMax.to($(_this.newContainer).find(".pseudo-circle"), 1, {
                                                opacity: 1,
                                                delay: 1, 
                                                ease: Expo.easeOut
                                            });
                                        }

                                        TweenMax.to($(_this.newContainer).find(".dragger-circle"), 1.4, {
                                            rotation: -25 * _titleActiveIndex,
                                            ease: Expo.easeOut,
                                            onComplete: function () {
                                                TweenMax.to($(_this.newContainer).find(".separator"), 1, {
                                                    scaleX: 1,
                                                    ease: Expo.easeOut
                                                });
                                                TweenMax.staggerTo($(_this.newContainer).find(".category-text-wrapper.active p"), 0.8, {
                                                    y: 0,
                                                    opacity: 1,
                                                    ease: Circ.easeOut
                                                }, 0.05);
                                                TweenMax.to($(_this.newContainer).find(".buttons-container"), 0.6, {
                                                    clip: "rect(0px " + $(_this.newContainer).find(".buttons-container").outerWidth() + "px " + $(_this.newContainer).find(".buttons-container").outerHeight() + "px 0px)",
                                                    ease: Circ.easeOut
                                                });
                                            }
                                        });
                                        $(".loading-layout").removeClass("white-version");
                                        TweenMax.set($("#header-main .main-logo .logo-type svg path"), {
                                            clearProps: "fill"
                                        });
                                        TweenMax.set(".loading-layout", {
                                            clip: "rect(0px " + $('.loading-layout').outerWidth() + "px 0px 0px)"
                                        })
                                        TweenMax.set([$(".loading-layout .loading-titles-container"), $(".loading-layout .loading-titles-container").find("li")], {
                                            clearProps: "all"
                                        });
                                        titlesArray = [];
                                        maxWidth = 0;
                                        TweenMax.set([$(".loading-layout h3"), $(".loading-layout h2")], {
                                            clearProps: "all"
                                        });
                                        $("#header-main").css("pointer-events", "all");
                                    }
                                });
                            }, 200);
                        }
                    })
                }
            })
        });
    },

    fadeIn: function () {
        var _this = this;
        TweenMax.fromTo(_this.newContainer, .2, {
            autoAlpha: 0
        }, {
            autoAlpha: 1,
            onComplete: function () {
                _this.done();
                $_body.removeClass("js-loading-page");
                manageBodyClasses(_this.newContainer, _this.oldContainer)
            }
        });
    }
});