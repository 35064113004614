function termsAndServicesEntrance() {
    var $subTitle = $(".page-header .subtitle"),
        $bigDownCircle = $(".intro .big-down-circle"),
        $antonioAlvesImage = $(".intro .antonio-alves-image"),
        $languageContainer = $(".languages-container");

    var subtitleTitleChars = null,
        subtitleTitleSplitted = null,
        timelineAnimateIn = new TimelineMax({
            paused: true
        }),
        timelineAnimateFromIntro = new TimelineMax({
            paused: true
        });

    this.init = function () {
        subtitleTitleSplitted = new SplitText($subTitle, {
            type: "words, chars"
        });
        subtitleTitleChars = subtitleTitleSplitted.chars;

        TweenMax.set($(subtitleTitleChars), {
            rotationX: -100,
            opacity: 0
        });
    }

    this.animateIn = function (f) {
        timelineAnimateIn.staggerTo($(subtitleTitleChars), 1, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut,
                onComplete: function () {
                    f();
                }
            }, 0.025)
            // .to($languageContainer, 1, {
            //     x: 0,
            //     ease: Circ.easeOut
            // }, "0.5")

        timelineAnimateIn.play();
    }

    this.animateFromIntro = function (f) {
        timelineAnimateFromIntro.to($(".big-square-container-intro"), .6, {
                height: _globalViewportH,
                ease: Circ.easeOut,
                onComplete: function () {
                    $(".main-logo").removeClass("white-version");
                    f();
                }
            }, "0.1")
            .to($(".big-square-container-intro .big-square"), .6, {
                scaleY: 0,
                ease: Circ.easeInOut
            })
            .to(".intro .chars", .6, {
                opacity: 0,
                ease: Circ.easeOut
            }, "0")
            .to($bigDownCircle, 0.7, {
                scale: 0,
                ease: Circ.easeOut
            }, "0.2")
            .to($antonioAlvesImage, .6, {
                opacity: 0,
                rotation: -15,
                scale: .8,
                ease: Circ.easeOut
            }, "0")
            .to($(".intro-button"), .6, {
                y: 200,
                ease: Circ.easeOut
            }, "0.2")
            .to($(".policy"), 1.2, {
                y: 200,
                ease: Circ.easeOut,
                onComplete: function () {
                    TweenMax.set($(".intro"), {
                        autoAlpha: 0
                    });
                    timelineAnimateIn.play();
                }
            }, "0")

        timelineAnimateFromIntro.play();
    }
}