function buroParallaxMouseMoveOver() {
  var elements = document.querySelectorAll('[data-parallaxMoveOver]');
  var parallax_array = [];
  var mouseX = 0;
  var mouseY = 0;
  var mouseXAbs = 0;
  var mouseYAbs = 0;
  var mouseMoveOverLoop_id = -1;

  var init = function() {
    elements.forEach(function(item, i){
      var elem = {};

      elem.element = item;
      elem.close = item.querySelectorAll('[data-parallaxMoveOverClose]');
      elem.away = item.querySelectorAll('[data-parallaxMoveOverAway]');
      elem.offsetY = item.getBoundingClientRect().top;
      elem.offsetX = item.getBoundingClientRect().left;
      elem.height = item.offsetHeight;
      elem.width = item.offsetWidth;
      elem.inView = false;
      elem.isOver = false;
 
      TweenMax.set([elem.close, elem.away], { transformStyle: "preserve-3d" });
      parallax_array.push(elem);
    });

    document.addEventListener("mousemove", mouseMoveFunc);
    window.addEventListener('resize', resize);

    updateInView(0);
    mouseMoveOverLoop_id = _rAF_loop(update);
  }

  var update = function() {
    mouseMoveOverLoop_id = _rAF_loop(update);
 
    for(var i=0,len=parallax_array.length; i<len; i++) {
      
      if(mouseXAbs > parallax_array[i].offsetX && mouseXAbs < parallax_array[i].offsetX + parallax_array[i].width && mouseYAbs > parallax_array[i].offsetY - _buroScroll.getStatus().scrollVal && mouseYAbs < parallax_array[i].offsetY + parallax_array[i].height - _buroScroll.getStatus().scrollVal) {
        parallax_array[i].isOver = true;
        
        var localX = mouseX - parallax_array[i].offsetX;
        var localY = mouseY + _buroScroll.getStatus().scrollVal - parallax_array[i].offsetY;

        TweenMax.to(parallax_array[i].close, 2, {
          rotationY: -0.03 * localX*.1,
          rotationX: 0.03 * localY*.1,
          transformPerspective: 500,
          transformOrigin: "center center -400",
          ease: Expo.easeOut
        });
        TweenMax.to(parallax_array[i].away, 2, {
          rotationY: -0.03 * localX*.1,
          rotationX: 0.03 * localY*.1,
          transformPerspective: 500,
          transformOrigin: "center center -200",
          ease: Expo.easeOut
        });
        
      }
      else {
        parallax_array[i].isOver = false;

        TweenMax.to(parallax_array[i].close, 2, {
          rotationY:0,
          rotationX: 0,
          transformPerspective: 500,
          transformOrigin: "center center -400",
          ease: Expo.easeOut
        });
        TweenMax.to(parallax_array[i].away, 2, {
          rotationY: 0,
          rotationX: 0,
          transformPerspective: 500,
          transformOrigin: "center center -200",
          ease: Expo.easeOut
        });
      }
    }
  }

  var updateInView = function(scrollVal) {
    for(var i=0,len=parallax_array.length; i<len; i++) {
      if(scrollVal + _globalViewportH > parallax_array[i].offsetY && scrollVal + _globalViewportH < parallax_array[i].offsetY + parallax_array[i].height + _globalViewportH) {
        parallax_array[i].inView = true;
      }
    }
  }

  var mouseMoveFunc = function(e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
    mouseXAbs = e.pageX;
    mouseYAbs = e.pageY;
  }

  

  var mouseLeaveFunc = function(e) {
    
  }

  var resize = function() {
    parallax_array.forEach(function(item, i){
      item.offsetY = item.element.getBoundingClientRect().top + _buroScroll.getStatus().scrollVal;
      item.offsetX = item.element.getBoundingClientRect().left;
      item.height = item.element.offsetHeight;
      item.width = item.element.offsetWidth;
      item.inView = false;
      item.isOver = false;
    });
  }

  var kill = function() {
    document.removeEventListener("mousemove", mouseMoveFunc);
    window.removeEventListener('resize', resize);
    cancelAnimationFrame(mouseMoveOverLoop_id);
  }
  return {
    init: init,
    update: update,
    updateInView: updateInView,
    kill: kill
  }
}