function wineAdviceEntrance() {
    var $bigTitle = $(".page-header .big-titles"),
        $subTitle = $(".page-header .subtitle"),
        $wineAdviceFiltersContainer = $(".wine-advice-filters-container"),
        $wineAdviceFilters = $(".wine-advice-filters"),
        $adviceItem = $(".advice-item"),
        $bigDownCircle = $(".intro .big-down-circle"),
        $antonioAlvesImage = $(".intro .antonio-alves-image"),
        $languageContainer = $(".languages-container");

    var bigTitleChars = null,
        bigTitleSplitted = null,
        subtitleTitleChars = null,
        subtitleTitleSplitted = null,
        timelineAnimateIn = new TimelineMax({
            paused: true
        }),
        timelineAnimateFromIntro = new TimelineMax({
            paused: true
        });

    this.init = function () {
        bigTitleSplitted = new SplitText($bigTitle, {
            type: "words, chars"
        });
        bigTitleChars = bigTitleSplitted.chars;

        subtitleTitleSplitted = new SplitText($subTitle, {
            type: "words, chars"
        });
        subtitleTitleChars = subtitleTitleSplitted.chars;

        $wineAdviceFilters.find("li a").each(function () {
            var $this = $(this),
                filtersChars = null;

            filtersChars = new SplitText($this, {
                type: "words, chars"
            });
            $(filtersChars.chars).addClass("filters-chars");
            TweenMax.set($(filtersChars.chars), {
                rotationX: -90,
                opacity: 0
            });
        });

        TweenMax.set($(bigTitleChars), {
            rotationX: 45,
            opacity: 0
        });
        TweenMax.set($(subtitleTitleChars), {
            rotationX: -100,
            opacity: 0
        });
        TweenMax.set($adviceItem, {
            y: _globalViewportH / 2
        });

        TweenMax.set(".wine-advice-filters-container", {
            autoAlpha: 0
        });
    }

    this.animateIn = function (f) {
        timelineAnimateIn.staggerTo($(bigTitleChars), 3, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05)
            .staggerTo($(subtitleTitleChars), 1, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut,
                onComplete: function () {
                    $wineAdviceFiltersContainer.addClass("active");
                    TweenMax.staggerTo($adviceItem, 1, {
                        y: 0,
                        ease: Expo.easeOut
                    }, 0.05);
                    f();
                }
            }, 0.025, "0")
            .to(".wine-advice-filters-container", 1, {
                autoAlpha: 1,
                ease: Expo.easeOut
            }, "0.6")
            // .to($languageContainer, 1, {
            //     x: 0,
            //     ease: Circ.easeOut
            // }, "0.5")
            .staggerTo(".filters-chars", 1, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05, "0.6")

        timelineAnimateIn.play();
    }

    this.animateFromIntro = function (f) {
        timelineAnimateFromIntro.to($(".big-square-container-intro"), 0.6, {
                height: _globalViewportH,
                ease: Circ.easeOut,
                onComplete: function () {
                    $(".main-logo").removeClass("white-version");
                    f();
                }
            }, "0.1")
            .to($(".big-square-container-intro .big-square"), 0.6, {
                scaleY: 0,
                ease: Circ.easeInOut
            })
            .to(".intro .chars", 0.6, {
                opacity: 0,
                ease: Circ.easeOut
            }, "0")
            .to($bigDownCircle, 0.7, {
                scale: 0,
                ease: Circ.easeOut
            }, "0.2")
            .to($antonioAlvesImage, 0.6, {
                opacity: 0,
                rotation: -15,
                scale: .8,
                ease: Circ.easeOut
            }, "0")
            .to($(".intro-button"), 0.6, {
                y: 200,
                ease: Circ.easeOut
            }, "0.2")
            .to($(".policy"), 1.2, {
                y: 200,
                ease: Circ.easeOut,
                onComplete: function () {
                    TweenMax.set($(".intro"), {
                        autoAlpha: 0
                    });
                    timelineAnimateIn.play();
                }
            }, "0")

        timelineAnimateFromIntro.play();
    }
}