function portfolioSingleEntrance() {
    var $bigDownCircle = $(".intro .big-down-circle"),
        $antonioAlvesImage = $(".intro .antonio-alves-image"),
        $languageContainer = $(".languages-container");

    var timelineAnimateIn = new TimelineMax({
            paused: true
        }),
        timelineAnimateFromIntro = new TimelineMax({
            paused: true
        });

    this.init = function () {
        $(".header-titles-container").addClass("hide-before");
        if ($(".suggestion").length != 0) {
            TweenMax.set(".suggestion", {
                opacity: 0
            });
        }
        if ($(".preserve-icon")) {
            TweenMax.set(".preserve-icon", {
                scale: 0.8,
                opacity: 0
            });
        }
        TweenMax.set(".element", {
            opacity: 0
        });
        TweenMax.set(".page-header p", {
            y: 10,
            opacity: 0
        });

        TweenMax.set(".header-titles", {
            x: _globalViewportW / 1.5
        });
    }

    this.animateIn = function (f) {
        TweenMax.set(".header-titles li", {
            opacity: 1
        });
        timelineAnimateIn.to(".header-titles", 1, {
                x: -(_globalViewportH * 0.62 + 100) * _titleActiveIndex,
                ease: Expo.easeOut,
                onComplete: function () {
                    f();
                    $(".header-titles-container").addClass("remove-opacity");
                    $(".header-titles-container").removeClass("hide-before");
                    if ($(".suggestion").length != 0) {
                        TweenMax.to(".suggestion", 0.8, {
                            opacity: 1,
                            ease: Expo.easeOut
                        });
                    }
                    if ($(".preserve-icon")) {
                        TweenMax.to(".preserve-icon", 0.8, {
                            scale: 1,
                            opacity: 1,
                            ease: Expo.easeOut
                        });
                    }
                }
            }, "0")
            .staggerTo(".page-header p", 1, {
                y: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.1, "0")
            .to(".element", 2, {
                opacity: 1,
                ease: Expo.easeOut
            }, "0")
            // .to($languageContainer, 1, {
            //     x: 0,
            //     ease: Circ.easeOut
            // }, "0.5")
            .staggerTo(".filters-chars", 1, {
                rotationX: 0,
                opacity: 1,
                ease: Circ.easeOut
            }, 0.05, "0.6")

        timelineAnimateIn.play();
    }

    this.animateFromIntro = function (f) {
        timelineAnimateFromIntro.to($(".big-square-container-intro"), .6, {
                height: _globalViewportH,
                ease: Circ.easeOut,
                onComplete: function () {
                    $(".main-logo").removeClass("white-version");
                    f();
                }
            }, "0.1")
            .to($(".big-square-container-intro .big-square"), 0.6, {
                scaleY: 0,
                ease: Circ.easeInOut
            })
            .to(".intro .chars", .6, {
                opacity: 0,
                ease: Circ.easeOut
            }, "0")
            .to($bigDownCircle, 0.7, {
                scale: 0,
                ease: Circ.easeOut
            }, "0.2")
            .to($antonioAlvesImage, .6, {
                opacity: 0,
                rotation: -15,
                scale: .8,
                ease: Circ.easeOut
            }, "0")
            .to($(".intro-button"), 0.6, {
                y: 200,
                ease: Circ.easeOut
            }, "0.2")
            .to($(".policy"), 1.2, {
                y: 200,
                ease: Circ.easeOut,
                onComplete: function () {
                    TweenMax.set($(".intro"), {
                        autoAlpha: 0
                    });
                    timelineAnimateIn.play();
                }
            }, "0")

        timelineAnimateFromIntro.play();
    }
}