function wineAdvicePageFunctions() {

  if($_scrollContentWrapper.attr("data-buroScrollbar") != undefined  && !_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
    _buroScroll = new buroVirtualScroll("body", ".page-current", .5, true);
    _buroScroll.init();

   _page_scroll_listener = function(status) {
        wineAdvicePage_scroll_rAF(status);
    };
    _buroScroll.addListener(_page_scroll_listener)
  } else {
    _scrollRef = function () {
      _raf_loop_id = _rAF_loop(wineAdvicePage_scroll_rAF);
    }
    $_window[0].addEventListener("scroll", _scrollRef, {
      passive: true
    });
  }

  /*DOM variables*/
  var $wineAdviceFiltersContainer = $(".wine-advice-filters-container"),
      $wineAdviceFilters = $(".wine-advice-filters"),
      $clearFilters = $(".clear-filters");

  /*Variables*/
  var lastScroll = 0,
      wineAdviceFiltersObj = new wineAdviceFilters(),
      globalFooterObj = new globalFooter(),
      wineAdviceParallaxElements = buroParallaxScroll(),
      pathArray = window.location.pathname.split('/');
    
  var init = function() {
    
    /*inits*/
    if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
      _buroScroll.blockBuroScroll();
    else
      disableBodyScroll(true);

    if($_body.hasClass("js-no-ajax")){
      _introObj.init(function(){
        if(cookie.get('cookie-age') != undefined){
          $.doTimeout(600, function(){
            wineAdviceEntranceObj.animateIn(function(){
              if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
                _buroScroll.unblockBuroScroll();
                _buroScroll.update();
                wineAdviceParallaxElements.init();
              } else {
                disableBodyScroll(false);
              }
            });
          });
        } else {
          if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
            _buroScroll.unblockBuroScroll();
            _buroScroll.update();
            $.doTimeout(1000, function(){
              _buroScroll.update();
            });
          } else {
            disableBodyScroll(false);
          }
        }
      });
      wineAdviceEntranceObj.init(function(){
        wineAdviceParallaxElements.init();
      });
    } else {
      if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
        _buroScroll.unblockBuroScroll();
        _buroScroll.update();
        wineAdviceParallaxElements.init();
        $.doTimeout(1000, function(){
          _buroScroll.update();
        });
      } else {
        disableBodyScroll(false);
      }
    }

    wineAdviceFiltersObj.init();
    globalFooterObj.init();
    $(".header-link").eq(3).addClass("active");
    $("nav .main-links li").removeClass("disable");
    $("nav .main-links li").eq(3).addClass("disable");
        
    //Events
    initEvents();
  }

  var kill = function() {
    //Kill Events

    setTimeout(function(){
      globalFooterObj.kill();
    }, 1000);
    if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
      _buroScroll.kill();
      wineAdviceParallaxElements.kill();
    } else {
      $_window[0].removeEventListener("scroll", _scrollRef);
      _scrollRef = null;
    }

    cancelAnimationFrame(_raf_loop_id);

  }

  /*page functions*/
  function initEvents() {
  }

  function wineAdviceFilters(){
    this.init = function(){
      var filtersHeight = $wineAdviceFilters.outerHeight(),
          canClick = true,
          isAnimate = false;

      pathArray.pop();
      $wineAdviceFilters.find("li").each(function(){
        var $this = $(this),
            category = $this.attr("data-category");

        if(category === pathArray[pathArray.length-1]){
          $this.addClass("active");
        }
      });

      if(_browserObj.isPhone){
        TweenMax.set($wineAdviceFilters, {height: 0});

        $wineAdviceFiltersContainer.on("click", function(){
          if(canClick && !isAnimate){
            isAnimate = true;
            TweenMax.to($wineAdviceFiltersContainer.find(".arrow"), 1, {rotation: -270, ease: Expo.easeInOut});
            TweenMax.to($wineAdviceFilters, 1, {height: filtersHeight, ease: Expo.easeInOut, onComplete: function(){
              isAnimate = false;
              canClick = false;
            }});
          } else if(!canClick && !isAnimate) {
            isAnimate = true;
            TweenMax.to($wineAdviceFiltersContainer.find(".arrow"), 1, {rotation: -90, ease: Expo.easeInOut});
            TweenMax.to($wineAdviceFilters, 1, {height: 0, ease: Expo.easeInOut, onComplete: function(){
              isAnimate = false;
              canClick = true;
            }});
          }
        });
      }

      if(!_browserObj.isPhone)
        $wineAdviceFilters.find("li").css("width", "calc(100% / "+ ($wineAdviceFilters.find("li").length - 1) +")");
      
      $wineAdviceFilters.find("li").each(function(){
        var $this = $(this);

        $this.find(".clear-filters").css("right", ($this.width() - $this.find("a").width())/2 - $clearFilters.width()*2);
      });

      $clearFilters.on("click", function(e){
        var href = $(".main-links").find("li").last().find("a").attr("href");
            response = Barba.Utils.xhr(href);

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        $wineAdviceFilters.find("li").removeClass("active");
        history.pushState({}, {}, href);

        if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
          _buroScroll.blockBuroScroll();
        else 
          $("html, body").css("overflow", "hidden");

        response.then(function(data) {
          var $data = $(data);

          TweenMax.to(".full-width", 1, {opacity: 0, ease: Circ.easeOut});
          TweenMax.staggerTo(".advice-item.advice-right", 1, {opacity: 0, ease: Circ.easeOut}, 0.1);
          TweenMax.staggerTo(".advice-item.advice-left", 1, {opacity: 0, ease: Circ.easeOut}, 0.1, function(){
            $(".main-wine-advices-list").remove();
            $(".other-wine-advices-list").remove();

            $data.find(".advice-item").each(function(){
              if($(this).hasClass("advice-left")){
                TweenMax.set($(this), { opacity: 0});
              } else {
                TweenMax.set($(this), {opacity: 0});
              }
            });

            $(".page-content").prepend($data.find(".other-wine-advices-list"));
            $(".page-content").prepend($data.find(".main-wine-advices-list"));
            TweenMax.staggerTo(".advice-item", 1, { opacity: 1, ease: Circ.easeInOut}, 0.1, function(){
              if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
                _buroScroll.update();
                _buroScroll.unblockBuroScroll();
                globalFooterObj.reset();
              } else {
                $("html, body").css("overflow", "visible");
              } 
              rewriteUrl()
            });
          });
        });
      });

      $wineAdviceFilters.find("a").on("click", function(e){
        var $this = $(this),
            text = $this.text(),
            href = $this.attr('href'),
            response = Barba.Utils.xhr(href);


          $wineAdviceFilters.find("li").removeClass("active");
          $this.parent().addClass("active");
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          history.pushState({}, {}, href);
          $wineAdviceFiltersContainer.click();
          $wineAdviceFiltersContainer.find("h2").html(text);

          if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
            _buroScroll.blockBuroScroll();
          else 
            $("html, body").css("overflow", "hidden");

          response.then(function(data) {
            var $data = $(data);

            TweenMax.to(".full-width", 1, {opacity: 0, ease: Circ.easeOut});
            TweenMax.staggerTo(".advice-item.advice-right", 1, {opacity: 0, ease: Circ.easeOut}, 0.1);
            TweenMax.staggerTo(".advice-item.advice-left", 1, {opacity: 0, ease: Circ.easeOut}, 0.1, function(){
              $(".main-wine-advices-list").remove();
              $(".other-wine-advices-list").remove();
              
              $data.find(".advice-item").each(function(){
                if($(this).hasClass("advice-left")){
                  TweenMax.set($(this), { opacity: 0});
                } else {
                  TweenMax.set($(this), {opacity: 0});
                }
              });

              $(".page-content").prepend($data.find(".other-wine-advices-list"));
              $(".page-content").prepend($data.find(".main-wine-advices-list"));
              TweenMax.staggerTo(".advice-item", 1, { opacity: 1, ease: Circ.easeInOut}, 0.1, function(){
                if(!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE){
                  _buroScroll.update();
                  _buroScroll.unblockBuroScroll();
                  globalFooterObj.reset();
                } else {
                  $("html, body").css("overflow", "visible");
                } 
                rewriteUrl()
              });
            });
          });
      });
    }
  }

  function wineAdvicePage_scroll_rAF(status) {
    if(!_buroScroll) {
      var status = { scrollVal: window.pageYOffset }

      if(lastScroll - status.scrollVal < 0){
        status.direction = 'down';
      } else if(lastScroll - status.scrollVal > 0){
        status.direction = 'up';
      }

      lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
    }
    
    _globalHeader.animate(status.scrollVal, status.direction);
    globalFooterObj.inViewport(status.scrollVal);
    globalCtaCavesObj.animate(status.scrollVal, $(".page-current").height(), status.direction);

    if(!_browserObj.isMobile)
      wineAdviceParallaxElements.update(status.scrollVal);

  }

  return {
    init: init,
    kill: kill
  }
}

///BARBA INITIALIZER///
var wineAdvicePage = Barba.BaseView.extend({
  namespace: 'wine-advice',
  onEnter: function() {
    
  },
  onEnterCompleted: function() {
      // The Transition has just finished.
      _currentView = wineAdvicePageFunctions();
      _currentView.init();
  },
  onLeave: function() {
      // A new Transition toward a new page has just started.
      _currentView.kill();
  },
  onLeaveCompleted: function() {
      // The Container has just been removed from the DOM.
  }
});


wineAdvicePage.init();