function four0fourPageFunctions() {

    if($_scrollContentWrapper.attr("data-buroScrollbar") != undefined  && !_browserObj.isMobile && !_browserObj.isIE && !_browserObj.isEdge) {
      _buroScroll = new buroVirtualScroll(".scroll-content-wrapper", ".page-current", .3, true);
      _buroScroll.init();
  
      _page_scroll_listener = function(status) {
         four0fourPage_scroll_rAF(status);
       };
       _buroScroll.addListener(_page_scroll_listener)
    }
    else {
      _scrollRef = function() { _raf_loop_id = _rAF_loop(four0fourPage_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
  
    /*Variables*/
    /*DOM variables*/
  
    var init = function() {
        /*inits*/
        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
            _buroScroll.blockBuroScroll();
        else
            disableBodyScroll(true);


        if ($_body.hasClass("js-no-ajax")) {
            _introObj.init(function () {
                if (cookie.get('cookie-age') != undefined) {
                    $.doTimeout(1000, function () {
                        four0fourEntranceObj.animateIn(function () {
                        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                            _buroScroll.unblockBuroScroll();
                            _buroScroll.update();
                        } else {
                            disableBodyScroll(false);
                        }
                        });
                    });
                } else {
                    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                        _buroScroll.unblockBuroScroll();
                        _buroScroll.update();
                        $.doTimeout(1000, function () {
                            _buroScroll.update();
                        });
                    } else {
                        disableBodyScroll(false);
                    }
                }
            });
            four0fourEntranceObj.init();
            } else {
                if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                    _buroScroll.unblockBuroScroll();
                    _buroScroll.update();
                    $.doTimeout(1000, function () {
                        _buroScroll.update();
                    });
                } else {
                    disableBodyScroll(false);
                }
            }
        
        //Events
        initEvents();
    }
  
    var kill = function() {
      //Kill Events
     
      
      cancelAnimationFrame(_raf_loop_id);
      if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
        _buroScroll.kill();
      } else {
        $_window[0].removeEventListener("scroll", _scrollRef);
        _scrollRef = null;
      }
    }
  
   
    
    /*page functions*/
    function initEvents() {
    }
  
    
    
  
    function four0fourPage_scroll_rAF(status) {
      if(!_buroScroll) {
        var status = { scrollVal: window.pageYOffset }
  
        if(lastScroll - status.scrollVal < 0){
          status.direction = 'down';
        } else if(lastScroll - status.scrollVal > 0){
          status.direction = 'up';
        }
  
        lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
      }
  
      if(status.direction == 'up') {
        if(!scrollUp) {
          pointScrollVal = status.scrollVal;
          pointScrollVal = pointScrollVal - 300;
        }
  
        scrollDown = false;
        scrollUp = true;
  
        if(pointScrollVal >= status.scrollVal && !canShow) {
          _mainMenu.smallHeaderShow();
          canShow = true;
        }
      }
  
      if(status.direction == 'up' && status.scrollVal <= 100 && scrollUp) {
        _mainMenu.bigHeader();
      }
      else if(status.direction == 'down' && status.scrollVal >= 150 && !scrollDown){
        scrollDown = true;
        scrollUp = false;
        canShow = false;
        
        _mainMenu.smallHeader();
        _mainMenu.smallHeaderHide();
      }
    }
  
    return {
      init: init,
      kill: kill
    }
  }
  
  ///BARBA INITIALIZER///
  var four0fourPage = Barba.BaseView.extend({
    namespace: 'four0four',
    onEnter: function() {
      
    },
    onEnterCompleted: function() {
      // The Transition has just finished.
      _currentView = four0fourPageFunctions();
      _currentView.init();
      
    },
    onLeave: function() {
        // A new Transition toward a new page has just started.
    },
    onLeaveCompleted: function() {
        // The Container has just been removed from the DOM.
    }
  });
  
  four0fourPage.init();
  