//Check if an object is intersecting an array of other objects 
function buroIntersect(vars) {
  var target = vars.target,
      objs = vars.objects,
      objsBoundings = [],
      overlap = false;

  var init = function() {

    for(var i=0,len=objs.length; i<len; i++) {
      var el = {};

      el.element = objs[i];
      el.boundings = objs[i].getBoundingClientRect();

      objsBoundings.push(el);
    }

    window.addEventListener("resize", resize);
    
  }

  var update = function(scrollVal) {
    overlap = false;
    
    for(var i=0,len=objsBoundings.length; i<len; i++) {
      if(target.getBoundingClientRect().bottom + scrollVal > objsBoundings[i].boundings.top && target.getBoundingClientRect().top + scrollVal < objsBoundings[i].boundings.bottom)
        overlap = true;
    }

    return overlap;
  }
  
  var resize = function() {
    objsBoundings = [];

    for(var i=0,len=objs.length; i<len; i++) {
      var el = {};

      el.element = objs[i];
      el.boundings = objs[i].getBoundingClientRect();

      objsBoundings.push(el);
    }
  }

  var kill = function() {
    window.removeEventListener("resize", resize);
  }

  return {
    init: init,
    update: update,
    kill: kill
  }
}