function portfolioPageFunctions() {

  /*Variables*/
  var portfolioDraggerObj = new portfolioDragger();

  /*DOM variables*/

  var init = function () {
    if (!_browserObj.isMobile) {
      if ($_scrollContentWrapper.attr("data-scrollbar") != undefined && !$_scrollContentWrapper.hasClass("js-scroll")) {
        _customScroll = Scrollbar.init($_scrollContentWrapper[0], {
          speed: 0.8,
          syncCallbacks: true
        });
        $_scrollContentWrapper.addClass("js-scroll");
      }
    } else {
      $(".scroll-content-wrapper").removeAttr("data-scrollbar");
    }
    if (_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function () {
        _raf_loop_id = _rAF_loop(portfolio_scroll_rAF);
      }
      $_window[0].addEventListener("scroll", _scrollRef, {
        passive: true
      });
    } else {
      _page_scroll_listener = function (status) {
        portfolio_scroll_rAF(status);
      };
      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/
    if ($_body.hasClass("js-no-ajax")) {
      $(".preload-image").imagesLoaded(function ($images, $proper, $broken) {
        var fPreload = $(this).imagesLoaded();
        fPreload.always(function () {
          portfolioDraggerObj.init();
          portfolioEntranceObj.init();
          _introObj.init(function () {
            if (cookie.get('cookie-age') != undefined) {
              $.doTimeout(700, function () {
                portfolioEntranceObj.animateIn(function () {
                  if (!_browserObj.isMobile) {
                    portfolioDraggerObj.preload();
                  }
                });
              });
            }
          });
        });
      });
    } else {
      portfolioDraggerObj.init();
      if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
        setTimeout(function () {
          portfolioDraggerObj.preload();
        }, 2000);
      }
    }

    $(".header-link").eq(2).addClass("active-blue");
    $("nav .main-links li").removeClass("disable");
    $("nav .main-links li").eq(2).addClass("disable");

    //Events
    initEvents();
  }

  var kill = function () {
    //Kill Events

    _scrollRef = null;

    cancelAnimationFrame(_raf_main_id);
    portfolioDraggerObj.kill();

    if (_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {}

  function portfolio_scroll_rAF(status) {}

  return {
    init: init,
    kill: kill
  }
}

///BARBA INITIALIZER///
var portfolioPage = Barba.BaseView.extend({
  namespace: 'portfolio',
  onEnter: function () {

  },
  onEnterCompleted: function () {
    // The Transition has just finished.
    _currentView = portfolioPageFunctions();
    _currentView.init();
  },
  onLeave: function () {
    // A new Transition toward a new page has just started.
    _currentView.kill();
  },
  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
  }
});


portfolioPage.init();