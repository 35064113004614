var categoryChangeTransition = Barba.BaseTransition.extend({
    start: function () {

        $_body.addClass("js-loading-page");

        // As soon the loading is finished and the old page is faded out, let's fade the new page
        Promise.all([this.newContainerReady, this.newContainerLoading])
            .then(this.fadeOut.bind(this))
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        var _this = this,
            titlesArray = [],
            delay = 0;

        return new Promise(function (resolve, reject) {

            TweenMax.set($(_this.newContainer).find(".page-header p"), {
                y: 10,
                opacity: 0
            });

            TweenMax.set($(_this.newContainer).find(".line-scroll"), {
                opacity: 1
            });

            if ($(_this.newContainer).find(".preserve-icon")) {
                TweenMax.set($(_this.newContainer).find(".preserve-icon"), {
                    scale: 0.8,
                    opacity: 0
                });
            }

            TweenMax.set($(_this.newContainer).find(".header-titles li"), {
                opacity: 1
            });

            if ($(_this.newContainer).find(".suggestion").length != 0) {
                TweenMax.set($(_this.newContainer).find(".suggestion"), {
                    opacity: 0
                });
            }

            TweenMax.set($(_this.newContainer).find(".element"), {
                opacity: 0
            });

            $(_this.oldContainer).find(".line-scroll").each(function () {
                var $this = $(this)

                TweenMax.to($this.find("path"), 0.5, {
                    strokeDashoffset: $this.find("path")[0].getTotalLength(),
                    strokeDasharray: $this.find("path")[0].getTotalLength(),
                    ease: Expo.easeOut
                })
            });

            TweenMax.staggerTo($(_this.oldContainer).find(".page-header p"), 0.8, {
                y: -10,
                opacity: 0,
                ease: Expo.easeOut
            })

            if ($(_this.oldContainer).find(".suggestion").length != 0) {
                TweenMax.to($(_this.oldContainer).find(".suggestion"), 0.6, {
                    opacity: 0
                });
            }

            if ($(_this.oldContainer).find(".preserve-icon")) {
                TweenMax.to($(_this.oldContainer).find(".preserve-icon"), 0.8, {
                    scale: 0.8,
                    opacity: 0,
                    ease: Expo.easeOut
                });
            }
            TweenMax.to($(_this.oldContainer).find(".element"), 1, {
                ease: Expo.easeOut,
                opacity: 0
            });


            for (var i = 0; i < $(_this.oldContainer).find(".header-titles-container .header-titles").find("li").length; i++) {
                titlesArray.push(-(_globalViewportH * 0.62 + 100) * i);
            }
            $("#header-main").css("pointer-events", "none");

            if ($(_this.oldContainer).find(".scroll-content-wrapper").prevObject.css("transform") != 'none') {
                delay = 0.6;
            } else {
                delay = 0;
            }

            TweenMax.to($(_this.oldContainer).find(".scroll-content-wrapper").prevObject, delay, {
                y: 0,
                ease: Expo.easeInOut,
                onComplete: function () {
                    $_window.scrollTop(0);
                    $("#header-main").removeClass("header-collapse");

                    TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.6, {
                        fill: "#06214D",
                        ease: Circ.easeOut,
                        onComplete: function () {
                            TweenMax.set($("#header-main .main-logo .logo-type svg path"), {
                                clearProps: "fill"
                            });
                        }
                    });
                    TweenMax.to($(".logo-image"), 0.6, {
                        alpha: 1,
                        delay: 0.1,
                        ease: Expo.easeInOut
                    });
                    TweenMax.to($("#header-main"), 1, {
                        y: 0,
                        ease: Expo.easeInOut
                    });
                    TweenMax.to($(_this.oldContainer).find(".header-titles-container .header-titles"), 0.8, {
                        x: titlesArray[_titleActiveIndex],
                        ease: Expo.easeInOut,
                        onComplete: function () {
                            $("#header-main").removeClass("whiteCategory");
                            $("#header-main").removeClass("tawnyCategory");
                            $("#header-main").removeClass("rubyCategory");
                            $("#header-main").removeClass("roseCategory");
                            TweenMax.set(_this.oldContainer, {
                                autoAlpha: 0,
                                onComplete: function () {
                                    TweenMax.set($(_this.oldContainer).find(".page-current"), {
                                        clearProps: "transform"
                                    });
                                    TweenMax.set(_this.oldContainer, {
                                        display: 'none'
                                    });
                                    resolve();

                                    $("#header-main").css("pointer-events", "all");
                                    $(".header-titles-container").addClass("remove-opacity");

                                    TweenMax.set($(_this.newContainer).find(".header-titles-container .header-titles"), {
                                        x: titlesArray[_titleActiveIndex]
                                    });

                                    $(_this.newContainer).find(".header-titles-container .header-titles").find("li").each(function (key) {
                                        var $this = $(this);
                                        $this.css({
                                            "width": _globalViewportH * 0.62,
                                            "margin-right": "100px"
                                        });
                                    });

                                    $(_this.newContainer).find(".header-titles-container .header-titles").css("left", (_globalViewportW - _globalViewportH * 0.62) / 2);


                                    setTimeout(function () {
                                        TweenMax.staggerTo($(_this.newContainer).find(".page-header p"), 0.8, {
                                            y: 0,
                                            opacity: 1,
                                            ease: Expo.easeOut
                                        })

                                        if ($(_this.newContainer).find(".preserve-icon")) {
                                            TweenMax.to($(_this.newContainer).find(".preserve-icon"), 0.8, {
                                                scale: 1,
                                                opacity: 1,
                                                delay: 0.3,
                                                ease: Expo.easeOut
                                            });
                                        }

                                        if ($(_this.newContainer).find(".suggestion").length != 0) {
                                            TweenMax.to($(_this.newContainer).find(".suggestion"), 0.6, {
                                                delay: 0.4,
                                                opacity: 1
                                            });
                                        }

                                        TweenMax.to($(_this.newContainer).find(".element"), 1, {
                                            ease: Expo.easeOut,
                                            delay: 0.2,
                                            opacity: 1
                                        });
                                    }, 500)
                                }
                            });
                        }
                    });
                }
            });
        });
    },

    fadeIn: function () {
        var _this = this;
        TweenMax.fromTo(_this.newContainer, 0, {
            autoAlpha: 0
        }, {
            autoAlpha: 1,
            onComplete: function () {
                _this.done();
                $_body.removeClass("js-loading-page");
                manageBodyClasses(_this.newContainer, _this.oldContainer)
            }
        });
    }
});