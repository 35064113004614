function contactsPageFunctions() {

    if ($_scrollContentWrapper.attr("data-buroScrollbar") != undefined && !_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
        _buroScroll = new buroVirtualScroll("body", ".page-current", .5, true);
        _buroScroll.init();

        _page_scroll_listener = function (status) {
            contactsPage_scroll_rAF(status);
        };
        _buroScroll.addListener(_page_scroll_listener)
    } else {
        _scrollRef = function () {
            _raf_loop_id = _rAF_loop(contactsPage_scroll_rAF);
        }
        $_window[0].addEventListener("scroll", _scrollRef, {
            passive: true
        });
    }

    /*DOM variables*/

    /*Variables*/
    var lastScroll = -1,
        globalFooterObj = new globalFooter();

    var init = function () {

        /*inits*/
        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
            _buroScroll.blockBuroScroll();
        else
            disableBodyScroll(true);


        if ($_body.hasClass("js-no-ajax")) {
            contactsEntranceObj.init();
            _introObj.init(function () {
                if (cookie.get('cookie-age') != undefined) {
                    contactsEntranceObj.animateIn(function() {
                        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                            _buroScroll.unblockBuroScroll();
                            _buroScroll.update();
                        } else {
                            disableBodyScroll(false);
                        }
                    });
                } else {
                    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                        _buroScroll.unblockBuroScroll();
                        _buroScroll.update();
                        $.doTimeout(1000, function() {
                            _buroScroll.update();
                        });
                    } else {
                        disableBodyScroll(false);
                    }
                }
            });
        } else {
            if (!_browserObj.isMobile) {
                _buroScroll.unblockBuroScroll();
                _buroScroll.update();
                $.doTimeout(1000, function () {
                    _buroScroll.update();
                });
            } else {
                disableBodyScroll(false);
            }
        }

        globalFooterObj.init();
        //Events
        initEvents();

        $("nav .main-links li").removeClass("disable");
        $("nav .main-links li").eq(4).addClass("disable");
    }

    var kill = function () {
        //Kill Events

        setTimeout(function () {
            globalFooterObj.kill();
        }, 1000);
        if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
            _buroScroll.kill();
        } else {
            $_window[0].removeEventListener("scroll", _scrollRef);
            _scrollRef = null;
        }

        cancelAnimationFrame(_raf_loop_id);
    }

    /*page functions*/
    function initEvents() {}

    function contactsPage_scroll_rAF(status) {
        if (!_buroScroll) {
            var status = {
                scrollVal: window.pageYOffset
            }

            if (lastScroll - status.scrollVal < 0) {
                status.direction = 'down';
            } else if (lastScroll - status.scrollVal > 0) {
                status.direction = 'up';
            }

            lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
        }

        _globalHeader.animate(status.scrollVal, status.direction);
        globalFooterObj.inViewport(status.scrollVal);
        globalCtaCavesObj.animate(status.scrollVal, $(".page-current").height(), status.direction);
    }

    return {
        init: init,
        kill: kill
    }
}

///BARBA INITIALIZER///
var contactsPage = Barba.BaseView.extend({
    namespace: 'contactos',
    onEnter: function () {

    },
    onEnterCompleted: function () {
        // The Transition has just finished.
        _currentView = contactsPageFunctions();
        _currentView.init();
    },
    onLeave: function () {
        // A new Transition toward a new page has just started.
        _currentView.kill();
    },
    onLeaveCompleted: function () {
        // The Container has just been removed from the DOM.
    }
});


contactsPage.init();