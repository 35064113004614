function homePageFunctions() {

  if ($_scrollContentWrapper.attr("data-buroScrollbar") != undefined && !_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
    _buroScroll = new buroVirtualScroll("body", ".page-current", .5, true);
    _buroScroll.init();

    _page_scroll_listener = function (status) {
      homePage_scroll_rAF(status);
    };
    _buroScroll.addListener(_page_scroll_listener)
  } else {
    _scrollRef = function () {
      _raf_loop_id = _rAF_loop(homePage_scroll_rAF);
    }
    $_window[0].addEventListener("scroll", _scrollRef, {
      passive: true
    });
  }

  /*DOM variables*/
  var $familyWrapper = $(".family-wrapper");

  /*Variables*/
  var destaquesSliderObj = new destaquesSlider(),
    homeSliderObj = new homeSlider(),
    wineAdviceSliderObj = new wineAdviceSlider(),
    globalFooterObj = new globalFooter(),
    waterRippleObj = new waterRipple(),
    homeScrollAnimationsObj = new homeScrollAnimations(),
    lastScroll = -1;

  var init = function () {

    $(".logo").parent().css("pointer-events", "none");
    $("nav .main-links li").removeClass("disable");

    /*inits*/
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE)
      _buroScroll.blockBuroScroll();
    else
      disableBodyScroll(true);


    if ($_body.hasClass("js-no-ajax")) {
      _introObj.init(function () {
        if (cookie.get('cookie-age') != undefined) {
          $.doTimeout(1000, function () {
            homeEntranceObj.animateIn(function () {
              if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
                _buroScroll.unblockBuroScroll();
                _buroScroll.update();
                homeScrollAnimationsObj.inViewport(100);
              } else {
                disableBodyScroll(false);
                homeScrollAnimationsObj.inViewport(300);
              }
            });
          });
        } else {
          if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
            _buroScroll.unblockBuroScroll();
            _buroScroll.update();
            $.doTimeout(1000, function () {
              _buroScroll.update();
            });
          } else {
            disableBodyScroll(false);
          }
        }
      });
      homeEntranceObj.init();
    } else {
      $(".lazyload").addClass("lazypreload");
      if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
        _buroScroll.unblockBuroScroll();
        _buroScroll.update();
        homeScrollAnimationsObj.inViewport(100);
        $.doTimeout(1000, function () {
          _buroScroll.update();
        });
      } else {
        disableBodyScroll(false);
        homeScrollAnimationsObj.inViewport(300);
      }
    }

    destaquesSliderObj.init();
    homeSliderObj.init();
    wineAdviceSliderObj.init();
    globalFooterObj.init();
    homeScrollAnimationsObj.init();
    if (!_browserObj.isMobile) {
      waterRippleObj.init();
    }
    //Events
    initEvents();
  }

  var kill = function () {
    //Kill Events

    cancelAnimationFrame(_raf_loop_id);
    destaquesSliderObj.kill();
    homeSliderObj.kill();
    setTimeout(function () {
      globalFooterObj.kill();
    }, 1000);
    wineAdviceSliderObj.kill();
    if (!_browserObj.isMobile) {
      waterRippleObj.kill();
    }
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
      _buroScroll.kill();
    } else {
      $_window[0].removeEventListener("scroll", _scrollRef);
      _scrollRef = null;
    }

    cancelAnimationFrame(_raf_loop_id);
    $(".logo").parent().css("pointer-events", "all");
  }

  /*page functions*/
  function initEvents() {}

  $familyWrapper.find("ul li").on("click", function () {
    var $this = $(this);
    _titleActiveIndex = $familyWrapper.find("ul li").index($this);
  });

  function removeClick(e){
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }

  function waterRipple() {
    var waterCanvasHeight = $(".page-header .big-square-home").height(),
      $canvasRipple = $('#ripple'),
      animationFrame = 0,
      canvas,
      animation = null,
      canvasMouseOver = null,
      rippleSettings = {
        maxSize: 63,
        animationSpeed: 4,
        strokeColor: [2, 25, 48]
      },
      canvasSettings = {
        blur: 3,
        ratio: 1
      };

    function Coords(x, y) {
      this.x = x || null;
      this.y = y || null;
    }

    this.init = function () {
      var Ripple = function Ripple(x, y, circleSize, ctx) {
        this.position = new Coords(x, y);
        this.circleSize = circleSize;
        this.maxSize = rippleSettings.maxSize;
        this.opacity = 1;
        this.ctx = ctx;
        this.strokeColor = 'rgba(' + Math.floor(rippleSettings.strokeColor[0]) + ',\n    ' + Math.floor(rippleSettings.strokeColor[1]) + ',\n    ' + Math.floor(rippleSettings.strokeColor[2]) + ',\n    ' + this.opacity + ')';

        this.animationSpeed = rippleSettings.animationSpeed;
        this.opacityStep = this.animationSpeed / (this.maxSize - circleSize) / 2;
      };

      Ripple.prototype = {
        update: function update() {
          this.circleSize = this.circleSize + this.animationSpeed;
          this.opacity = this.opacity - this.opacityStep;
          this.strokeColor = 'rgba(' + Math.floor(rippleSettings.strokeColor[0]) + ',\n      ' + Math.floor(rippleSettings.strokeColor[1]) + ',\n      ' + Math.floor(rippleSettings.strokeColor[2]) + ',\n      ' + this.opacity + ')';
        },
        draw: function draw() {
          this.ctx.beginPath();
          this.ctx.strokeStyle = this.strokeColor;
          this.ctx.arc(this.position.x, this.position.y, this.circleSize, 0, 2 * Math.PI);
          this.ctx.stroke();
        }
      };

      canvas = document.querySelector('#ripple');
      var ctx = canvas.getContext('2d');
      var ripples = [];

      var height = document.body.clientHeight;
      var width = document.body.clientWidth;

      var rippleStartStatus = 'start';

      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

      canvas.style.filter = 'blur(' + canvasSettings.blur + 'px)';

      canvas.width = width * canvasSettings.ratio;
      canvas.height = height * canvasSettings.ratio;

      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';

      // Function which is executed on mouse hover on canvas
      canvasMouseOver = function canvasMouseOver(e) {
        var x = e.clientX * canvasSettings.ratio;
        var y = e.clientY * canvasSettings.ratio;
        ripples.unshift(new Ripple(x, y, 1, ctx));
      };

      animation = function animation() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        var length = ripples.length;
        for (var i = length - 1; i >= 0; i -= 1) {
          var r = ripples[i];

          r.update();
          r.draw();

          if (r.opacity <= 0) {
            ripples[i] = null;
            delete ripples[i];
            ripples.pop();
          }
        }
        animationFrame = _rAF_loop(animation);
      };

      animation();
      canvas.addEventListener('mousemove', canvasMouseOver);
    }

    this.checkInViewport = function (scroll) {
      if (scroll > waterCanvasHeight && !$canvasRipple.hasClass("notInViewport")) {
        $canvasRipple.addClass("notInViewport");
        cancelAnimationFrame(animationFrame);
        canvas.removeEventListener('mousemove', canvasMouseOver);
      } else if (scroll < waterCanvasHeight && $canvasRipple.hasClass("notInViewport")) {
        $canvasRipple.removeClass("notInViewport");
        animation();
        canvas.addEventListener('mousemove', canvasMouseOver);
      }
    }

    this.kill = function () {
      cancelAnimationFrame(animationFrame);
      canvas.removeEventListener('mousemove', canvasMouseOver);
    }
  }

  function homeScrollAnimations() {
    var $number = $(".number"),
      $mapContainer = $(".map-container"),
      $jsBigTitle = $(".js-big-title"),
      $jsSubtitle = $(".js-subtitle"),
      $calemButton = $(".calem-button"),
      $family = $(".family"),
      $jsText = $(".js-text"),
      $mainPerspective = $(".main-perspective"),
      offset = 100,
      mapOffset = $mapContainer.offset().top + offset,
      familyOffset = $family.offset().top + offset * 2,
      mapVisible = false,
      familyVisible = false,
      positionsNumbers = [],
      positionsBigTitles = [],
      positionsSubtitles = [],
      positionsText = [],
      positionsButtons = [],
      positionsImgs = [],
      captions = [];

    this.init = function () {

      if (!_browserObj.isMobile) {
        $family.find("li").hover(function () {
          var $this = $(this);
          $this.addClass("hovered");
          TweenMax.to($family.find("li").not(".hovered"), 0.2, {
            opacity: 0.7,
            ease: Circ.easeOut
          });
        }, function () {
          $family.find("li.hovered").removeClass("hovered");
          TweenMax.to($family.find("li"), 0.2, {
            opacity: 1,
            ease: Circ.easeOut
          });
        })
      }

      $number.each(function () {
        var $this = $(this),
          splittedNumber = null,
          objNumber = {},
          offset,
          offsetTop = $this.offset().top;

        splittedNumber = new SplitText($this, {
          type: "chars"
        });
        $(splittedNumber.chars).addClass("number-chars");

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 200;
        }

        objNumber.elem = $this;
        objNumber.offset = offsetTop + offset;
        objNumber.active = false;

        positionsNumbers.push(objNumber);
      });

      $jsBigTitle.each(function () {
        var $this = $(this),
          offset,
          splittedBigTitle = null,
          objBigTitle = {};

        splittedBigTitle = new SplitText($this, {
          type: "words, chars"
        });
        $(splittedBigTitle.chars).addClass("big-title-chars");
        if (!_browserObj.isSafari) {
          TweenMax.set($(splittedBigTitle.chars), {
            rotationX: 100,
            opacity: 0
          });
        } else {
          if(_browserObj.isMobile){
            TweenMax.set($(splittedBigTitle.chars), {
              rotationX: 100,
              opacity: 0
            });          
          }
        }

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objBigTitle.elem = $this;
        objBigTitle.offset = $this.offset().top + offset;
        objBigTitle.active = false;

        positionsBigTitles.push(objBigTitle);
      });

      $jsSubtitle.each(function () {
        var $this = $(this),
          offset,
          splittedSubTitle = null,
          objSubtitle = {};

        splittedSubTitle = new SplitText($this, {
          type: "lines"
        });
        $(splittedSubTitle.lines).addClass("subtitle-lines");
        TweenMax.set($this.find(".subtitle-lines"), {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objSubtitle.elem = $this;
        objSubtitle.offset = $this.offset().top + offset;
        objSubtitle.active = false;

        positionsSubtitles.push(objSubtitle);
      });

      $jsText.each(function () {
        var $this = $(this),
          offset,
          splittedText = null,
          objText = {};

        TweenMax.set($this, {
          y: 50,
          opacity: 0
        });

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objText.elem = $this;
        objText.offset = $this.offset().top + offset;
        objText.active = false;

        positionsText.push(objText);
      });

      $calemButton.each(function () {
        var $this = $(this),
          offset,
          objBtn = {};

        if(!$this.hasClass("intro-button")){
          TweenMax.set($this, {
            y: 50
          });
        }

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objBtn.elem = $this;
        objBtn.offset = $this.offset().top + offset;
        objBtn.active = false;

        positionsButtons.push(objBtn);
      });

      $mainPerspective.find("figure").each(function () {
        var $this = $(this),
          offset,
          objImg = {};

        if($this.attr("data-offset") != undefined){
          offset = Number($this.attr("data-offset"));
        } else {
          offset = 300;
        }

        objImg.elem = $this;
        objImg.offset = $this.offset().top + offset;
        objImg.active = false;

        positionsImgs.push(objImg);
      });
    }

    this.inViewport = function (scroll) {
      for (var i = 0; i <= positionsNumbers.length - 1; i++) {
        if (scroll + _globalViewportH > positionsNumbers[i].offset && positionsNumbers[i].active === false) {
          TweenMax.staggerTo($(positionsNumbers[i].elem).find(".number-chars"), 1, {
            opacity: 1,
            rotationX: 0,
            ease: Expo.easeInOut
          }, 0.08);
          $(positionsNumbers[i].elem).addClass("active");
          positionsNumbers[i].active = true;
        }
        if (positionsNumbers[positionsNumbers.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsBigTitles.length - 1; i++) {
        if (scroll + _globalViewportH > positionsBigTitles[i].offset && positionsBigTitles[i].active === false) {
          TweenMax.staggerTo($(positionsBigTitles[i].elem).find(".big-title-chars"), 1, {
            opacity: 1,
            rotationX: 0,
            ease: Circ.easeOut
          }, 0.05, function () {
            if (i === 0) {
              $(positionsBigTitles[0].elem).find("sup").addClass("show");
            }
          });
          positionsBigTitles[i].active = true;
        }
        if (positionsBigTitles[positionsBigTitles.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsSubtitles.length - 1; i++) {
        if (scroll + _globalViewportH > positionsSubtitles[i].offset && positionsSubtitles[i].active === false) {
          TweenMax.staggerTo($(positionsSubtitles[i].elem.find(".subtitle-lines")), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          }, 0.1);
          positionsSubtitles[i].active = true;
        }
        if (positionsSubtitles[positionsSubtitles.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsText.length - 1; i++) {
        if (scroll + _globalViewportH > positionsText[i].offset && positionsText[i].active === false) {
          TweenMax.to($(positionsText[i].elem), 1, {
            opacity: 1,
            y: 0,
            ease: Circ.easeOut
          });
          positionsText[i].active = true;
        }
        if (positionsText[positionsText.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsImgs.length - 1; i++) {
        if (scroll + _globalViewportH > positionsImgs[i].offset && positionsImgs[i].active === false) {
          TweenMax.to($(positionsImgs[i].elem), 1.4, {
            x: 0,
            y: 0,
            rotation: 0,
            opacity: 1,
            ease: Power3.easeOut
          });
          positionsImgs[i].active = true;
        }
        if (positionsImgs[positionsImgs.length - 1].active === true)
          break;
      }

      for (var i = 0; i <= positionsButtons.length - 1; i++) {
        if (scroll + _globalViewportH > positionsButtons[i].offset && positionsButtons[i].active === false) {
          TweenMax.to($(positionsButtons[i].elem), 1, {
            autoAlpha: 1,
            y: 0,
            ease: Circ.easeOut
          })
          positionsButtons[i].active = true;
        }
        if (positionsButtons[positionsButtons.length - 1].active === true)
          break;
      }

      if (scroll + _globalViewportH > mapOffset && !mapVisible) {
        mapVisible = true;
        $mapContainer.addClass("active");
        TweenMax.to($mapContainer.find(".map .element-cover"), 1, {
          opacity: 1,
          scale: 1,
          delay: 0.6,
          ease: Expo.easeInOut
        });
      }

      if (scroll + _globalViewportH > familyOffset && !familyVisible) {
        familyVisible = true;
        TweenMax.staggerTo($family.find("figure"), 2, {
          rotationX: 0,
          autoAlpha: 1,
          ease: Expo.easeInOut
        }, 0.1);
      }

      for (var i = 0; i <= captions.length - 1; i++) {
        if (scroll + _globalViewportH > captions[i].offset && captions[i].active === false) {
          captions[i].active = true;
          TweenMax.staggerTo($(captions[i].elem).find(".caption-chars"), 1, {
            opacity: 1,
            rotationX: 0,
            ease: Circ.easeInOut
          }, 0.1);
        }
        if (captions[captions.length - 1].active === true)
          break;
      }
    }

    this.kill = function () {

    }
  }

  function homePage_scroll_rAF(status) {
    if (!_buroScroll) {
      var status = {
        scrollVal: window.pageYOffset
      }

      if (lastScroll - status.scrollVal < 0) {
        status.direction = 'down';
      } else if (lastScroll - status.scrollVal > 0) {
        status.direction = 'up';
      }

      lastScroll = status.scrollVal <= 0 ? 0 : status.scrollVal;
    }

    _globalHeader.animate(status.scrollVal, status.direction);
    homeSliderObj.inViewport(status.scrollVal);
    destaquesSliderObj.inViewport(status.scrollVal);
    wineAdviceSliderObj.inViewport(status.scrollVal);
    globalFooterObj.inViewport(status.scrollVal);
    homeScrollAnimationsObj.inViewport(status.scrollVal);
    if (!_browserObj.isMobile) {
      waterRippleObj.checkInViewport(status.scrollVal);
    }
  }

  return {
    init: init,
    kill: kill
  }
}

///BARBA INITIALIZER///
var homePage = Barba.BaseView.extend({
  namespace: 'home',
  onEnter: function () {

  },
  onEnterCompleted: function () {
    // The Transition has just finished.
    _currentView = homePageFunctions();
    _currentView.init();
  },
  onLeave: function () {
    // A new Transition toward a new page has just started.
    _currentView.kill();
  },
  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
  }
});


homePage.init();