var nextFooterCategoryTransition = Barba.BaseTransition.extend({
    start: function () {

        $_body.addClass("js-loading-page");

        // As soon the loading is finished and the old page is faded out, let's fade the new page
        Promise.all([this.newContainerReady, this.newContainerLoading])
            .then(this.fadeOut.bind(this))
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        var _this = this,
            titlesArrayLoading = [];

        return new Promise(function (resolve, reject) {

            var mouseX, mouseY, raf_loading;
            if(!_browserObj.isMobile){
                TweenMax.set(".loading-svg", {autoAlpha: 1, scale: 1});
            } else {
                TweenMax.set(".loading-svg", {
                    autoAlpha: 1, 
                    scale: 1, 
                    delay: 0.5,
                    y: (_globalViewportH - $(".loading-svg").outerHeight())/2 + $(_this.newContainer).find(".header-titles").outerHeight()/2
                });
            }
            TweenMax.to(".loading-svg", 1, {rotation: "+=60", repeat: -1, ease: Linear.easeNone});
            if(!_browserObj.isMobile){
              $_window.on("mousemove.loading", function (e) {
                mouseX = e.clientX - $(".loading-svg").outerWidth() / 2;
                mouseY = e.clientY - $(".loading-svg").outerHeight() / 2;
              });
        
              loadingLoop();
              TweenMax.set(".loading-svg", {x: (window.innerWidth - $(".loading-svg").outerWidth()) / 2, y: (window.innerHeight - $(".loading-svg").outerHeight()) / 2})
        
              function loadingLoop() {
                TweenMax.to(".loading-svg", .6, {
                    x: mouseX,
                    y: mouseY,
                    ease: Circ.easeOut
                });
                raf_loading = _rAF_loop(loadingLoop);
              } 
            }

            for (var i = 0; i < $(_this.newContainer).find(".header-titles").find("li").length; i++) {
                titlesArrayLoading.push(-(_globalViewportH * 0.62 + 100) * i);
            }

            TweenMax.set($(_this.newContainer).find(".line-scroll"), {
                opacity: 1
            });

            $(_this.newContainer).find(".header-titles-container").addClass("remove-opacity");
            $("#header-main").css("pointer-events", "none");
            TweenMax.set([$(".loading-layout h3"), $(".loading-layout h2")], {
                autoAlpha: 0
            });
            TweenMax.set($(".loading-layout .loading-titles-container"), {
                autoAlpha: 1
            });

            $(".loading-layout .loading-titles-container").find("li").each(function (key) {
                var $this = $(this);
                $this.css({
                    "width": _globalViewportH * 0.62,
                    "margin-right": "100px"
                });
            });

            $(_this.newContainer).find(".header-titles").find("li").each(function (key) {
                var $this = $(this);
                $this.css({
                    "width": _globalViewportH * 0.62,
                    "margin-right": "100px"
                });
                TweenMax.set($this, {
                    opacity: 1
                });
            });

            $(".loading-layout .loading-titles-container .loading-titles").css("left", (_globalViewportW - _globalViewportH * 0.62) / 2);
            $(_this.newContainer).find(".header-titles").css("left", (_globalViewportW - _globalViewportH * 0.62) / 2);

            if($_window.width() > 768){
                TweenMax.set($(".loading-layout .loading-titles-container"), {
                    y: $(_this.newContainer).find(".header-titles-container").position().top
                });
            } else {
                TweenMax.set($(".loading-layout .loading-titles-container"), {
                    y: $(_this.newContainer).find(".category-title").position().top
                });
            }

            TweenMax.set($(".loading-layout .loading-titles-container .loading-titles"), {
                x: titlesArrayLoading[_titleActiveIndex]
            })
            TweenMax.set($(_this.newContainer).find(".header-titles"), {
                x: titlesArrayLoading[_titleActiveIndex]
            })

            setTimeout(function () {
                $("#header-main").removeClass("header-collapse");
            }, 100);
            $(".loading-layout").addClass("white-version");

            TweenMax.to($("#header-main .main-logo .logo-type svg path"), 0.6, {
                fill: "#06214D",
                ease: Circ.easeOut
            });
            TweenMax.to($(".logo-image"), 0.6, {
                alpha: 1,
                delay: 0.1,
                ease: Expo.easeInOut
            });
            TweenMax.to($("#header-main"), 1, {
                y: 0,
                ease: Expo.easeInOut
            });

            setTimeout(function () {
                if (_browserObj.isMobile) {
                    if ($("nav.show-mobile").hasClass("menu-is-open")) {
                        $(".hamburguer").click();
                    }
                }
            }, 400)

            TweenMax.to(".loading-layout", 1, {
                clip: "rect(0px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                ease: Expo.easeInOut,
                onComplete: function () {
                    $_window.scrollTop(0);
                    $("#header-main").removeClass("whiteCategory");
                    $("#header-main").removeClass("tawnyCategory");
                    $("#header-main").removeClass("rubyCategory");
                    $("#header-main").removeClass("roseCategory");
        
                    TweenMax.set(_this.oldContainer, {
                        autoAlpha: 0,
                        onComplete: function () {
                            TweenMax.to(".loading-svg", 0.2, {opacity: 0, scale: 0.9, ease: Expo.easeOut});
                            TweenMax.set($(_this.oldContainer).find(".page-current"), {
                                clearProps: "transform"
                            });
                            TweenMax.set(_this.oldContainer, {
                                display: 'none'
                            });
                            resolve();
                            if(!_browserObj.isMobile){
                                $_window.off("mousemove.loading");
                                cancelAnimationFrame(raf_loading);
                            }
                            setTimeout(function () {
                                TweenMax.to(".loading-layout", 1, {
                                    clip: "rect(" + $('.loading-layout').outerHeight() + "px " + $('.loading-layout').outerWidth() + "px " + $('.loading-layout').outerHeight() + "px 0px)",
                                    ease: Expo.easeInOut,
                                    onComplete: function () {
                                        $(".loading-layout").removeClass("white-version");
                                        TweenMax.set($("#header-main .main-logo .logo-type svg path"), {
                                            clearProps: "fill"
                                        });
                                        TweenMax.set(".loading-layout", {
                                            clip: "rect(0px " + $('.loading-layout').outerWidth() + "px 0px 0px)"
                                        })
                                        TweenMax.set([$(".loading-layout .loading-titles-container"), $(".loading-layout .loading-titles-container").find("li")], {
                                            clearProps: "all"
                                        });
                                        TweenMax.set([$(".loading-layout h3"), $(".loading-layout h2")], {
                                            clearProps: "all"
                                        });
                                        $("#header-main").css("pointer-events", "all");
                                    }
                                });
                            }, 200);
                        }
                    })
                }
            })
        });
    },

    fadeIn: function () {
        var _this = this;
        TweenMax.fromTo(_this.newContainer, .2, {
            autoAlpha: 0
        }, {
            autoAlpha: 1,
            onComplete: function () {
                _this.done();
                $_body.removeClass("js-loading-page");
                manageBodyClasses(_this.newContainer, _this.oldContainer)
            }
        });
    }
});