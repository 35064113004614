function buroVirtualScroll(wrapper, target, ease, fullPage) {

  // if(_buroScroll) {
  //   _buroScroll.kill();
  //   _buroScroll = null;
  // }
  var containerWrapper = document.querySelector(wrapper),
      containerScroll = document.querySelector(target),
      scrollHandler = null,
      scroll_raf = null,
      canScroll = true,
      scrollVal = 0,
      isHover = true,
      isHoverHandler = false,
      isHoverHandlerZone = false,
      lastScroll = 0,
      prevScroll = 0,
      status = {scrollVal:0 },
      handlerPosition = 0,
      handlerHeight = 0,
      scrollLimit = containerScroll.offsetHeight - 1,
      isFullPage = fullPage,
      scrollEase = ease,
      handlerDrag = null,
      listeners = [];
  
  var mouseMoveFunc = function(e) {
    if(e.clientX > _globalViewportW - 50)
      isHoverHandlerZone = true;
    else
      isHoverHandlerZone = false;
  }

  var scrolHandlerMouseEnter = function() {
    isHoverHandler = true;
  }
  var scrolHandlerMouseLeave = function() {
    isHoverHandler = false;
  }

  var blockBuroScroll = function() {
    canScroll = false;
  }
  var unblockBuroScroll = function() {
    canScroll = true;
  }
  
  var mouseoverContainerScroll = function() {
    isHover = true;
  }
  var mouseleaveContainerScroll = function() {
    isHover = true;
  }

  var windowFocus = function() {
    if(cookie.get('cookie-age') == 'true')
      canScroll = true;
  }

  var windowBlur = function() {
    canScroll = false;
  }


  var scrollResize = function () {
    scrollLimit = containerScroll.offsetHeight;
    status.scrollLimit = scrollLimit;
    handlerHeight =  window.innerHeight*window.innerHeight/scrollLimit;

    scrollHandler.style.height = handlerHeight + "px";

    if( scrollLimit <= window.innerHeight) {
      TweenMax.set('.scroll-handler-wrapper', {
        display: 'none'
      });
    }
    else {
      TweenMax.set('.scroll-handler-wrapper', {
        display: 'block'
      });
    }
  }


  var init = function() {
    scroll_raf = _rAF_loop(scrollLoop);

    handlerHeight =  _globalViewportH*_globalViewportH/scrollLimit;
 
    scrollLimit = containerScroll.offsetHeight - 1;

    status.scrollLimit = scrollLimit;

    containerWrapper.style.height = '100vh';
    containerWrapper.style.overflow = 'hidden';
    
    containerWrapper.style.willChange = 'transform';
    containerWrapper.style.transform = 'translateZ(0px)';
    containerWrapper.style.webkitTransform = 'translateZ(0px)';

    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('html').style.overflow = 'hidden';
    
    var node = document.createElement("span");
    var node2 = document.createElement("span");

    node.className = 'scroll-handler-wrapper';
    node2.className = 'scroll-handler';

    node.appendChild(node2);
    containerWrapper.appendChild(node);
    node.style.position = 'fixed';
    node.style.display = 'block';
    node.style.width = '4px';
    node.style.height = '100%';
    node.style.top = '0px';
    node.style.right = '2px';
    node.style.zIndex = '9999';

    node2.style.position = 'absolute';
    node2.style.display = 'block';
    node2.style.width = '4px';
    node2.style.height = handlerHeight + 'px';
    node2.style.top = '0px';
    node2.style.left = '0px';

    if(isFullPage) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('html').style.overflow = '100vh';
    }

    if( scrollLimit <= window.innerHeight) {
      TweenMax.set('.scroll-handler-wrapper', {
        display: 'none'
      });
    }
      
    scrollHandler = document.querySelector('.scroll-handler');

    VirtualScroll.on(function(e) {
      if(!canScroll) return;
      
      scrollVal += e.deltaY*scrollEase;
      scrollVal = Math.max( (scrollLimit - _globalViewportH) * -1, scrollVal);
      scrollVal = Math.min(0, scrollVal);
    });
    
    handlerDrag = Draggable.create(".scroll-handler", {
      type: "y",
      bounds: ".scroll-handler-wrapper",
      cursor: 'grab',
      onDrag : function() {
        scrollVal = this.endY*(scrollLimit)/_globalViewportH*-1;

        TweenMax.to(containerScroll, scrollEase,{
          y: scrollVal,
          roundProps: "x",
          force3D: true,
          ease: Linear.easeNone
        });  
      },
      onPress : function() {
        isDragPressed = true;
        TweenMax.set(".scroll-handler",{cursor:"grabbing"});
      },
      onRelease : function() {
        isDragPressed = false;
      }
    });
    
    /*Events*/
    containerScroll.addEventListener("mouseover", mouseoverContainerScroll);
    containerScroll.addEventListener("mouseover", mouseleaveContainerScroll);
    window.addEventListener("resize", scrollResize);
    document.addEventListener("blockBuroScroll", blockBuroScroll);
    document.addEventListener("unblockBuroScroll", unblockBuroScroll);
    scrollHandler.addEventListener('mouseenter', scrolHandlerMouseEnter);
    scrollHandler.addEventListener('mouseenter', scrolHandlerMouseLeave);
    document.addEventListener("mousemove", mouseMoveFunc);
    window.addEventListener("focus", windowFocus);
    window.addEventListener("blur", windowBlur);
  }

  var update = function() {
    scrollResize();
  }

  var setPosition = function(x,y) {
    TweenMax.set(containerScroll,{
      y: y,
      x: x
    })
  }

  var scrollTo = function(val, speed, callback) {
    canScroll = false;
    
    var counter = {var: scrollVal };
    var value = val;

    TweenMax.to(counter, speed, {
      var: value,
      roundProps: "var",
      ease: Power4.easeOut,
      onUpdate: function () {
       scrollVal = counter.var;
      },
      onComplete: function() {
        canScroll = true;
        if(typeof callback === 'function') callback();
      }
    })
  }

  var kill = function() {
    cancelAnimationFrame(scroll_raf);
    VirtualScroll.off();
    containerScroll.removeEventListener("mouseover", mouseoverContainerScroll);
    containerScroll.removeEventListener("mouseover", mouseleaveContainerScroll);
    window.removeEventListener("resize", scrollResize);
    document.removeEventListener("blockBuroScroll", blockBuroScroll);
    document.removeEventListener("unblockBuroScroll", unblockBuroScroll);
    scrollHandler.removeEventListener('mouseenter', scrolHandlerMouseEnter);
    scrollHandler.removeEventListener('mouseenter', scrolHandlerMouseLeave);
    document.removeEventListener("mousemove", mouseMoveFunc);
    window.removeEventListener("focus", windowFocus);
    window.removeEventListener("blur", windowBlur);

    document.querySelector(".scroll-handler").remove();
    document.querySelector(".scroll-handler-wrapper").remove();
  }

  var addListener = function(listenerFunction) {
    if (typeof listenerFunction == "function") {
      listeners.push(listenerFunction);
    }
  }

   var removeListener = function(listenerFunction) {
    if (typeof listenerFunction == "function") {
      listeners.push(listenerFunction);

      for(var i=0,len=listeners.length; i<len; i++) {
        if(listeners[i] == listenerFunction) {
          listeners.splice(i, 1);
        }
      }
    }
  }

  var getStatus = function() {
    return status;
  }

  var getScrollBarInfo = function() {
  
    return {
      position : -handlerPosition,
      height : handlerHeight
    }
  }
  
  var setBarColor = function(color) {
    TweenMax.to(".scroll-handler", .5, {
      backgroundColor: color
    });
  }
  return {
    init: init,
    kill: kill,
    update: update,
    scrollTo: scrollTo,
    setPosition: setPosition,
    addListener: addListener,
    blockBuroScroll: blockBuroScroll,
    unblockBuroScroll: unblockBuroScroll,
    getStatus: getStatus,
    setBarColor: setBarColor,
    getScrollBarInfo: getScrollBarInfo,
    removeListener: removeListener
  }

  //private funcions

  
  function scrollLoop(callback) {
    scroll_raf = _rAF_loop(scrollLoop);

    if(isHoverHandlerZone && !isHoverHandler) {
      TweenMax.to(scrollHandler, .5, {
        opacity: 1
      })
      document.body.style.cursor = "auto";
    }
    else if(isHoverHandler) {
      TweenMax.to(scrollHandler, .5, {
        opacity: 1
      })
      document.body.style.cursor = "grab";
    }
    else if(prevScroll != scrollVal) {
      //lastScroll += (scrollVal -lastScroll) * scrollEase;

      handlerPosition = scaleBetween(scrollVal, 0, window.innerHeight, 0, scrollLimit);
      TweenMax.to(scrollHandler, scrollEase, {
        y: -handlerPosition
      })
      TweenMax.to(containerScroll, scrollEase,{
        y: scrollVal,
        roundProps: "y",
        force3D: true,
        ease: Power4.easeOut
      })

      TweenMax.to(scrollHandler, .5, {
        opacity: 1
      })

      //Run listeners
      prevScroll > scrollVal ? status.direction = 'down' : status.direction = 'up';
      status.scrollVal = -Math.round(scrollVal);

      for(var i=0,len=listeners.length; i<len; i++) {
        listeners[i](status);
      }   

      prevScroll = scrollVal;
    }
    else {
      TweenMax.to(scrollHandler, .5, {
        opacity: 0,
      })
    }
  }
}