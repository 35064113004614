function rewriteUrl() {
  var $hiddenUrl = $(".hidden-url"),
    $languagesContainer = $(".languages-container");
  $hiddenUrl.find("li").each(function () {
    var $this = $(this);

    if ($this.hasClass("en")) {
      $languagesContainer.find("li.en a").attr("href", $this.html())
    } else {
      $languagesContainer.find("li.pt a").attr("href", $this.html())
    }
  })
}

function globalCtaCaves() {
  var $cta = $(".global-cta-caves"),
    $closeCta = $(".global-close-cta-caves"),
    ctaWidth = $cta.outerWidth(),
    isShow = false,
    firstTime = true;

  this.init = function (name) {
    $closeCta.on("click", function () {
      hide();
    });

    if (isShow) {
      hide();
    }
  }

  this.animate = function (scroll, documentHeight, direction) {
    if (scroll + _globalViewportH > (documentHeight * 2) / 3 && firstTime) {
      firstTime = false;
      if (name != 'home' && name != 'portfolio' && !isShow) {
        isShow = true;
        TweenMax.to($cta, 0.6, {
          x: 0,
          ease: Circ.easeOut
        });
      }
    }

    if (direction == 'up' && isShow) {
      isShow = false;
      TweenMax.to($cta, 1, {
        x: $cta.outerWidth() * 2,
        delay: 0.5,
        ease: Circ.easeOut
      });
    }

    if (direction === 'down' && !isShow && scroll + _globalViewportH > (documentHeight * 2) / 3 && name != 'home' && name != 'portfolio') {
      isShow = true;
      TweenMax.to($cta, 1, {
        x: 0,
        ease: Circ.easeOut
      });
    }
  }

  function hide() {
    TweenMax.to($cta, 0.6, {
      x: ctaWidth + ctaWidth / 2,
      ease: Circ.easeOut,
      onComplete: function () {
        TweenMax.set($cta, {
          display: "none"
        });
      }
    });
  }
}


function canvasSlideshow(options) {

  //  SCOPE
  /// ---------------------------      
  var that = this;


  //  OPTIONS
  /// ---------------------------      
  options = options || {};
  options.containerTarget = options.hasOwnProperty('containerTarget') ? document.querySelector(options.containerTarget) : document.body;
  options.stageWidth = options.hasOwnProperty('stageWidth') ? options.stageWidth : 2800;
  options.stageHeight = options.hasOwnProperty('stageHeight') ? options.stageHeight : 1657;
  options.pixiSprites = options.hasOwnProperty('sprites') ? options.sprites : [];
  options.centerSprites = options.hasOwnProperty('centerSprites') ? options.centerSprites : false;
  options.autoPlay = options.hasOwnProperty('autoPlay') ? options.autoPlay : true;
  options.autoPlaySpeed = options.hasOwnProperty('autoPlaySpeed') ? options.autoPlaySpeed : [10, 3];
  options.fullScreen = options.hasOwnProperty('fullScreen') ? options.fullScreen : true;
  options.displaceScale = options.hasOwnProperty('displaceScale') ? options.displaceScale : [200, 70];
  options.displacementImage = options.hasOwnProperty('displacementImage') ? options.displacementImage : '';
  options.navElement = options.hasOwnProperty('navElement') ? options.navElement : document.querySelectorAll('.displacement-canvas-button');
  options.displaceAutoFit = options.hasOwnProperty('displaceAutoFit') ? options.displaceAutoFit : false;
  options.wacky = options.hasOwnProperty('wacky') ? options.wacky : false;
  options.displaceScaleTo = (options.autoPlay === false) ? [0, 0] : [20, 20];
  options.displacementCenter = options.hasOwnProperty('displacementCenter') ? options.displacementCenter : false;


  //  PIXI VARIABLES
  /// ---------------------------    
  PIXI.utils.skipHello();
  var renderer = new PIXI.autoDetectRenderer(options.stageWidth, options.stageHeight, {
    transparent: true
  });
  var stage = new PIXI.Container();
  var slidesContainerDisplacement = new PIXI.Container();
  var displacementSprite = new PIXI.Sprite.fromImage(options.displacementImage);
  var displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);


  //  SLIDES ARRAY INDEX
  /// ---------------------------    
  this.currentIndex = 0;

  /// ---------------------------
  //  INITIALISE PIXI
  /// ---------------------------      
  this.initPixi = function () {

    // Add canvas to the HTML
    options.containerTarget.appendChild(renderer.view);

    // Add child container to the main container 
    stage.addChild(slidesContainerDisplacement);

    // Enable Interactions
    stage.interactive = true;


    // Fit renderer to the screen
    if (options.fullScreen === true) {
      renderer.view.style.objectFit = 'cover';
      renderer.view.style.width = '100%';
      renderer.view.style.height = '100%';
      renderer.view.style.top = '50%';
      renderer.view.style.left = '50%';
      renderer.view.style.webkitTransform = 'translate( -50%, -50% ) scale(1.2)';
      renderer.view.style.transform = 'translate( -50%, -50% ) scale(1.2)';
    } else {
      renderer.view.style.maxWidth = '100%';
      renderer.view.style.top = '50%';
      renderer.view.style.left = '50%';
      renderer.view.style.webkitTransform = 'translate( -50%, -50% )';
      renderer.view.style.transform = 'translate( -50%, -50% )';
    }

    if (_browserObj.isIE) {
      renderer.view.style.objectFit = 'cover';
      renderer.view.style.width = '100%';
      renderer.view.style.height = '100%';
      renderer.view.style.top = '0%';
      renderer.view.style.left = '0%';
      renderer.view.style.webkitTransform = 'translate( -50%, 0% ) scale(1.2)';
      renderer.view.style.transform = 'translate( -50%, 0% ) scale(1.2)';
    }

    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

    // Set the filter to stage and set some default values for the animation
    stage.filters = [displacementFilter];

    if (options.autoPlay === false) {
      displacementFilter.scale.x = 0;
      displacementFilter.scale.y = 0;
    }

    if (options.wacky === true) {
      displacementSprite.anchor.set(0.5);
      displacementSprite.x = renderer.width / 2;
      displacementSprite.y = renderer.height / 2;
    }

    displacementSprite.scale.x = 2;
    displacementSprite.scale.y = 2;

    // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
    displacementFilter.autoFit = options.displaceAutoFit;

    stage.addChild(displacementSprite);

  };


  /// ---------------------------
  //  LOAD SLIDES TO CANVAS
  /// ---------------------------          
  this.loadPixiSprites = function (sprites) {
    var rSprites = options.sprites;

    for (var i = 0; i < rSprites.length; i++) {

      var texture = new PIXI.Texture.fromImage(sprites[i]);
      var image = new PIXI.Sprite(texture);

      if (options.centerSprites === true) {
        image.anchor.set(0.5);
        image.x = renderer.width / 2;
        image.y = renderer.height / 2;
      }
      // image.transform.scale.x = 1.3;
      // image.transform.scale.y = 1.3;



      if (i !== 0) {
        TweenMax.set(image, {
          alpha: 0
        });
      }

      slidesContainerDisplacement.addChild(image);
    }
  };



  /// ---------------------------
  //  DEFAULT RENDER/ANIMATION
  /// ---------------------------        
  if (options.autoPlay === true) {

    var ticker = new PIXI.ticker.Ticker();

    ticker.autoStart = options.autoPlay;

    ticker.add(function (delta) {

      displacementSprite.x += options.autoPlaySpeed[0] * delta;
      displacementSprite.y += options.autoPlaySpeed[1];

      renderer.render(stage);

    });

  } else {

    var render = new PIXI.ticker.Ticker();

    render.autoStart = true;

    render.add(function (delta) {
      renderer.render(stage);
    });

  }


  /// ---------------------------
  //  TRANSITION BETWEEN SLIDES
  /// ---------------------------    
  var isPlaying = false;
  var slideImages = slidesContainerDisplacement.children;
  this.moveSlider = function (newIndex) {
    isPlaying = true;

    var baseTimeline = new TimelineMax({
      onComplete: function () {
        that.currentIndex = newIndex;
        isPlaying = false;
        if (options.wacky === true) {
          displacementSprite.scale.set(1);
        }
      },
      onUpdate: function () {
        if (options.wacky === true) {
          displacementSprite.rotation += baseTimeline.progress() * 0.02;
          displacementSprite.scale.set(baseTimeline.progress() * 3);
        }
      }
    });

    baseTimeline.clear();

    if (baseTimeline.isActive()) {
      return;
    }

    baseTimeline
      .to(displacementFilter.scale, 1, {
        x: options.displaceScale[0],
        y: options.displaceScale[1],
        ease: Power1.easeOut
      })
      .to(slideImages[that.currentIndex], 0.5, {
        alpha: 0,
        ease: Power2.easeOut
      }, 0.2)
      .to(slideImages[newIndex], 0.5, {
        alpha: 1,
        ease: Power2.easeOut
      }, 0.2)
      .to(displacementFilter.scale, 1, {
        x: options.displaceScaleTo[0],
        y: options.displaceScaleTo[1],
        ease: Power2.easeOut
      }, 0.3);
  };

  /// ---------------------------
  //  CLICK HANDLERS
  /// ---------------------------         
  var nav = options.navElement;

  for (var i = 0; i < nav.length; i++) {

    var navItem = nav[i];

    navItem.onclick = function (event) {

      // Make sure the previous transition has ended
      if (isPlaying) {
        return false;
      }

      if (this.getAttribute('data-direction') === 'next') {
        if (that.currentIndex >= 0 && that.currentIndex < slideImages.length - 1) {
          that.moveSlider(that.currentIndex + 1);
        } else {
          that.moveSlider(0);
        }

      } else {

        if (that.currentIndex > 0 && that.currentIndex < slideImages.length) {
          that.moveSlider(that.currentIndex - 1);
        } else {
          that.moveSlider(options.sprites.length - 1);
        }

      }

      return false;

    }
  }


  /// ---------------------------
  //  INIT FUNCTIONS
  /// ---------------------------    

  this.init = function () {


    that.initPixi();
    that.loadPixiSprites(options.pixiSprites);

    /*
    if ( options.fullScreen === true ) {
        window.addEventListener("resize", function( event ){ 
        scaleToWindow( renderer.view );
        });
        scaleToWindow( renderer.view );  
    }
    */

  };

  /// ---------------------------
  //  CENTER DISPLACEMENT
  /// ---------------------------
  if (options.displacementCenter === true) {
    displacementSprite.anchor.set(0.5);
    displacementSprite.x = renderer.view.width / 2;
    displacementSprite.y = renderer.view.height / 2;
  }


  /// ---------------------------
  //  START 
  /// ---------------------------           
  this.init();

  /// ---------------------------
  //  HELPER FUNCTIONS
  /// ---------------------------
  function scaleToWindow(canvas, backgroundColor) {
    var scaleX, scaleY, scale, center;

    //1. Scale the canvas to the correct size
    //Figure out the scale amount on each axis
    scaleX = window.innerWidth / canvas.offsetWidth;
    scaleY = window.innerHeight / canvas.offsetHeight;

    //Scale the canvas based on whichever value is less: `scaleX` or `scaleY`
    scale = Math.min(scaleX, scaleY);
    canvas.style.transformOrigin = "0 0";
    canvas.style.transform = "scale(" + scale + ")";

    //2. Center the canvas.
    //Decide whether to center the canvas vertically or horizontally.
    //Wide canvases should be centered vertically, and 
    //square or tall canvases should be centered horizontally
    if (canvas.offsetWidth > canvas.offsetHeight) {
      if (canvas.offsetWidth * scale < window.innerWidth) {
        center = "horizontally";
      } else {
        center = "vertically";
      }
    } else {
      if (canvas.offsetHeight * scale < window.innerHeight) {
        center = "vertically";
      } else {
        center = "horizontally";
      }
    }

    //Center horizontally (for square or tall canvases)
    var margin;
    if (center === "horizontally") {
      margin = (window.innerWidth - canvas.offsetWidth * scale) / 2;
      canvas.style.marginTop = 0 + "px";
      canvas.style.marginBottom = 0 + "px";
      canvas.style.marginLeft = margin + "px";
      canvas.style.marginRight = margin + "px";
    }

    //Center vertically (for wide canvases) 
    if (center === "vertically") {
      margin = (window.innerHeight - canvas.offsetHeight * scale) / 2;
      canvas.style.marginTop = margin + "px";
      canvas.style.marginBottom = margin + "px";
      canvas.style.marginLeft = 0 + "px";
      canvas.style.marginRight = 0 + "px";
    }

    //3. Remove any padding from the canvas  and body and set the canvas
    //display style to "block"
    canvas.style.paddingLeft = 0 + "px";
    canvas.style.paddingRight = 0 + "px";
    canvas.style.paddingTop = 0 + "px";
    canvas.style.paddingBottom = 0 + "px";
    canvas.style.display = "block";

    //4. Set the color of the HTML body background
    document.body.style.backgroundColor = backgroundColor;

    //Fix some quirkiness in scaling for Safari
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        // Chrome
      } else {
        // Safari
        //canvas.style.maxHeight = "100%";
        //canvas.style.minHeight = "100%";
      }
    }

    //5. Return the `scale` value. This is important, because you'll nee this value 
    //for correct hit testing between the pointer and sprites
    return scale;
  }
};

var count = 1;

function resetNum(num) {
  return count = num;
}

function yearsBottle() {
  var yearsList = [],
    positions = [],
    positionsDescription = [];

  var $bottlesImagesContainer = $(".bottles-images-container"),
    $bottlesDescriptionContainer = $(".bottles-description-container"),
    $bottlesDescriptionWrapper = $(".bottles-description-wrapper"),
    $technicalLink = $(".technical-link");

  this.init = function () {

    $bottlesDescriptionContainer.each(function () {
      var $this = $(this),
        maxHeight = 0,
        maxWidth = 0;

      $this.find($bottlesDescriptionWrapper).each(function () {
        var thisHeight = $(this).outerHeight(),
          thisWidth = $(this).outerWidth();

        if (thisHeight > maxHeight)
          maxHeight = thisHeight;

        if (thisWidth > maxWidth)
          maxWidth = thisWidth;        
      });

      TweenMax.set([$this, $this.find($bottlesDescriptionWrapper)], {
        height: maxHeight,
        width: maxWidth
      });
    });

    setTimeout(function(){
      $bottlesDescriptionContainer.each(function () {
        var $this = $(this),
          obj = {};
  
        obj.elem = $this;
        obj.offsetTop = $this.parent().find(".years-container").offset().top + 350;
        obj.active = false;
  
        TweenMax.set($this, {
          y: 50,
          opacity: 0
        });
  
        positionsDescription.push(obj);
      });
    }, 2000)

    $bottlesImagesContainer.each(function () {
      var $this = $(this),
        obj = {};

      obj.elem = $this;
      obj.offsetTop = $this.offset().top + 300;
      obj.active = false;

      positions.push(obj);

      TweenMax.set($this, {
        height: $this.find("figure").eq(0).height(),
        width: $this.find("figure").eq(0).width()
      });
      TweenMax.set($this.find("figure").eq(0), {
        opacity: 0,
        rotationX: 45
      });
    });

    $bottlesDescriptionWrapper.css("position", "absolute");
    $bottlesImagesContainer.find("figure").css("position", "absolute");
    if (!_browserObj.isMobile)
      $_window.on("resize.yearsBottle", $.debounce(500, resizeYearsBottle));

    $(".years-wrapper").each(function (index) {
      var $this = $(this),
        $years = $(this).find(".years"),
        $arrow = $(this).find(".arrow"),
        $yearsWrapperListItem = null,
        listHeight = 0,
        isAnimate = false,
        canClick = true;

      yearsList[index] = ($(this).find(".years").attr("data-years").split("/"));


      for (var j = 0; j <= yearsList[index].length - 1; j++) {
        yearsList[index][j] = Number(yearsList[index][j]);
        if (index === 1 && $(".page-toload").hasClass("tawny-category")) {
          if ($_body.attr("data-lang") === 'pt') {
            var str = 'anos';
          } else {
            var str = 'years';
          }
        } else {
          var str = ' ';
        }

        if (j === 0)
          $years.append('<li class="active">' + yearsList[index][j] + " " + str + '</li>');
        else
          $years.append('<li>' + yearsList[index][j] + " " + str + '</li>');
      }

      $yearsWrapperListItem = $this.find(".years li");

      listHeight = $this.find("ul").outerHeight();
      TweenMax.set($this, {
        height: 50
      });

      $this.on("click", function () {
        if (canClick && !isAnimate) {

          isAnimate = true;
          TweenMax.to($arrow, 1, {
            rotation: 90,
            ease: Expo.easeInOut
          })
          TweenMax.to($this, 1, {
            height: listHeight,
            ease: Expo.easeInOut,
            onComplete: function () {
              $this.addClass("shadow");
              isAnimate = false;
              canClick = false;
            }
          });
        } else if (!canClick && !isAnimate) {
          isAnimate = true;
          $this.removeClass("shadow");
          TweenMax.to($arrow, 0.4, {
            rotation: 0,
            ease: Expo.easeOut
          })
          TweenMax.to($this, 0.4, {
            height: 50,
            ease: Expo.easeOut,
            onComplete: function () {
              isAnimate = false;
              canClick = true;
            }
          });
        }
      });

      $yearsWrapperListItem.on("click", function () {
        var alt = $(this).index(),
          temp = yearsList[index][alt],
          that = $this;
        
        bottleAnimations(index, temp);
        if (!$(this).hasClass('active')) {
          $this.find(".years li.active").removeClass("active");

          yearsList[index].splice(alt, 1);
          yearsList[index].sort();
          yearsList[index].unshift(temp);

          $this.find(".years li").html(' ');
          for (var k = 0; k <= yearsList[index].length - 1; k++) {
            if (index === 1 && $(".page-toload").hasClass("tawny-category")) {
              if ($_body.attr("data-lang") === 'pt') {
                var str = 'anos';
              } else {
                var str = 'years';
              }
            } else {
              var str = ' ';
            }

            $this.find(".years li").eq(k).html(yearsList[index][k] + " " + str);
          }
          $this.find(".years li").eq(0).addClass("active");
          $(".age-tawny").each(function () {
            $(this).html(temp);
          });
        }
      });
    });
  }

  function resizeYearsBottle() {
    $bottlesImagesContainer.find("figure").css("position", "relative");
    TweenMax.set($bottlesImagesContainer, {
      clearProps: "all"
    });
    $bottlesImagesContainer.each(function () {
      var $this = $(this);
      TweenMax.set($this, {
        height: $this.find("figure").eq(0).height(),
        width: $this.find("figure").eq(0).width()
      });
    });
    $bottlesImagesContainer.find("figure").css("position", "absolute");
  }

  function bottleAnimations(key, newBottle) {
    TweenMax.to($bottlesImagesContainer.eq(key).find(".active"), 1, {
      rotationY: 20,
      x: -5,
      opacity: 0,
      ease: Circ.easeInOut
    });
    $technicalLink.attr("href", $(".bottle-" + newBottle + "").attr("data-link"));

    var aux_links = $bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".years").attr("data-links").split(",");
    var $buyLink = $bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find('.buy-link');
    var $techLink = $bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find('.technical-link');
    
    $buyLink.attr("href", $(".bottle-" + newBottle + "").attr("data-buy"));
    console.log($(".bottle-" + newBottle + "").attr("data-buy"))
    if($(".bottle-" + newBottle + "").attr("data-buy").length == 0) {
      $buyLink.addClass('hide-btn');
      $techLink.addClass('hide-btn');
    } 
    else{
      $buyLink.removeClass('hide-btn');
      $techLink.removeClass('hide-btn');
    }

    if($(".bottle-" + newBottle + "").attr("data-available") == 'nao') {
      $buyLink.addClass('inactive');
      $buyLink.find('.text').text($buyLink.attr('data-esgotado'));
      $buyLink.attr('title', $buyLink.attr('data-esgotado'))
    } 
    else{
      $buyLink.removeClass('inactive');
      $buyLink.find('.text').text($buyLink.attr('data-comprar'));
      $buyLink.attr('title', $buyLink.attr('data-comprar'))
    }

    $bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-container.active").addClass("remove");
    TweenMax.staggerTo($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards.active li"), 0.6, {
      y: -20,
      opacity: 0,
      ease: Expo.easeOut
    }, 0.05);
    TweenMax.to($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-container.remove .awards-buttons"), 0.4, {
      autoAlpha: 0
    });
    if ($(".calem-awards.awards-" + newBottle + "").length === 0 || $(".calem-awards.awards-" + newBottle + "").find("li").length <= 2) {
      TweenMax.to($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-section .dragger-helper-mobile"), 0.4, {
        autoAlpha: 0
      });
    } else if ($(".calem-awards.awards-" + newBottle + "").length != 0 || $(".calem-awards.awards-" + newBottle + "").find("li").length > 2) {
      TweenMax.to($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-section .dragger-helper-mobile"), 0.4, {
        autoAlpha: 1
      });
    }
    $(".calem-awards.awards-" + newBottle + "").css({
      "opacity": "1",
      "visibility": "visible"
    });
    $(".calem-awards.awards-" + newBottle + "").parent().parent().addClass("active");

    if ($(".calem-awards.awards-" + newBottle + " li").length != 0) {
      TweenMax.staggerTo($(".calem-awards.awards-" + newBottle + " li"), 0.6, {
        y: 0,
        opacity: 1,
        ease: Expo.easeOut
      }, 0.05, function () {
        TweenMax.set($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-container.remove .calem-awards"), {
          clearProps: "transform"
        });
        TweenMax.set($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-container.remove .awards-buttons"), {
          clearProps: "all"
        });
        $bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards-container.remove").removeClass("remove").removeClass("active");
        resetNum(0);

        TweenMax.set($bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards.active li"), {
          clearProps: "all"
        });
        $bottlesImagesContainer.eq(key).closest(".bottle-wrapper").find(".calem-awards.active").removeClass("active");
        $(".calem-awards.awards-" + newBottle + "").addClass("active");
      })
    }

    TweenMax.to($(".bottle-" + newBottle + ""), 1, {
      rotationY: 0,
      opacity: 1,
      x: 0,
      ease: Circ.easeInOut,
      onComplete: function () {
        TweenMax.set($bottlesImagesContainer.eq(key).find("figure"), {
          clearProps: "transform, opacity"
        });
        $bottlesImagesContainer.eq(key).find("figure.active").removeClass("active");
        $(".bottle-" + newBottle + "").addClass("active");
        TweenMax.set($bottlesImagesContainer.eq(key).find("figure").not(".active"), {
          rotationY: 20,
          x: -5,
          opacity: 0
        });
      }
    });

    $bottlesDescriptionContainer.eq(key).find($bottlesDescriptionWrapper).filter(".active").removeClass("active");
    $(".text-" + newBottle + "").addClass("active");

    // TweenMax.staggerTo($bottlesDescriptionContainer.eq(key).find($bottlesDescriptionWrapper).filter(".active").find(".text-lines"), 1, {
    //   opacity: 0,
    //   y: -5,
    //   ease: Expo.easeInOut
    // }, 0.05);
    // TweenMax.staggerTo($(".text-" + newBottle + " .text-lines"), 1, {
    //   y: 0,
    //   opacity: 1,
    //   ease: Expo.easeInOut,
    //   onComplete: function () {
    //     TweenMax.set($bottlesDescriptionContainer.eq(key).find($bottlesDescriptionWrapper).filter(".active").find(".text-lines"), {
    //       clearProps: "transform, opacity"
    //     });
    //     $bottlesDescriptionContainer.eq(key).find($bottlesDescriptionWrapper).filter(".active").removeClass("active");
    //     $(".text-" + newBottle + "").addClass("active");
    //     TweenMax.set($bottlesDescriptionWrapper.not(".active").find(".text-lines"), {
    //       y: 5,
    //       opacity: 0
    //     });
    //   }
    // }, 0.05);
  }

  this.inViewport = function (scroll) {
    for (var i = 0; i <= positions.length - 1; i++) {
      if (scroll + _globalViewportH > positions[i].offsetTop && positions[i].active === false) {
        TweenMax.to(positions[i].elem.find("figure").eq(0), 1, {
          opacity: 1,
          rotationX: 0,
          ease: Circ.easeInOut
        });
        // TweenMax.to($bottlesDescriptionContainer.eq(i).closest(".bottle-wrapper").find(".years-wrapper"), 1, {
        //   autoAlpha: 1,
        //   delay: 0.5,
        //   ease: Circ.easeInOut
        // });
        positions[i].active = true;
      }
      if (positions[positions.length - 1].active === true)
        break;
    }

    for (var index = 0; index < positionsDescription.length; index++) {
      if(scroll + _globalViewportH > positionsDescription[index].offsetTop && positionsDescription[index].active === false){
        TweenMax.to($(positionsDescription[index].elem), 0.6, {
          opacity: 1,
          y: 0,
          ease:Circ.easeOut
        });
        positionsDescription[index].active = true;
      }

      if (positionsDescription[positionsDescription.length - 1].active === true)
        break;
    }
  }

  this.kill = function () {
    $_window.off("resize.yearsBottle");
  }
};

function navMobile() {
  var $hamburguer = $(".hamburguer"),
    $nav = $("nav"),
    $mainLinks = $(".main-links"),
    $secondLinks = $(".second-links"),
    $footerLinkMobile = $(".footer-link-mobile"),
    $navOverlay = $(".nav-overlay"),
    $languageContainer = $(".languages-container"),
    isOpen = false,
    canAnimateMenu = true,
    navTL = new TimelineMax({
      paused: true
    }),
    navHamb = new TimelineMax({
      paused: true
    });

  $mainLinks.find("li a").each(function () {
    var $this = $(this),
      linkChars = null;

    linkChars = new SplitText($this, {
      type: "chars"
    });
    $(linkChars.chars).addClass("main-links-chars");
    TweenMax.set($(linkChars.chars), {
      rotationX: -90,
      opacity: 0
    });
  });

  TweenMax.set($secondLinks.find("li"), {
    y: 25,
    opacity: 0
  });

  navHamb.to($hamburguer.find("span:first-of-type"), 0.6, {
      y: 4,
      ease: Expo.easeOut
    })
    .to($hamburguer.find("span:last-of-type"), 0.6, {
      y: -5,
      ease: Expo.easeOut
    }, "0")
    .to($hamburguer.find("span:first-of-type"), 0.6, {
      rotation: 45,
      ease: Expo.easeOut
    })
    .to($hamburguer.find("span:last-of-type"), 0.6, {
      rotation: -45,
      ease: Expo.easeOut
    }, "0.6")


  navTL.to($navOverlay, 0.6, {
      scaleY: 1,
      ease: Circ.easeInOut
    })
    .staggerTo($(".main-links-chars"), 0.6, {
      rotationX: 0,
      opacity: 1,
      ease: Circ.easeInOut
    }, 0.025, ".2")
    .staggerTo($secondLinks.find("li"), 1, {
      y: 0,
      opacity: 1,
      ease: Circ.easeInOut
    }, 0.2, ".3")
    .to($footerLinkMobile, 0.6, {
      y: 0,
      ease: Circ.easeInOut,
      onComplete: function () {
        canAnimateMenu = true;
      }
    }, "1")
    .to($footerLinkMobile, 0.4, {
      y: 50,
      ease: Circ.easeOut
    })
    .staggerTo($secondLinks.find("li"), 1, {
      y: 25,
      opacity: 0,
      ease: Circ.easeInOut
    }, -0.04, "-=0.6")
    .staggerTo($(".main-links-chars"), 0.6, {
      rotationX: -90,
      opacity: 0,
      ease: Circ.easeInOut
    }, -0.01, "-=0.8")
    .to($navOverlay, 0.6, {
      scaleY: 0,
      transformOrigin: "bottom",
      ease: Circ.easeInOut,
      onComplete: function () {
        canAnimateMenu = true;
      }
    }, "-=0.6");


  $hamburguer.on("click", function () {
    if (!isOpen && canAnimateMenu) {
      canAnimateMenu = false;
      disableBodyScroll(true);
      isOpen = true;
      TweenMax.set($nav, {
        autoAlpha: 1
      });
      navTL.tweenFromTo(0, 1.9);
      navHamb.play();
      $("html, body").css("overflow", "hidden");
      $nav.addClass("menu-is-open");
      TweenMax.to($languageContainer, 1, {
        x: 0,
        ease: Circ.easeOut
      })
      if ($_body.hasClass("portfolio") || $_body.hasClass("portfolio-archive") || $_body.hasClass("wine-advice") || $_body.hasClass("wine-advice-single") || $_body.hasClass("terms-and-services")) {
        if (!$_headerMain.hasClass("header-collapse")) {
          TweenMax.set(".main-logo .logo-type svg path", {
            fill: "#ffffff"
          });
        }
      }
    } else if (isOpen && canAnimateMenu) {
      canAnimateMenu = false;
      isOpen = false;
      disableBodyScroll(false);
      $("html, body").css("overflow", "initial");
      navTL.tweenFromTo(2, navTL.duration());
      navHamb.reverse();
      $nav.removeClass("menu-is-open");
      TweenMax.to($languageContainer, 1, {
        x: -$languageContainer.outerWidth(),
        ease: Circ.easeOut
      })
      if ($_body.hasClass("portfolio") || $_body.hasClass("portfolio-archive") || $_body.hasClass("wine-advice") || $_body.hasClass("wine-advice-single") || $_body.hasClass("terms-and-services")) {
        if (!$_headerMain.hasClass("header-collapse")) {
          $.doTimeout(900, function () {
            if ($_body.hasClass("portfolio") || $_body.hasClass("portfolio-archive") || $_body.hasClass("wine-advice") || $_body.hasClass("wine-advice-single") || $_body.hasClass("terms-and-services")) {
              TweenMax.set(".main-logo .logo-type svg path", {
                fill: "#06214D"
              });
              TweenMax.set($nav, {
                autoAlpha: 0,
                delay: 1
              });
              TweenMax.set(".main-logo .logo-type svg path", {
                clearProps: "fill"
              })
            }
          })
        }
      } else {
        $.doTimeout(1100, function () {
          TweenMax.set($nav, {
            autoAlpha: 0
          });
        })
      }
    }
  });
}

function globalFooter() {
  var $globalFooter = $(".global-footer"),
    $fakeWrapper = $(".fake-wrapper"),
    $footerAntonioAlvesImage = $(".footer-antonio-alves-image");

  var globalFooterHeight,
    globalFooterOffsetTop;

  this.init = function () {
    globalFooterHeight = $globalFooter.outerHeight();
    globalFooterOffsetTop = $fakeWrapper.offset().top;

    TweenMax.set($fakeWrapper, {
      height: globalFooterHeight
    });

    if (!_browserObj.isMobile)
      TweenMax.set($globalFooter, {
        y: _globalViewportH - globalFooterHeight
      });
  }

  this.inViewport = function (scroll) {
    if (!_browserObj.isMobile && !_browserObj.isEdge && !_browserObj.isIE) {
      TweenMax.to($globalFooter, .5, {
        y: scroll + (_globalViewportH - globalFooterHeight),
        ease: Power4.easeOut,
        roundProps: "x",
        force3D: true
      });
    }

    if (scroll + _globalViewportH > globalFooterOffsetTop) {
      if (_browserObj.isMobile) {
        var fade = scaleBetween(scroll + _globalViewportH, 0, 1, $(".fake-wrapper").offset().top, $(".fake-wrapper").offset().top + $(".global-footer").outerHeight());
        var val = scaleBetween(scroll + _globalViewportH, $(".global-footer").outerHeight() / 2, 0, $(".fake-wrapper").offset().top, $(".fake-wrapper").offset().top + $(".global-footer").outerHeight());
        var rot = scaleBetween(scroll + _globalViewportH, 130, 0, $(".fake-wrapper").offset().top, $(".fake-wrapper").offset().top + $(".global-footer").outerHeight());
      } else {
        var fade = scaleBetween(scroll + _globalViewportH, 0, 1, globalFooterOffsetTop, globalFooterOffsetTop + globalFooterHeight);
        var val = scaleBetween(scroll + _globalViewportH, globalFooterHeight / 2, 0, globalFooterOffsetTop, globalFooterOffsetTop + globalFooterHeight);
        var rot = scaleBetween(scroll + _globalViewportH, 130, 0, globalFooterOffsetTop, globalFooterOffsetTop + globalFooterHeight);
      }

      TweenMax.set($globalFooter, {
        opacity: Math.abs(fade)
      });
      TweenMax.set($footerAntonioAlvesImage, {
        rotation: rot
      });
    }
  }

  this.reset = function () {
    globalFooterOffsetTop = $(".fake-wrapper").offset().top;
    TweenMax.set([$footerAntonioAlvesImage, $globalFooter], {
      clearProps: "all"
    });
  }

  this.kill = function () {
    globalFooterHeight = 0;
    globalFooterOffsetTop = 0;

    TweenMax.set([$fakeWrapper, $globalFooter, $footerAntonioAlvesImage], {
      clearProps: "all"
    });
  }
}

function globalHeader() {
  var $headerLogoImage = $(".logo-image"),
    $headerMain = $("#header-main"),
    $languagesContainer = $(".languages-container");

  var lastS = 0;

  this.animate = function (scroll, direction) {
    if (direction === 'down') {
      lastS = scroll;
    }

    if (scroll > 10 && !$headerMain.hasClass("header-collapse") && direction == 'down') {
      if (!_browserObj.isPhone) {
        TweenMax.to($headerLogoImage, 0.6, {
          alpha: 0,
          ease: Circ.easeOut
        });
        TweenMax.to($headerMain, 1, {
          y: -$headerMain.outerHeight() - $headerLogoImage.outerHeight(),
          ease: Circ.easeOut
        });
        setTimeout(function () {
          $headerMain.addClass("header-collapse");
        }, 200)
        // TweenMax.set($languagesContainer, {y: -$headerMain.outerHeight()*2});
        // TweenMax.to($languagesContainer, 1, {y: 5, ease: Circ.easeOut});
      } else {
        TweenMax.to($headerMain, 1, {
          y: -$headerMain.outerHeight() - $headerLogoImage.outerHeight(),
          ease: Circ.easeOut
        });
        setTimeout(function () {
          $headerMain.addClass("header-collapse");
        }, 200)
      }
    }

    if (!_browserObj.isPhone) {
      if (scroll > _globalHalfViewportH && direction === 'down' && !$headerMain.hasClass("is-hidden")) {
        $headerMain.addClass("is-hidden");
        TweenMax.to($headerMain, 1, {
          y: -$headerMain.outerHeight() - $headerLogoImage.outerHeight(),
          ease: Circ.easeOut
        });
      }
    } else {
      if (scroll > 10 && direction === 'down' && !$headerMain.hasClass("is-hidden")) {
        $headerMain.addClass("is-hidden");
        TweenMax.to($headerMain, 1, {
          y: -$headerMain.outerHeight() - $headerLogoImage.outerHeight(),
          ease: Circ.easeOut
        });
      }
    }

    if (direction === 'up' && $headerMain.hasClass("is-hidden") && scroll < lastS - 350) {
      $headerMain.removeClass("is-hidden");
      if (!_browserObj.isPhone) {
        TweenMax.to($headerMain, 1, {
          y: -$headerLogoImage.outerHeight(),
          ease: Circ.easeOut
        });
      } else {
        TweenMax.to($headerMain, 1, {
          y: 0,
          ease: Circ.easeOut
        });
        $headerMain.addClass("header-collapse");
      }
    }

    if (direction === 'up' && scroll < 100 && $headerMain.hasClass("header-collapse")) {
      $headerMain.removeClass("header-collapse");
      TweenMax.to($headerLogoImage, 0.6, {
        alpha: 1,
        ease: Circ.easeOut
      });
      TweenMax.to($headerMain, 1, {
        y: 0,
        ease: Circ.easeOut
      });
    }
  }
}

/*******************************************************************************************
 **                                                                                       **
    =GENERAL FUNCTIONS AND PLUGINGS CONTROL
 **                                                                                       **
*********************************************************************************************/

function submitJSON(action, value, onSuccess, onError) {
  $.ajax({
    data: ({
      action: 'saveSimulatorData',
      value: value
    }),
    type: 'post',
    dataType: 'json',
    url: _server_hostname + '/wp-admin/admin-ajax.php',
    success: function (data) {
      onSuccess(data)
      console.log("success", data);
    },
    error: function (data) {
      onError(data)
      console.log("error", data);
    }
  });
}

function submitForm($form) {
  if (validateForm($form) && !$form.hasClass('sending')) {
    $form.addClass('sending');

    $.ajax({
      data: $form.serialize(),
      type: 'post',
      dataType: 'json',
      url: _server_hostname + '/wp-admin/admin-ajax.php',
      success: function (data) {
        $form.removeClass('sending');
      },
      error: function (data) {
        $form.removeClass('sending');
      }
    });
  }
}


/** =Global page values */
function calculateGlobalValues() {
  _globalViewportW = window.innerWidth;
  _globalViewportH = window.innerHeight;
  _globalHalfViewportH = (_globalViewportH / 2).toFixed(0);
}

/** =ASYNC PLUGINS (polyfill, etc) */
function callAsyncFunctions() {
  if (window.respimage) respimage();
}

/** =Random integer between min (included) and max (excluded) */
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

/** =Manage for added body and page classes */
function manageBodyClassesRefresh() {
  $_body.addClass($(".page-main.page-current .page-toload").attr("data-bodyClass"));
}

/** =Manage for added body and page classes */
function manageBodyClasses(newContainer, oldContainer) {
  $_body.removeClass("js-byrefresh js-no-ajax");
  $_body.removeClass($(oldContainer).find(".page-toload").attr("data-bodyClass"));
  $_body.addClass($(newContainer).find(".page-toload").attr("data-bodyClass"));
  rewriteUrl();
}



// https://stackoverflow.com/a/31687097/3203138
Array.prototype.scaleBetween = function (scaledMin, scaledMax) {
  var max = Math.max.apply(Math, this);
  var min = Math.min.apply(Math, this);
  return this.map(
    function (num) {
      return (scaledMax - scaledMin) * (num - min) / (max - min) + scaledMin
    }
  );
}

// function scaleBetween(arr, scaledMin, scaledMax) {
//   var max = Math.max.apply(Math, arr);
//   var min = Math.min.apply(Math, arr);
//   return arr.map(num => (scaledMax-scaledMin)*(num-min)/(max-min)+scaledMin);
// }

function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
}

/**
 * Prevent body scroll and overscroll.
 * Tested on mac, iOS chrome / Safari, Android Chrome.
 *
 * Based on: https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
 *           https://stackoverflow.com/a/41601290
 *
 * Use in combination with:
 * html, body {overflow: hidden;}
 *
 * and: -webkit-overflow-scrolling: touch; for the element that should scroll.
 *
 * disableBodyScroll(true, '.i-can-scroll');
 */
var disableBodyScroll = (function () {
  /**
   * Private variables
   */
  var _selector = false,
    _element = false,
    _clientY;

  /**
   * Polyfills for Element.matches and Element.closest
   */
  if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;

  if (!Element.prototype.closest)
    Element.prototype.closest = function (s) {
      var ancestor = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(s)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return el;
    };

  /**
   * Prevent default unless within _selector
   *
   * @param  event object event
   * @return void
   */
  var preventBodyScroll = function (event) {
    if (false === _element || !event.target.closest(_selector)) {
      event.preventDefault();
    }
  };

  /**
   * Cache the clientY co-ordinates for
   * comparison
   *
   * @param  event object event
   * @return void
   */
  var captureClientY = function (event) {
    // only respond to a single touch
    if (event.targetTouches.length === 1) {
      _clientY = event.targetTouches[0].clientY;
    }
  };

  /**
   * Detect whether the element is at the top
   * or the bottom of their scroll and prevent
   * the user from scrolling beyond
   *
   * @param  event object event
   * @return void
   */
  var preventOverscroll = function (event) {
    // only respond to a single touch
    if (event.targetTouches.length !== 1) {
      return;
    }

    var clientY = event.targetTouches[0].clientY - _clientY;

    // The element at the top of its scroll,
    // and the user scrolls down
    if (_element.scrollTop === 0 && clientY > 0) {
      event.preventDefault();
    }

    // The element at the bottom of its scroll,
    // and the user scrolls up
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
    if ((_element.scrollHeight - _element.scrollTop <= _element.clientHeight) && clientY < 0) {
      event.preventDefault();
    }

  };

  /**
   * Disable body scroll. Scrolling with the selector is
   * allowed if a selector is porvided.
   *
   * @param  boolean allow
   * @param  string selector Selector to element to change scroll permission
   * @return void
   */
  return function (allow, selector) {
    if (typeof selector !== "undefined") {
      _selector = selector;
      _element = document.querySelector(selector);
    }

    if (true === allow) {
      if (false !== _element) {
        _element.addEventListener('touchstart', captureClientY, {
          passive: false
        });
        _element.addEventListener('touchmove', preventOverscroll, {
          passive: false
        });
      }
      $("body, html").css("overflow", "hidden");
      document.body.addEventListener("touchmove", preventBodyScroll, {
        passive: false
      });
    } else {
      if (false !== _element) {
        _element.removeEventListener('touchstart', captureClientY, {
          passive: false
        });
        _element.removeEventListener('touchmove', preventOverscroll, {
          passive: false
        });
      }
      $("body, html").css("overflow", "");
      document.body.removeEventListener("touchmove", preventBodyScroll, {
        passive: false
      });
    }
  };
}());

/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 https://gist.github.com/gre/1650294
 */


function linear(t) {
  return t
};

function easeInQuad(t) {
  return t * t
};

function easeOutQuad(t) {
  return t * (2 - t)
};

function easeInOutQuad(t) {
  return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
};

function easeInCubic(t) {
  return t * t * t
};

function easeOutCubic(t) {
  return (--t) * t * t + 1
};

function easeInOutCubic(t) {
  return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
};

function easeInQuart(t) {
  return t * t * t * t
};

function easeOutQuart(t) {
  return 1 - (--t) * t * t * t
};

function easeInOutQuart(t) {
  return t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
};

function easeInQuint(t) {
  return t * t * t * t * t
};

function easeOutQuint(t) {
  return 1 + (--t) * t * t * t * t
};

function easeInOutQuint(t) {
  return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
}

function exponentialOut(t) {
  return t == 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);
}


//https://github.com/drojdjou/bartekdrozdz.com/blob/master/static/src/framework/VirtualScroll.js
var VirtualScroll = (function (document) {

  var vs = {};

  var numListeners, listeners = [],
    initialized = false;

  var touchStartX, touchStartY;

  // [ These settings can be customized with the options() function below ]
  // Mutiply the touch action by two making the scroll a bit faster than finger movement
  var touchMult = 2;
  // Firefox on Windows needs a boost, since scrolling is very slow
  var firefoxMult = 15;
  // How many pixels to move with each key press
  var keyStep = 120;
  // General multiplier for all mousehweel including FF
  var mouseMult = 1;

  var bodyTouchAction;

  var hasWheelEvent = 'onwheel' in document;
  var hasMouseWheelEvent = 'onmousewheel' in document;
  var hasTouch = 'ontouchstart' in document;
  var hasTouchWin = navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1;
  var hasPointer = !!window.navigator.msPointerEnabled;
  var hasKeyDown = 'onkeydown' in document;

  var isFirefox = navigator.userAgent.indexOf('Firefox') > -1;

  var event = {
    y: 0,
    x: 0,
    deltaX: 0,
    deltaY: 0,
    originalEvent: null
  };

  vs.on = function (f) {
    if (!initialized) initListeners();
    listeners.push(f);
    numListeners = listeners.length;
  }

  vs.options = function (opt) {
    keyStep = opt.keyStep || 120;
    firefoxMult = opt.firefoxMult || 15;
    touchMult = opt.touchMult || 2;
    mouseMult = opt.mouseMult || 1;
  }

  vs.off = function (f) {
    listeners.splice(f, 1);
    numListeners = listeners.length;
    if (numListeners <= 0) destroyListeners();
  }

  var notify = function (e) {
    event.x += event.deltaX;
    event.y += event.deltaY;
    event.originalEvent = e;

    for (var i = 0; i < numListeners; i++) {
      listeners[i](event);
    }
  }

  var onWheel = function (e) {
    // In Chrome and in Firefox (at least the new one)
    event.deltaX = e.wheelDeltaX || e.deltaX * -1;
    event.deltaY = e.wheelDeltaY || e.deltaY * -1;

    // for our purpose deltamode = 1 means user is on a wheel mouse, not touch pad
    // real meaning: https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent#Delta_modes
    if (isFirefox && e.deltaMode == 1) {
      event.deltaX *= firefoxMult;
      event.deltaY *= firefoxMult;
    }

    event.deltaX *= mouseMult;
    event.deltaY *= mouseMult;

    notify(e);
  }

  var onMouseWheel = function (e) {
    // In Safari, IE and in Chrome if 'wheel' isn't defined
    event.deltaX = (e.wheelDeltaX) ? e.wheelDeltaX : 0;
    event.deltaY = (e.wheelDeltaY) ? e.wheelDeltaY : e.wheelDelta;

    notify(e);
  }

  var onTouchStart = function (e) {
    var t = (e.targetTouches) ? e.targetTouches[0] : e;
    touchStartX = t.pageX;
    touchStartY = t.pageY;
  }

  var onTouchMove = function (e) {
    // e.preventDefault(); // < This needs to be managed externally
    var t = (e.targetTouches) ? e.targetTouches[0] : e;

    event.deltaX = (t.pageX - touchStartX) * touchMult;
    event.deltaY = (t.pageY - touchStartY) * touchMult;

    touchStartX = t.pageX;
    touchStartY = t.pageY;

    notify(e);
  }

  var onKeyDown = function (e) {
    // 37 left arrow, 38 up arrow, 39 right arrow, 40 down arrow
    event.deltaX = event.deltaY = 0;
    switch (e.keyCode) {
      case 37:
        event.deltaX = -keyStep;
        break;
      case 39:
        event.deltaX = keyStep;
        break;
      case 38:
        event.deltaY = keyStep;
        break;
      case 40:
        event.deltaY = -keyStep;
        break;
    }

    notify(e);
  }

  var initListeners = function () {
    if (hasWheelEvent) document.addEventListener("wheel", onWheel);
    if (hasMouseWheelEvent) document.addEventListener("mousewheel", onMouseWheel);

    if (hasTouch) {
      document.addEventListener("touchstart", onTouchStart);
      document.addEventListener("touchmove", onTouchMove);
    }

    if (hasPointer && hasTouchWin) {
      bodyTouchAction = document.body.style.msTouchAction;
      document.body.style.msTouchAction = "none";
      document.addEventListener("MSPointerDown", onTouchStart, true);
      document.addEventListener("MSPointerMove", onTouchMove, true);
    }

    if (hasKeyDown) document.addEventListener("keydown", onKeyDown);

    initialized = true;
  }

  var destroyListeners = function () {
    if (hasWheelEvent) document.removeEventListener("wheel", onWheel);
    if (hasMouseWheelEvent) document.removeEventListener("mousewheel", onMouseWheel);

    if (hasTouch) {
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchmove", onTouchMove);
    }

    if (hasPointer && hasTouchWin) {
      document.body.style.msTouchAction = bodyTouchAction;
      document.removeEventListener("MSPointerDown", onTouchStart, true);
      document.removeEventListener("MSPointerMove", onTouchMove, true);
    }

    if (hasKeyDown) document.removeEventListener("keydown", onKeyDown);

    initialized = false;
  }

  return vs;
})(document);

function displacementImage(args) {
  PIXI.utils.skipHello();

  var pixelRatio = window.devicePixelRatio;
  var renderer = new PIXI.autoDetectRenderer(_globalViewportW * pixelRatio, _globalViewportH * pixelRatio, {
    transparent: true,
    antialias: true,
    forceCanvas: true
  });
  var stage = new PIXI.Container();
  var slidesContainer = new PIXI.Container();
  var displacementSprite = new PIXI.Sprite.fromImage(args.displacementImage);
  var displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
  var ticker = new PIXI.ticker.Ticker();
  var images_array = [];
  var active_index = 0;

  var $container = args.container;

  var canScroll = true;

  var canAnimate = false;

  var sprites = args.spriteImages;

  var res = null;

  ($(".page-current").attr("data-namespace") == 'home') ? res = cover(3400, 2182, _globalViewportW, _globalViewportH, false): res = cover(3400, 2182, _globalViewportW, _globalViewportH, true);

  var init = function (id, f) {

    active_index = id;

    $container.append(renderer.view);

    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

    displacementSprite.anchor.set(0.5);
    displacementSprite.x = renderer.width / 2;
    displacementSprite.y = renderer.height / 2;

    displacementSprite.scale.x = 0;
    displacementSprite.scale.y = 0;

    displacementFilter.scale.x = 50;
    displacementFilter.scale.y = 50;

    // Set the filter to stage
    stage.filters = [displacementFilter];

    var loader = new PIXI.loaders.Loader();

    for (var i = 0; i < sprites.length; i++) {
      loader.add(sprites[i]);
    }
    loader.load(function () {

      for (var i = 0; i < sprites.length; i++) {

        var texture = PIXI.Texture.from(sprites[i]);
        renderer.plugins.prepare.upload(texture.baseTexture);
      }

      loadPixiSprites(sprites);

      canAnimate = true;
      TweenMax.to(images_array[active_index], 1, {
        alpha: 1,
        ease: Power4.easeInOut,
        onComplete: function () {
          f();
        }
      });
      TweenMax.to(displacementFilter.scale, 2, {
        x: 0,
        y: 0,
        ease: Power4.easeInOut,
        onComplete: function () {
          canAnimate = false;
          canScroll = true;

          displacementSprite.scale.x = 0;
          displacementSprite.scale.y = 0;
          displacementFilter.scale.x = 50;
          displacementFilter.scale.y = 50;
        }
      });
    });


    // Add child container to the stage
    stage.addChild(slidesContainer);
    stage.addChild(displacementSprite);
    ticker.autoStart = true;

    ticker.add(function (delta) {
      if (canAnimate) {
        displacementSprite.scale.x += delta * .1;
        displacementSprite.scale.y += delta * .1;
      }

      renderer.render(stage);
    });

  }

  var goTo = function (id) {
    if (!canScroll) return;

    canScroll = false;
    canAnimate = true;

    slidesContainer.setChildIndex(images_array[id], images_array.length - 1);

    TweenMax.to(images_array[active_index], 2, {
      alpha: 0,
      ease: Power4.easeInOut
    });
    TweenMax.to(images_array[id], 1, {
      alpha: 1,
      ease: Power4.easeInOut
    });
    TweenMax.to(displacementFilter.scale, 2, {
      x: 0,
      y: 0,
      ease: Power4.easeInOut,
      onComplete: function () {
        canAnimate = false;
        canScroll = true;

        displacementSprite.scale.x = 0;
        displacementSprite.scale.y = 0;
        displacementFilter.scale.x = 50;
        displacementFilter.scale.y = 50;

        active_index = id;
      }
    });
  }

  var goOut = function (id) {
    TweenMax.set(images_array[id], {
      alpha: 0
    });
  }

  var goIn = function (id) {
    canAnimate = true;

    TweenMax.to(images_array[id], 1, {
      alpha: 1,
      ease: Power4.easeInOut
    });
    TweenMax.to(displacementFilter.scale, 2, {
      x: 0,
      y: 0,
      ease: Power4.easeInOut,
      onComplete: function () {
        canAnimate = false;

        displacementSprite.scale.x = 0;
        displacementSprite.scale.y = 0;
        displacementFilter.scale.x = 50;
        displacementFilter.scale.y = 50;
      }
    });

  }

  var getActiveIndex = function () {
    return active_index;
  }

  function loadPixiSprites(sprites) {

    for (var i = 0; i < sprites.length; i++) {

      var texture = new PIXI.Texture.fromImage(sprites[i]);
      var image = new PIXI.Sprite(texture);

      image.anchor.set(0);
      image.width = res.width * pixelRatio;
      image.height = res.height * pixelRatio;
      image.x = res.left * pixelRatio;
      image.y = res.top * pixelRatio;
      image.order = i;

      TweenMax.set(image, {
        alpha: 0
      });

      slidesContainer.addChild(image);

      images_array.push(image);

    }

    slidesContainer.setChildIndex(images_array[active_index], images_array.length - 1);


  }

  var kill = function () {
    //APAGAR AS CENAS DO PIXI
    if (renderer)
      renderer.destroy();
    if (stage)
      stage.destroy();

    $(".bottle-canvas-container").find("canvas").remove();

  }
  return {
    init: init,
    getActiveIndex: getActiveIndex,
    goTo: goTo,
    goOut: goOut,
    goIn: goIn,
    kill: kill
  }
}

function checkInViewHorizontal(item, offset, scroll) {
  if (offset = undefined)
    offset = 0;

  if (item.offsetX - offset >= scroll + _globalViewportW || item.offsetX + item.width - offset <= scroll)
    return false;
  else
    return true;
}

function normalizedValue(val, max, min) {
  return Number(((val - min) / (max - min)).toFixed(2));
}

function cover(srcW, srcH, targetW, targetH, contain) {
  var output = {};

  if (contain) {
    // https://opensourcehacker.com/2011/12/01/calculate-aspect-ratio-conserving-resize-for-images-in-javascript/
    var ratio = Math.min(targetW / srcW, targetH / srcH);
    output.width = srcW * ratio;
    output.height = srcH * ratio;
  } else {
    output.width = targetW;
    output.height = (srcH * targetW) / srcW;

    if (output.height < targetH) {
      output.width = (srcW * targetH) / srcH;
      output.height = targetH;
    }
  }

  output.left = Math.round((targetW - output.width) / 2);
  output.top = Math.round((targetH - output.height) / 2);

  return output;
}

function slugify(str) {
  var map = {
    '-': ' ',
    '-': '_',
    'a': 'á|à|ã|â|À|Á|Ã|Â',
    'e': 'é|è|ê|É|È|Ê',
    'i': 'í|ì|î|Í|Ì|Î',
    'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    'c': 'ç|Ç',
    'n': 'ñ|Ñ'
  };

  str = str.toLowerCase();

  for (var pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  };

  return str;
};

function getBrowserInfo() {
  var obj = {
    isMobile: false,
    isTablet: false,
    isPhone: false,
    isDesktop: false,
    isPortrait: false,
    isLandscape: false,
    isSafari: false,
    isIE: false,
    isEdge: false,
    isChrome: false,
    isFirefox: false,
    isRetina: false,
    pixelRatio: 1,
    type: '',
    browser: ''
  }



  if ($("body").hasClass("mobile")) {
    obj.isMobile = true;

    if ($("body").hasClass("phone")) {
      obj.isPhone = true;
      obj.type = 'phone';
    } else {
      obj.type = 'tablet';
      obj.isTablet = true;
    }

    if ($("html").hasClass("firefox")) {
      obj.browser = 'firefox';
      obj.isFirefox = true;
    } else if ($("html").hasClass("chrome")) {
      obj.browser = 'chrome';
      obj.isChrome = true;
    } else if ($("html").hasClass("safari")) {
      obj.browser = 'safari';
      obj.isSafari = true;
    } else
      obj.browser = 'unknown';
  } else {
    obj.type = 'desktop';
    obj.isDesktop = true;

    if ($("html").hasClass("firefox")) {
      obj.browser = 'firefox';
      obj.isFirefox = true;
    } else if ($("html").hasClass("chrome")) {
      obj.browser = 'chrome';
      obj.isChrome = true;
    } else if ($("html").hasClass("safari")) {
      obj.browser = 'safari';
      obj.isSafari = true;
    } else if ($("html").hasClass("ie")) {
      obj.browser = 'ie';
      obj.isIE = true;
    } else if ($("html").hasClass("edge")) {
      obj.browser = 'edge';
      obj.isEdge = true;
    } else
      obj.browser = 'unknown';
  }

  if ($("html").hasClass("orientation_landscape"))
    obj.isLandscape = true;

  if ($("html").hasClass("orientation_portrait"))
    obj.isPortrait = true;

  obj.pixelRatio = window.devicePixelRatio;

  if (obj.pixelRatio >= 2)
    obj.isRetina = true;

  return obj;

}