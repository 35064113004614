function wineAdviceSlider() {

    var $wineAdviceSliderContainer = $(".wine-advice-slider-container");
        $wineAdviceSlider = $(".wine-advice-slider"),
        $wineAdviceSliderArrow = $(".wine-advice-slider-arrow"),
        $dotsContainer = $(".dots-container"),
        $btnRight = $(".wine-advice-arrow-right"),
        $btnLeft = $(".wine-advice-arrow-left"),
        $btn = $(".wine-advice-slider-arrow");

    var current = 0,
        canClick = true,
        dotTotalLength = 0,
        isFirstTime = false,
        adviceLoopRAF = null,
        number = 0,
        reachEnd = false,
        dotArr = [],
        totalSlides = $wineAdviceSlider.find("li").length,
        slideWidth = $wineAdviceSlider.find("li").outerWidth(true),
        slideHeight = $wineAdviceSlider.find("li").outerHeight(),
        distance = 0,
        isClickedDot = false,
        btnWidth = $btn.outerWidth(),
        wineAdviceSliderOffset = null,
        wineAdviceSliderHeight = $wineAdviceSliderContainer.height();

    this.init = function(){
        if($wineAdviceSlider.find("li").length > 1){
            wineAdviceSliderOffset = $wineAdviceSliderContainer.find(".second-big-titles").offset().top;
            $wineAdviceSliderArrow.addClass("js-visible");
            if(_browserObj.isPhone){
                distance = _globalViewportW * 0.05;
                $wineAdviceSlider.swipeleft(function(e) { 
                    cancelAnimationFrame(adviceLoopRAF);
                    if (canClick && current < totalSlides-1) {
                        canClick = false;
                        animate("right");
                        TweenMax.set($(".dot svg ellipse"), {strokeDashoffset: 0});
                    }
                });
                $wineAdviceSlider.swiperight(function(e) { 
                    cancelAnimationFrame(adviceLoopRAF);
                    if (canClick && current > 0) {
                        canClick = false;
                        animate("left");
                        TweenMax.set($(".dot svg ellipse"), {strokeDashoffset: 0});
                    }
                });
            } else {
                distance = _globalViewportW * 0.125 + 20;
            }

            $wineAdviceSlider.find("li").each(function(key){
                var $this = $(this);

                if(key == 0)
                    $this.css("left", $this.outerWidth() * key);
                else 
                    $this.css("left", ($this.outerWidth() * key) + distance * key);

                if(!$this.hasClass("active"))
                    TweenMax.set($this.find("figcaption"), {clip:"rect(0px 0px "+slideHeight+"px 0px)"}); 
                else 
                    TweenMax.set($this.find("figcaption"), {clip:"rect(0px "+slideWidth+"px "+slideHeight+"px 0px)"}); 
            
                $dotsContainer.append(
                    '<div class="dot">'+
                        '<svg viewBox="0 0 60.15 59.87">'+
                            '<path d="M30.1 59.87a29.93 29.93 0 1 1 30-29.93 30 30 0 0 1-30 29.93zM30.1 1a28.93 28.93 0 1 0 29 28.94A29 29 0 0 0 30.1 1z"/>'+
                            '<ellipse cx="30.08" cy="29.93" rx="29.58" ry="29.43"/>'+
                        '</svg>'+
                        '<span>0'+parseInt(key+1)+'</span>'+
                    '</div>'
                );
            });

            $dotsContainer.find(".dot").eq(0).addClass("active");
            $dotsContainer.find(".dot").each(function(){
                var $this = $(this),
                    $loader = $this.find("svg ellipse"),
                    dotTL = new TimelineMax({paused: true});

                dotTL.to($loader, 4, {strokeDashoffset: 0, ease: Linear.easeNone});
                dotArr.push(dotTL);
            });

            distance = $wineAdviceSlider.find("li").eq(1).offset().left - ($wineAdviceSlider.find("li").eq(0).offset().left + slideWidth);

            if($_window.width() > 768){
                TweenMax.set($btnRight, {x: $wineAdviceSlider.find("li").eq(1).offset().left - btnWidth/2});
                TweenMax.set($btnLeft, {x: distance - btnWidth});
            }

            $wineAdviceSliderArrow.on("click", function(){
                cancelAnimationFrame(adviceLoopRAF);
                var $this = $(this),
                    direction = $this.attr("data-direction");

                if(canClick){
                    canClick = false;
                    animate(direction);
                }
            });

            $(".dot").on("click", function(){
                var $this = $(this),
                    key = $(".dot").index($this);
                
                TweenMax.set($this.find("svg ellipse"), {strokeDashoffset: 0});
                dotArr[current].pause();
                cancelAnimationFrame(adviceLoopRAF);

                if(key > current){
                    if(canClick){
                        canClick = false;
                        isClickedDot = true;
                        current = key;
                        animate("right");
                    }
                } else {
                    if(canClick){
                        canClick = false;
                        isClickedDot = true;
                        current = key;
                        animate("left");
                    }
                }
            });
            TweenMax.set($wineAdviceSlider, {x: _globalViewportW});
        }

        if(!_browserObj.isMobile)
            $_window.on("resize.wineAdvice", $.debounce(100, resizeWineAdvice));
    };

    this.inViewport = function(scroll){
        if(scroll + _globalViewportH > wineAdviceSliderOffset + 150 && scroll + _globalViewportH < wineAdviceSliderOffset + wineAdviceSliderHeight && !$wineAdviceSlider.hasClass("animate-in")){
            $wineAdviceSlider.addClass("animate-in");
            if(!isFirstTime){
                isFirstTime = true;
                TweenMax.to($wineAdviceSlider, 1, {x:0, ease: Circ.easeInOut});
            }
            dotArr[current].play();
            adviceLoop();
        } else if(scroll + _globalViewportH < wineAdviceSliderOffset + 150 && $wineAdviceSlider.hasClass("animate-in") || scroll + _globalViewportH > wineAdviceSliderOffset + wineAdviceSliderHeight && $wineAdviceSlider.hasClass("animate-in")){
            $wineAdviceSlider.removeClass("animate-in");
            dotArr[current].pause();
            cancelAnimationFrame(adviceLoopRAF);
        }
    }

    function resizeWineAdvice(){
        // distance = $wineAdviceSlider.find("li").eq(1).offset().left - ($wineAdviceSlider.find("li").eq(0).offset().left + $wineAdviceSlider.find("li").outerWidth(true));
        // $dotsContainer.css("left", $wineAdviceSlider.find("li").eq(1).offset().left);
        // TweenMax.set($btnRight, {x: _globalViewportW - distance - btnWidth/2});
        // TweenMax.set($btnLeft, {x: distance - btnWidth});

        // TweenMax.set($wineAdviceSlider.find("li"), {clearProps: "all"});
        // $wineAdviceSlider.find("li").each(function(key){
        //     var $this = $(this);

        //     if(key == 0)
        //         $this.css("left", $this.outerWidth() * key);
        //     else 
        //         $this.css("left", ($this.outerWidth() * key) + distance * key);
        // });
    }

    function adviceLoop() {
        if(reachEnd){
            cancelAnimationFrame(adviceLoopRAF);
            return false;
        }
            
        if(number > 60*4){
            number = 0;
            animate('right');
            canClick = false;
        }            
        number++;
        adviceLoopRAF = _rAF_loop(adviceLoop);
    }
  
    function animate(direction) {
        if(direction == 'right'){
            // if(current >= totalSlides-1)
            //     return false;            
            
            $wineAdviceSliderArrow.filter(".wine-advice-arrow-left").removeClass("disable");
            dotArr[current].pause();
            $dotsContainer.find(".dot.active").removeClass("active");

            if(!isClickedDot)
                current++;

            $dotsContainer.find(".dot").eq(current).addClass("active");
            dotArr[current].play();
            
            if(current == totalSlides - 1)  {
                $wineAdviceSliderArrow.filter(".wine-advice-arrow-right").addClass("disable");
                reachEnd = true;
            }

            TweenMax.to($wineAdviceSlider.find("li.active").find("figcaption"), 0.7, {clip:"rect(0px 0px "+slideHeight+"px 0px)",ease: Circ.easeInOut}); 
            $wineAdviceSlider.find("li.active").removeClass("active");
            $wineAdviceSlider.find("li").eq(current).addClass("active");
            TweenMax.to($wineAdviceSlider.find("li").eq(current).find("figcaption"), 1, {clip:"rect(0px "+slideWidth+"px "+slideHeight+"px 0px)", ease: Circ.easeInOut}); 
            TweenMax.to($wineAdviceSlider, 1, {x: -(slideWidth+distance)*current, ease: Circ.easeInOut, onComplete: function(){
                canClick = true;
                $wineAdviceSlider.find("li").eq(current).addClass("active");
                isClickedDot = false;
            }});
        } else {
            // if(current <= 0)
            //     return false;

            if($wineAdviceSliderArrow.filter(".wine-advice-arrow-right").hasClass("disable"))
                $wineAdviceSliderArrow.filter(".wine-advice-arrow-right").removeClass("disable");

            dotArr[current].pause();
            $dotsContainer.find(".dot.active").removeClass("active");

            if(!isClickedDot)
                current--;

            $dotsContainer.find(".dot").eq(current).addClass("active");
            dotArr[current].play();        

            if(current == 0)  
                $wineAdviceSliderArrow.filter(".wine-advice-arrow-left").addClass("disable");


            TweenMax.to($wineAdviceSlider.find("li.active").find("figcaption"), 0.7, {clip:"rect(0px 0px "+slideHeight+"px 0px)",ease: Circ.easeInOut}); 
            $wineAdviceSlider.find("li.active").removeClass("active");
            $wineAdviceSlider.find("li").eq(current).addClass("active");
            TweenMax.to($wineAdviceSlider.find("li").eq(current).find("figcaption"), 1, {clip:"rect(0px "+slideWidth+"px "+slideHeight+"px 0px)", ease: Circ.easeInOut}); 
            TweenMax.to($wineAdviceSlider, 1, {x: -(slideWidth+distance)*current, ease: Circ.easeInOut, onComplete: function(){
                canClick = true;
                $wineAdviceSlider.find("li").eq(current).addClass("active");
                isClickedDot = false;
            }});
        }
    }

    this.kill = function(){
        cancelAnimationFrame(adviceLoopRAF);
        $_window.off("resize.wineAdvice");
    }
}
